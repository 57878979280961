import { useEffect, useState } from "react"
import { NavigateConfig } from "../../../layout/NavbarLayout"
import { useGetLocale } from "../../../public/LangProvier"
import { useNavigate } from "react-router-dom"
import api from "../../../config/api"
import { Button, ErrorBlock, List, Toast } from "antd-mobile"
import "./MyAccount.scss"
import { AddOutline, DeleteOutline } from "antd-mobile-icons"
import Modal from "../../../public/Modal"
import Loading from "../../../public/Loading"

type MyAccountProps = {
    onNavigateConfig?: (config: NavigateConfig) => void
}


export default ({ onNavigateConfig }: MyAccountProps) => {


    const [items, setItems] = useState<any[]|null>(null);

    const locale = useGetLocale();

    const navigate = useNavigate();

    const loadAccountList = ()=>{
        api("withdraw/account/list").get({}).then(ret => {
            if (ret.code == 200) {
                setItems([
                    ...ret.data,
                ]);
            }
        })
    }


    useEffect(() => {

        onNavigateConfig?.({
            title: locale("my_account")
        });

        loadAccountList()
       


    }, []);

    const onDelete = async (id:any)=>{

        const {code,msg} = await api(`withdraw/account/${id}`).delete({});
        if(code == 200){
            Toast.show({
                content:locale(msg)
            });

            loadAccountList()
        }
    }

    if(items == null){
        return (
            <Loading/>
        )
    }


    return (
        <div className="my-account">

            {items.length == 0?(
                <ErrorBlock status="empty" title={locale("no_data")} description="" />
            ):(
                <List

                mode="card"

            >

                {items?.map((item: any, index: number) => {

                    return (
                        <List.Item


                            key={index}

                            prefix={<img src={item?.way?.image} style={{ borderRadius: 15 }} width={30} height={30} />}

                            title={item?.way?.shortName}

                            arrowIcon={null}

                            extra={
                                <Button onClick={()=>{

                                    Modal.confirm({
                                       
                                        content:locale("confirm_delete"),
                                        onOk:async ()=> {
                                            await onDelete(item?.id);
                                        },

                                    })

                                }} fill="outline" color="danger" size="small"  ><DeleteOutline/></Button>
                            }
                        >

                            <div>
                               <div>
                                  {locale("real_name")}: <span> {item.name}</span>
                               </div>
                               <div>
                                 {locale("account_num")}: <span> {item.accountNumber}</span>
                               </div>
                            </div>

                        </List.Item>
                    )
                })}

            </List>
            )}
        

            <div style={{padding:20}}>

                <Button onClick={()=>navigate("/user/account/add")} block fill="outline" color="primary" ><AddOutline/> {locale("add_withdraw_account")}</Button>
            </div>

        </div>
    )
}