import { useEffect, useState } from "react";
import { useGetLocale } from "../../../public/LangProvier";
import { Button, Card, DatePicker, ErrorBlock, Form, Grid, Input, Toast } from "antd-mobile";
import { useGetUserBalance } from "../../../public/UserBalanceProvier";
import CurrencySymbol from "../../../public/CurrencySymbol";

import "./index.scss";
import UserAccountSelectPopup from "../public/UserAccountSelectPopup";
import api from "../../../config/api";
import { useNavigate } from "react-router";
import { formatAmount } from "../../../utils";
import Modal from "../../../public/Modal";
import Loading from "../../../public/Loading";
import { useGetLogin } from "../../../public/LoginProvier";


const WithdrawTypeInput = ({ value, onChange }: any) => {

    const locale = useGetLocale();

    return (
        <Grid columns={2} gap={10} style={{ padding: 10 }}>
            <Grid.Item>
                <Button
                    style={{
                        // border: 0
                    }}
                    block
                    color={value == 0 ? "primary" : "default"}
                    // size="small"
                    // fill="outline"
                    onClick={() => {
                        onChange?.(0)
                    }}
                >{locale("fiat_currency")}</Button>
            </Grid.Item>
            <Grid.Item>
                <Button
                    style={{
                        // border: 0
                    }}
                    block
                    color={value == 1 ? "primary" : "default"}
                    // fill="outline"
                    // size="small"
                    onClick={() => {
                        onChange?.(1)
                    }}

                >{locale("USDT (TRC-20)")}</Button>
            </Grid.Item>
        </Grid>
    )
}

const Withdraw = ({ onNavigateConfig }: any) => {

    const locale = useGetLocale();

    const [form] = Form.useForm();

    const { balance, currency } = useGetUserBalance();

    const [usdtRate, setUsdtRate] = useState(null);

    const [loading, setLoading] = useState(false);

    const [withdraw, setWithdraw] = useState<any>(null);

    const { login, user } = useGetLogin();

    const navigate = useNavigate();

    const loadWithdarwAmount = () => {

        api("withdraw/amount").get({}).then(ret => {
            if (ret.code == 200) {
                setWithdraw(ret.data);
            }
        })
    }

    const onFinish = (values: any) => {
        console.log('提现信息：', values);

        setLoading(true);

        api("withdraw/apply").post({
            ...values,
            accountId: values.account?.id,
            account: undefined,

        }).then(ret => {
            if (ret.code == 200) {
                Toast.show({
                    content: locale(ret?.msg),
                });
                navigate("/withdraw/record");
            }
            if (ret.code == 201) {

                // Toast.show({
                //     content:locale(ret?.msg)
                // });
                Modal.alert(locale(ret?.msg));
            }
        })
            .finally(() => {
                setLoading(false);
            })

    };

    const loadUsdtRate = () => {
        api("withdraw/usdt-rate").get({}).then(ret => {
            if (ret.code == 200) {
                setUsdtRate(ret.data);
            }

        })
    }

    useEffect(() => {

        onNavigateConfig?.({
            title: locale("withdraw"),

        });

        loadWithdarwAmount();

    }, []);

    if (withdraw == null) {

        return (
            <Loading />
        )
    }

    if (!withdraw.support) {

        return (
            <ErrorBlock
                status="default"
                title={
                    locale("withdraw_tip", { needAmount: formatAmount(currency, withdraw?.needAmount) })
                }

                description={
                    <div>
                        <div className="withdraw-tip">
                            {locale("bet_amount_all", { betAmountAll: formatAmount(currency, withdraw?.betAmountAll) })}
                        </div>
                        {/* <div className="withdraw-tip">
                            {locale("bet_amount_all", { betAmountAll: formatAmount(currency, withdraw?.betAmountAll) })}
                        </div> */}
                      
                        <div style={{
                            padding: 20
                        }}>
                            <Button onClick={() => navigate("/home", { replace: true })} fill="outline" block size="large" color="primary">{locale("play_again")}</Button>
                        </div>
                    </div>
                }
            >

            </ErrorBlock>
        )
    }



    return (



        <Card className="withdarw-page" >
            <div className="balance">
                <div className="balance-item">
                    {locale("balance")}： {formatAmount(currency, balance)}
                </div>
                <div className="balance-item">
                    {/* {locale("withdraw_amount")}: {formatAmount(currency,)} */}
                </div>
            </div>
            {
                withdraw?.support ? null : (
                    <div className="tip">
                        <div className="tip-1">
                            { }
                        </div>
                        <div className="tip-2">

                        </div>
                    </div>
                )
            }


            <Form
                form={form}
                name="withdraw"
                layout="vertical"
                onFinish={onFinish}
                initialValues={{ type: 0 }}
                
                footer={
                    <Button disabled={!withdraw?.support} loading={loading} block type="submit" color="primary" size="large">
                        {locale("confirm_withdraw")}
                    </Button>
                }
            >
                {/* <Form.Item 
                    name={"type"}
                    label={<span>{locale("withdarw_type")}</span>}
                    rules={[{ required: true }]}
                >

                    <WithdrawTypeInput />
                </Form.Item> */}
                <Form.Item
                    name="amount"
                    
                    label={<span>{locale("amount")}</span>}
                    rules={[{
                        required:true,
                        validator(rule, value, callback) {
                            if(value < withdraw.startAmount || value > withdraw.endAmount){
                               return callback(locale("input_withdraw_amount", { start: withdraw?.startAmount, end: withdraw?.endAmount }))
                            }
                            return callback();
                        },
                    }]}
                >
                    <Input type="number" placeholder={locale("input_withdraw_amount", { start: withdraw?.startAmount, end: withdraw?.endAmount })} />
                </Form.Item>
                <Form.Item
                    name="account"
                    label={<span>{locale("receive_account")}</span>}
                    rules={[{ required: true }]}
                    onClick={(e, ref) => {
                        ref?.current?.open?.();
                    }}

                >

                    <UserAccountSelectPopup
                        title={locale("input_withdraw_account")}
                    >
                        {(value: any) => {
                            return value ? (

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={value?.way?.image} style={{ width: 24, height: 24, borderRadius: 12 }} />
                                    <div style={{ marginLeft: 10 }}>
                                        <div>
                                            ({value.name}) {value.accountNumber}
                                        </div>
                                        {/* <div style={{ fontSize: 10, color: "var(--adm-color-weak)" }}>
                                            {value?.name}
                                        </div> */}


                                    </div>
                                </div>


                            ) : (
                                <div>
                                    <Input placeholder={locale("pls_select")} readOnly />
                                </div>
                            )
                        }}
                    </UserAccountSelectPopup>
                </Form.Item>

                {user.userType == 1 ? (
                    <Form.Item
                        name="password"
                        label={<span>{locale("login_password")}</span>}
                        rules={[{ required: true }]}
                    >
                        <Input type="text" placeholder={locale("pls_input_pwd")} />
                    </Form.Item>
                ) : null}


            </Form>
        </Card>

    );
}



export default Withdraw;
