import { Result } from "antd-mobile"
import { useGetLocale } from "../../../public/LangProvier"

export default () => {

    const locale = useGetLocale();

    return (
        <div>
            <Result
                status='success'
                title={locale("pay_success")}
                description={locale("pay_success_info")}
            />

        </div>
    )

}