import { useEffect, useState } from "react";
import { useGetLocale } from "../../../public/LangProvier";
import { Button, Card, DatePicker, Form, Input, Space, Toast } from "antd-mobile";
import { useGetUserBalance } from "../../../public/UserBalanceProvier";
import CurrencySymbol from "../../../public/CurrencySymbol";

import "./add-withdarw-account.scss";
import { useNavigate } from "react-router";
import WithdrawWaySelectPopup from "../public/WithdrawWaySelectPopup";
import Item from "antd-mobile/es/components/dropdown/item";
import api from "../../../config/api";


const AddWithdarwAccount = ({ onNavigateConfig }: any) => {

    const locale = useGetLocale();

    const [form] = Form.useForm();

    const { currency } = useGetUserBalance();

    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const onFinish = (values: any) => {

        setLoading(true);

        api("withdraw/account").post({
            ...values,
            wayId: values?.way?.id,
            way: undefined,
        }).then(ret => {
            if (ret.code == 200) {
                Toast.show({
                    content: locale(ret.msg),
                    duration: 1500,
                });
                navigate(-1);
                return;
            }

        }).finally(() => {
            setLoading(false);
        })

    };

    useEffect(() => {

        onNavigateConfig?.({
            title: locale("add_withdraw_account"),
            onBack() {
                navigate(-1);
            }
        });

    }, []);



    return (
        <Card
            className="add-withdarw-account-page"
            style={{ padding: 0, marginTop: 0 }}
            bodyStyle={{paddingTop:0}}
        >
            {/* <div className="title">
                {locale("add_withdraw_account")}
            </div> */}
            <Form
                name="add-account"
                form={form}
                layout="vertical"
                onFinish={onFinish}
                footer={
                    <Button loading={loading} block type="submit" color="primary" size="large">
                        {locale("text.confirm")}
                    </Button>
                }
            >
                <Form.Item
                    name="way"
                    label={<span>{locale("account_type")}</span>}
                    rules={[{ required: true }]}
                    onClick={(e, ref) => {
                        // console.log("ref=>",ref);
                        ref?.current?.open?.();
                    }}
                >
                    <WithdrawWaySelectPopup>
                        {(value: any) => {
                            return value ? (

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <img src={value.image} style={{ width: 24, height: 24, borderRadius: 12 }} />
                                    <div style={{ marginLeft: 5 }}>
                                        <div>
                                            {value.shortName}
                                        </div>
                                        {value.name ? (
                                            <div style={{ fontSize: 10, color: "var(--adm-color-weak)" }}>
                                                {value.name}
                                            </div>
                                        ) : null}


                                    </div>
                                </div>


                            ) : (
                                <div>
                                    <Input placeholder={locale("pls_select")} readOnly />
                                </div>
                            )
                        }}
                    </WithdrawWaySelectPopup>
                </Form.Item>

                {currency == "INR" ? (
                    <Form.Item
                        name="ifscCode"
                        label={<span>{locale("IFSC Code")}</span>}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={locale("pls_input", { value: "IFSC Code" })} />
                    </Form.Item>
                ) : null}

                <Form.Item
                    name="accountNumber"
                    label={<span>{locale("account_num")}</span>}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={locale("input_real_name")} />
                </Form.Item>

                <Form.Item
                    name="name"
                    label={<span>{locale("real_name")}</span>}
                    rules={[{ required: true }]}
                >
                    <Input placeholder={locale("input_real_name")} />
                </Form.Item>
                {currency == "BRL" ? (
                    <Form.Item
                        name="cpf"
                        label={<span>{locale("CPF")}</span>}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={locale("pls_input", { value: "CPF" })} />
                    </Form.Item>
                ) : null}
                {currency == "PKR" ? (
                    <Form.Item
                        name="cnic"
                        label={<span>{locale("CNIC")}</span>}
                        rules={[{ required: true }]}
                    >
                        <Input placeholder={locale("pls_input", { value: "CNIC" })} />
                    </Form.Item>
                ) : null}

            </Form>
        </Card>
    );
}



export default AddWithdarwAccount;
