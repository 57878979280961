import { ConfigProvider } from "antd-mobile"

import { useGetSiteConfig } from "../public/SiteConfigProvier";

import LoginProiver, { Login } from "../public/LoginProvier";
import { FC, useEffect, useState } from "react";
import { getClientType, getTelegramWebApp, win } from "../utils";
import api from "../config/api";
import UserBalanceProiver, { Balance, useRefreshUserBalance } from "../public/UserBalanceProvier";
import SiteLoading from "../public/SiteLoading";
import { useGetLang } from "../public/LangProvier";
import { PlayGameProps } from "../public/PlayGameProvier";




type UserBalanceInitProps = {
    login?: Login
    children?: any
}

const UserBalanceInit: FC<UserBalanceInitProps> = ({ children }) => {

    const config = useGetSiteConfig();

    const balanceCache = localStorage.getItem("balance-cache");

    const [balance, setBalance] = useState<Balance>(balanceCache ? JSON.parse(balanceCache) : { balance: 0, currency: config?.currency });



    return (
        <>
            <UserBalanceProiver balance={{
                balance: balance, setBalance: value => {
                    setBalance(value);
                    localStorage.setItem("balance-cache", JSON.stringify(value));
                }
            }} >
                {children}
            </UserBalanceProiver>
        </>
    )
}


function getTelegramInitData() {

    const win: any = window;
    return win.Telegram.WebApp.initData;
}



export default (props: any) => {

    const loginCache = localStorage.getItem("login");

    const config = useGetSiteConfig();

    const [login, setLogin] = useState<Login>(loginCache == null ? { login: false } : JSON.parse(loginCache));

    const [loading, setLoading] = useState(true);

    const [gameData, setGameData] = useState<PlayGameProps | any>(null);

    const lang = useGetLang();

    const checkLogin = () => {
        const clientType = getClientType();

        const isTgClient = (clientType == "TELEGRAM_MIN_APP");

        const apiUrl = isTgClient ? "login/telegram/minapp" : "login/check";

        const data = isTgClient ? {
            data: getTelegramInitData()
        } : {};

        console.log("data=>",data,getTelegramWebApp())

        setLoading(true)
        api(apiUrl).post(data).then(ret => {

            if (ret.code == 200) {

                setLogin({
                    user: ret.data,
                    login: true,
                });

                localStorage.setItem("login", JSON.stringify({
                    user: ret.data,
                    login: true,
                }));

            }
            if (ret.code == 401) {
                localStorage.removeItem("login");
                setLogin({
                    login: false,
                    user: {}
                })
            }


        }).finally(() => {
            setLoading(false);
        })


    }



    useEffect(() => {


        checkLogin();

        // console.log("tg=>data:",getTelegramInitData());


    }, [login?.login]);

    const RouteComponent = props.routeComponent;


    if (login == null) {

        return (
            <SiteLoading />
        )
    }





    return (

        <LoginProiver value={{
            login: login,
            setLogin(login) {
                setLogin(login);
                localStorage.setItem("login", JSON.stringify(login));
            },
        }}>

            <UserBalanceInit login={login} >
                {RouteComponent ? <RouteComponent /> : props.children}
            </UserBalanceInit>
        </LoginProiver>
    )

}