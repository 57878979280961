
import React, { useState, useRef, useEffect } from 'react'
import { LuckyWheel } from '@lucky-canvas/react'
import { useNavigate, useParams, useSearchParams, useLocation } from 'react-router-dom'
import api from '../../../config/api';
import "./Index.scss";
import Loading from '../../../public/Loading';
import { Popover, Popup, Toast, NoticeBar, List, NavBar, Space, Button, ErrorBlock, CenterPopup } from 'antd-mobile';
import Modal from '../../../public/Modal';
import { CloseOutline, UserOutline } from 'antd-mobile-icons';
import { encodeBase64, formatAmount, getClientType, hideBackBotton, showCurrency, stringToHex, updateInviteFrom } from '../../../utils';
import { useGetSiteConfig } from '../../../public/SiteConfigProvier';
import { addAndroidBackListener, removeAndroidBackListener } from '../../../public/OnAndroidBack';
import { useGetCurrentPage, useGetLastPagePath } from '../../../public/AppProvier';
import { useGetLocale } from '../../../public/LangProvier';
import { useGetLogin } from '../../../public/LoginProvier';



const Lottery = ({ lottery, loadData }) => {


    const [params] = useSearchParams();

    const id = params.get("id");

    const [loading, setLoading] = useState(false)
    const [openBox, setOpenBox] = useState(false);

    const config = useGetSiteConfig();

    const navigate = useNavigate();

    const location = useLocation();

    const getLastPagePath = useGetLastPagePath();

    const currenctPage = useGetCurrentPage(location.pathname);

    const locale = useGetLocale();

    const [blocks] = useState([
        {
            padding: '8px', imgs: [

                {
                    src: require("./images/s1.png"),
                    width: '100%',
                    height: '100%',
                    rotate: true
                }
            ]
        },
    ])




    const [buttons] = useState([
        {
            radius: '40%',
            imgs: [{
                src: require("./images/s2.png"),
                width: '90%',
                top: '-120%'
            }]
        }
    ])
    const myLucky = useRef();

    const [data, setData] = useState(lottery);

    const [drawData, setDrawData] = useState(null);

    const [showResult, setShowResult] = useState(false);

    const [showNoMore, setShowNoMore] = useState(false);

    const { login, user } = useGetLogin();




    const reportShareData = (uid) => {
        api(`lottery/share`).post({
            lotteryId: id,
            uid: uid
        });
    }

    useEffect(() => {



        setData(lottery);

        const uid = params.get("uid");

        if(uid){
            updateInviteFrom({
                userId:uid
            });
        }

        if (login && uid) {
            reportShareData(uid);
        }


    }, [lottery, login]);

    const colorMap = {
        // "0":"#676C71",
        "0": "#FFC700",
        "1": "#FA244B",
        "2": "#FF9900",
        "3": "#5F3BBE",
        "4": "#07C616"
    }

    const prizeValues = data == null ? [] : data.prizes?.map((it, i) => {

        const v = i % 2;

        let color = colorMap[`${i}`];

        // console.log("color=>", color);

        if (!color) {
            color = v == 0 ? "#C19DE4" : "#F1DB8B";
        }



        return {
            id: it.id,
            item: it,
            background: color,
            imgs: [
                {
                    src: it.image,
                    width: '60px',
                    top: '20px',
                }
            ],
            fonts: [

            ]

        }
    });

    // console.log("prizeValues=>", prizeValues);


    const prizes = [
        {
            background: "#676C71",
            imgs: [
                { src: require("./images/s3.png"), width: "60px", top: "20px" }
            ]
        },
        ...[...prizeValues]
    ]

    const getPrizeIndex = (rows, prizeId) => {

        // console.log("prizeId=>", prizeId);

        if (prizeId == null || prizeId == undefined) {
            return 0;
        }

        for (var i = 0; i < rows.length; i++) {

            var r = rows[i];

            if (r.id == prizeId) {
                return i;
            }

        }

        return 0;

    }


    const doLottery = () => {

        setLoading(true);
        api("lottery/draw").post({
            lotteryId: parseInt(id),
            time: new Date().getTime()
        }).then(ret => {

            if (ret.code == 200) {

                const index = getPrizeIndex(prizes, ret.data.prizeId);

                setTimeout(() => {
                    // const index = Math.random() * 6 >> 0
                    myLucky.current.stop(index);
                    setDrawData(ret.data);

                }, 2500);


                loadData();

            } else {
                myLucky.current.stop(0);
            }


        }).catch(() => {
            myLucky.current.stop(0);
            // setLoading(false);
        })
            .finally(() => {

            })

    }


    const showLogin = () => {
        Modal.confirm({
            title: locale("tip"),
            content: locale("pls_login"),
            okText: locale("text.ok"),
            cancelText: locale("text.cancel"),
            onOk: () => {
                navigate(`/login?backUrl=${encodeURIComponent(window.location.href)}`);
            }
        })
    }



    const shareToTwitter = () => {

        if (!login) {
            showLogin();
            return;
        }

        const shareUrl = `${data?.shareRemark} \n${window.location.origin}/ativity/lottery?id=${id}&uid=${user?.id}`;
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareUrl)}`;

        const clientType = getClientType();
        if (clientType == "TELEGRAM_MIN_APP") {
            window.Telegram.WebApp.openLink(url);
            return;
        }

        if (clientType == "PC") {
            window.open(url, "_blank");
            return;
        }

        window.location.href = url;




    }

    const shareToLink = () => {
        if (!login) {
            showLogin();
            return;
        }

        const shareUrl = `${data?.shareRemark} \n${window.location.origin}/activity/lottery?id=${id}&uid=${user?.id}`;

        navigator.clipboard.writeText(shareUrl).then(ret => {

            Toast.show({
                content: locale("copy_success")
            })

        })


    }

    const shareToFacebook = () => {

        if (!login) {
            showLogin();
            return;
        }

        const shareUrl = `${window.location.origin}/ativity/lottery?id=${id}&uid=${user?.id}`;
        const url = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(shareUrl)}`;

        const clientType = getClientType();
        if (clientType == "TELEGRAM_MIN_APP") {
            window.Telegram.WebApp.openLink(url);
            return;
        }
        if (clientType == "PC") {
            window.open(url, "_blank");
            return;
        }
        window.location.href = url;




    }

    const shareToWhatsApp = ()=>{


        if (!login) {
            showLogin();
            return;
        }

        const shareUrl = `${data?.shareRemark} \n ${window.location.origin}/ativity/lottery?id=${id}&uid=${user?.id}`;
        const url = `https://api.whatsapp.com/send/?text=${shareUrl}&type=custom_url`;

        const clientType = getClientType();
        if (clientType == "TELEGRAM_MIN_APP") {
            window.Telegram.WebApp.openLink(url);
            return;
        }
        if (clientType == "PC") {
            window.open(url, "_blank");
            return;
        }
        window.location.href = url;

    }

    const shareToTelegram = () => {

        if (!login) {
            showLogin();
            return;
        }

        // alert(JSON.stringify(window.Telegram?.WebApp));



        const shareBotUrl = `https://t.me/${data?.bot}/app?startapp=d-${stringToHex(`page=${encodeURIComponent(`/activity/lottery?id=${id}&uid=${user.id}`)}`)}`;
        const text = `${data?.shareRemark}`;
        const url = `https://t.me/share/url?url=${encodeURIComponent(shareBotUrl)}&text=${encodeURIComponent(text)}`;
        // alert(window.Telegram?.WebApp?.version);
        if (window.Telegram) {
            window.Telegram.WebApp.openTelegramLink(url);
        }

        const clientType = getClientType();
        if (clientType == "TELEGRAM_MIN_APP") {
            window.Telegram.WebApp.openTelegramLink(url);
            return;
        }
        if (clientType == "PC") {
            window.open(url, "_blank");
        } else {
            window.location.href = url;
        }
       




    }




    if (data == null) {

        return (
            <div className='v2-loading'>
                <Loading />
            </div>
        )
    }


    return <div className='lottery-v2-bg '>


        <div className='v2-header'>

            {/* <NavBar onBack={() => {
                navigate("/", { replace: true });
            }} /> */}

            <div>
                <div className='v2-logo'>
                    <img src={require("./images/s5.png")} />
                </div>
            </div>

            <div className='v2-carousel'>
                <div className='v2-marquee-container'>
                    <div className='v2-marquee-box'>
                        {data?.winners?.map((it, i) => {
                            return (
                                <span key={i} style={{ marginRight: "10px", backgroundColor: "rgba(255,255,255,0.1)", color: "#fff", padding: 3, paddingLeft: 10, paddingRight: 10, borderRadius: 0 }} >
                                    {it?.user} Win {formatAmount(config.currency, it.amount)} {showCurrency(config.currency)}
                                </span>
                            )
                        })}
                    </div>

                </div>

            </div>

        </div>

        <div className='v2-lottery'>

            <div className='v2-lottery-pa'>
                <div style={{ position: "relative", width: "100%", height: "25.5rem", marginTop: "0rem" }}>


                    <div className={`v2-lucky-ly ${loading ? "disabled" : ""}`}>
                        <LuckyWheel
                            ref={myLucky}
                            width="25.5rem"
                            height="25.5rem"
                            blocks={blocks}
                            prizes={prizes}
                            buttons={buttons}
                            defaultConfig={{
                                gutter: '2px'
                            }}
                            onStart={() => { // 点击抽奖按钮会触发star回调

                                // const nowTime =  new Date().getTime();

                                if (!login) {

                                    showLogin();

                                    return;
                                }



                                if (data?.drawCount <= 0) {
                                    setShowNoMore(true);
                                    return;
                                }
                                if (!data.open) {
                                    Toast.show({ content: locale(data.msg) })
                                    return;
                                }

                                myLucky.current.play();

                                doLottery();
                                // setTimeout(() => {
                                //   const index = Math.random() * 6 >> 0
                                //   myLucky.current.stop(index)
                                // }, 2500)
                            }}
                            onEnd={prize => { // 抽奖结束会触发end回调
                                // alert('恭喜你抽到 ' + prize.fonts[0].text + ' 号奖品')

                                setShowResult(true);
                                setLoading(false);

                                if (data?.drawCount <= 0 && !drawData?.win) {
                                    setShowNoMore(true);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className='v2-base-ly'>
                    <div className='v2-base-img-ly'>
                        <img className='v2-base-img' src={require("./images/s6.png")} />
                    </div>
                </div>
            </div>
        </div>


        <div className='v2-lottery-ft'>
            <div className='v2-lc-ly'>
                <div className='v2-lottery-count-ly' >
                    <span>{locale("my_draw_count")} : </span>
                    <span style={{ color: "#FA244B" }}>{data?.drawCount}</span>
                </div>
            </div>
            <div className='v2-btns'>

                <div className='v2-btn-1' onClick={() => setOpenBox(true)}>
                    <span className='v2-mybox-top'>
                        {data?.boxItems?.length}
                    </span>
                    <span>{locale("MyBox")}</span>

                </div>
                <Popover.Menu placement="top" trigger="click" mode="dark"

                    actions={
                        [
                            {
                                text: locale("share_twitter"), icon: (

                                    <img src={require("../../../public/assets/x-twitter.png")} className='share-img-icon' />

                                ), style: { fontSize: 20 }, onClick: () => {
                                    shareToTwitter();
                                }
                            },

                            {
                                text: locale("share_telegram"), icon: (

                                    <img src={require("../../../public/assets/telegram.png")} className='share-img-icon' />

                                ), style: { fontSize: 20 }, onClick: () => {
                                    shareToTelegram();
                                }
                            },
                            {
                                text: locale("share_facebook"), icon: (

                                    <img src={require("../../../public/assets/Facebook.png")} className='share-img-icon' />

                                ), style: { fontSize: 20 }, onClick: () => {
                                    shareToFacebook();
                                }
                            },
                            {
                                text: locale("share_whatsapp"), icon: (

                                    <img src={require("../../../public/assets/whatsapp.png")} className='share-img-icon' />

                                ), style: { fontSize: 20 }, onClick: () => {
                                    shareToWhatsApp();
                                }
                            },
                            {
                                text: locale("share_link"), icon: (
                                    <img src={require("../../../public/assets/link.png")} className='share-img-icon' />
                                ), style: { fontSize: 20 }, onClick: () => {

                                    shareToLink();
                                }
                            }
                        ]

                    }

                >


                    <div className='v2-btn-2'>
                        {locale("SHARE")}
                    </div>
                </Popover.Menu>
            </div>
            <div className='v2-rule-title'>
                {locale("game_rules")}
            </div>
            <div className='v2-rule-box'>
                <div className='v2-rule-html' dangerouslySetInnerHTML={{
                    __html: data?.rules
                }} ></div>

                
            </div>
            <div style={{height:30}}></div>
        </div>






        {
            (drawData != null && showResult) ? (
                <>
                    <div className='v2-result-panel'>

                        <div className={`v2-win-title v2-win-title-${drawData.win}`}>
                            {/* {drawData.text} */}
                            {drawData.win ? "Congratulations!" : "miss!"}
                        </div>

                        <div>
                            {drawData.win ? (
                                <>
                                    <div className='v2-win-text1'>
                                        You won
                                    </div>
                                    {drawData?.amount ? (
                                        <div className='v2-win-amount'>
                                            {formatAmount(config.currency, drawData?.amount)}
                                        </div>
                                    ) : null}
                                    <div className='v2-win-box'>
                                        <div style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                                            <img src={drawData.image} className='v2-draw-image scale-up-center' />
                                        </div>


                                    </div>
                                </>
                            ) : (
                                <>
                                    <div>
                                        <div className='v2-not-win-text'>
                                            {locale("not_win")}
                                        </div>
                                        <div className='not-win-text-v2'>
                                            <img src={require("./images/s7.png")} className='v2-draw-image' />
                                        </div>

                                    </div>
                                </>
                            )}
                        </div>
                        <div className='v2-btn-text' onClick={() => {
                            setDrawData(null);
                            setShowResult(false);
                            if (data?.drawCount <= 0) {
                                setShowNoMore(true);
                            }

                        }}>
                            {/* {drawData.btnText} */}
                            {locale("text.ok")}
                        </div>


                    </div>
                </>
            ) : null
        }









        <Popup

            visible={openBox}

            title="MyBox"

            onClose={() => setOpenBox(false)}
            placement="bottom"
        // showCloseButton

        >

            <div style={{ minHeight: 300 }}>
                <NavBar backIcon={null} back={locale("my_boxs")} right={<CloseOutline onClick={() => {
                    setOpenBox(false);

                }} fontSize={24} />}  ></NavBar>
                <List mode="card" >
                    {data?.boxItems.length == 0 ? (
                        <ErrorBlock status="empty" title={locale("no_data")} description={""} />
                    ) : null}
                    {data?.boxItems?.map((r, index) => {

                        return (
                            <List.Item
                                key={index}
                                prefix={
                                    <img src={r.image} style={{ width: "3.8rem" }} />
                                }
                                extra={
                                    <Space align="center">
                                        <span style={{ marginRight: 20 }}>{`${r.progress}%`}</span>
                                        <Button color="primary" fill="outline" size="small" onClick={() => {

                                            Modal.confirm({

                                                // title: "Open Box",

                                                content: (
                                                    <>
                                                        <div>
                                                            <div>
                                                                <div style={{ marginBottom: 10, textAlign: "left" }}>{locale("open_rule")}:</div>
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 10 }}>
                                                                    {r.keys.map((it, i) => {

                                                                        return it.num > 0 ? (
                                                                            <span key={i} style={{ marginRight: 10, display: "flex", alignItems: "center", flexDirection: "row" }}>
                                                                                <img style={{ width: "3rem" }} src={`https://assets.uf88.me/lottery/key_${it.name}.png`} />
                                                                                <span>x{it.num}</span>
                                                                            </span>
                                                                        ) : null

                                                                    })}
                                                                </div>
                                                            </div>
                                                            <div  >
                                                                <div style={{ marginBottom: 10, textAlign: "left" }}>{locale("my_keys")}:</div>
                                                                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>


                                                                    {data?.keyItems?.map((it, i) => {

                                                                        return (
                                                                            <span key={i} style={{ marginRight: 10, display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "flex-start" }}>
                                                                                <img style={{ width: "3rem" }} src={`https://assets.uf88.me/lottery/key_${it.name}.png`} />
                                                                                <span>x{it.num}</span>
                                                                            </span>
                                                                        )

                                                                    })}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                                okText: "Open",
                                                cancelText: "Cancel",

                                                onOk: () => {



                                                    api("lottery/open/box").post({

                                                        id: r.id
                                                    }).then(ret => {

                                                        if (ret.code == 200) {

                                                            // You get ${r.amount} ${r.token}`
                                                            Toast.show({
                                                                icon: "success",
                                                                content: locale("win_box", { amount: `${formatAmount(null, r.amount)} ${showCurrency(config.currency)}` })
                                                            });



                                                            setOpenBox(false);

                                                            loadData();

                                                        }

                                                    }).finally(() => {

                                                    })
                                                }
                                            })




                                        }} >Open</Button>
                                    </Space>
                                }

                            >
                                {formatAmount(config.currency, r.amount)}
                            </List.Item>
                        )
                    })}
                </List>

            </div>


        </Popup>


        {/* <div className='v2-no-more' style={{ display: showNoMore ? "flex" : "none" }}>


            <div className='v2-nomore-box'>
                <div className='v2-nomore-box-view'>
                    <div className='v2-nbv'>
                        <div className='v2-nbv-header'>
                            
                       </div>
                        
                    </div>
                </div>

            </div>



        </div> */}

        <Popup visible={showNoMore} onClose={() => setShowNoMore(false)} showCloseButton>

            <div style={{ padding: 20,paddingTop:40 }}>
                <List>
                    <List.Item
                        onClick={shareToTwitter}
                        prefix={
                            <img src={require("../../../public/assets/x-twitter.png")} className='share-img-icon' />
                        }
                    >
                        {locale("share_twitter")}
                    </List.Item>
                    <List.Item
                        onClick={shareToTelegram}
                      prefix={
                        <img src={require("../../../public/assets/telegram.png")} className='share-img-icon' />
                    }
                    >
                        {locale("share_telegram")}
                    </List.Item>
                    <List.Item
                        onClick={shareToFacebook}
                      prefix={
                        <img src={require("../../../public/assets/Facebook.png")} className='share-img-icon' />
                    }
                    >
                        {locale("share_facebook")}
                    </List.Item>
                    <List.Item
                        onClick={shareToWhatsApp}
                      prefix={
                        <img src={require("../../../public/assets/whatsapp.png")} className='share-img-icon' />
                    }
                    >
                        {locale("share_whatsapp")}
                    </List.Item>
                    <List.Item
                    onClick={shareToLink}
                      prefix={
                        <img src={require("../../../public/assets/link.png")} className='share-img-icon' />
                    }
                    >
                        {locale("share_link")}
                    </List.Item>
                </List>
             


            </div>

        </Popup>


    </div >
}

export default () => {


    const [params] = useSearchParams();

    const id = params.get("id");


    const [lottery, setLottery] = useState(null);

    const loadData = () => {


        api("lottery/data").post({
            lotteryId: id,
            time: new Date().getTime()
        }).then(ret => {

            if (ret.code == 200) {
                setLottery(ret.data);
            }

        })



    }

    useEffect(() => {
        loadData();
    }, [])


    if(lottery == null){

        return (
            <Loading/>
        )
    }


    return (
        <div className="lottery-page">
            <Lottery loadData={loadData} lottery={lottery} />
        </div>
    )
}