import React, { createContext, FC, useContext } from "react";

export type Config = {
   
    type:any,
    open:boolean

}

type LoginRegisterValueProps = {
    config:Config,
    setConfig:(value:Config)=>void
}|null

const LoginRegisterOpenContext = createContext<LoginRegisterValueProps>(null);

export const useGetLoginRegisterOpen = ()=>{

    const contenxt = useContext(LoginRegisterOpenContext);

    return contenxt;

}

export const useSetLoginRegisterOpen = ()=>{
    const contenxt = useContext(LoginRegisterOpenContext);
    return (value:Config)=>{
    
        contenxt?.setConfig(value);
    }
}

type LoginRegisterOpenProiverProps = {
    value:LoginRegisterValueProps,
    children?:React.ReactNode
}

const LoginRegisterOpenProiver:FC<LoginRegisterOpenProiverProps> =  ({children,value})=>{
        return (
            <LoginRegisterOpenContext.Provider value={value}>
                {children}
            </LoginRegisterOpenContext.Provider>
        )

}


export default LoginRegisterOpenProiver;