export default {
    "login.failed": "Error de inicio de sesión",
    "login.user_disabled": "Usuario deshabilitado",
    "login.account_error": "Nombre de usuario o contraseña incorrectos",
    "login.lock_error": "Error de inicio de sesión, cuenta bloqueada durante 30 minutos",
    "reg.fail": "Error en el registro",
    "reg.user_name_exists": "El nombre de usuario ya existe",
    "text.login": "Iniciar sesión",
    "text.reg": "Registrarse",
    "text.username": "Nombre de usuario",
    "text.password": "Contraseña",
    "text.password2": "Confirmar contraseña",
    "text.passwod2_err": "¡Las contraseñas que ingresaste no coinciden!",
    "recent_play": "Jugadas recientes",
    "open_game_fail": "Error al abrir el juego",
    "text.ok": "OK",
    "deposit_way": "Método de depósito",
    "deposit": "Depósito",
    "amount": "Cantidad",
    "confirm_deposit": "Confirmar depósito",
    "input_deposit_amount": "La cantidad del depósito debe estar entre ${start} - ${end}",
    "home": "Inicio",
    "task": "Tarea",
    "message": "Mensaje",
    "mine": "Mío",
    "close": "Cerrar",
    "refresh": "Actualizar",
    "logout": "Cerrar sesión",
    "withdraw": "Retirar",
    "balance": "Saldo",
    "deposit_order": "Pedido de depósito",
    "withdraw_order": "Pedido de retiro",
    "game_records": "Historial de juegos",
    "all": "Todo",
    "withdraw_account": "Cuenta de retiro",
    "pay_amount": "Pagar ${amount}",
    "pay_success": "Pago exitoso",
    "pay_success_info": "Su pedido ha sido pagado con éxito",
    "my_order": "Mis pedidos",
    "apply_withdraw": "Solicitud de retiro",
    "confirm_withdraw": "Confirmación de retiro",
    "real_name": "Nombre real",
    "input_real_name": "Por favor, ingrese su nombre",
    "text.confirm": "Confirmar",
    "text.cancel": "Cancelar",
    "add_withdraw_account": "Agregar cuenta",
    "pls_select": "Por favor seleccione",
    "back": "Volver",
    "account_type": "Tipo de cuenta",
    "account_num": "Número de cuenta",
    "pls_input": "Por favor ingrese ${value}",
    "fiat_currency": "Moneda fiduciaria",
    "input_amount": "Por favor, ingrese la cantidad",
    "logout_text": "¿Estás seguro de que deseas cerrar sesión?",
    "tip": "Consejo",
    "user_center": "Centro de usuarios",
    "close_game": "¿Confirmar salida?",
    "receive_account": "Cuenta receptora",
    "insufficient_balance": "Saldo insuficiente",
    "apply_success": "Solicitud exitosa",
    "processing_withdraw_order": "Hay un pedido de retiro en proceso, por favor inténtelo más tarde.",
    "create_order_fail": "Error al crear el pedido, por favor inténtelo de nuevo",
    "add_success": "Agregado exitosamente",
    "delete_success": "Eliminado exitosamente",
    "add": "Agregar",
    "pls_input_pwd": "Por favor, ingrese la contraseña",
    "password_err": "Contraseña incorrecta",
    "lang_setting": "Configuración de idioma",
    "Slot": "Tragamonedas",
    "Fish": "Pescado",
    "Bingo": "Bingo",
    "Live": "En vivo",
    "Poker": "Póker",
    "ForYou": "Para ti",
    "MINI": "Mini",
    "ARCADE": "Arcade",
    "more":"Más"
}