

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./index.scss";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


// 使用 React.lazy 动态导入组件
const LazyComponent = React.lazy(() => import('./App'));

const AppIndexLazyComponent = React.lazy(() => import('../src/pages/h5/app/Index'));

const App = () => {
  return (

    <Suspense fallback={
      <div style={{ width: "100vw", height: "100vh", background: "var(--background)" }}>

       
          <div className="loader"></div>
        

      </div>
    } >
      <LazyComponent />
    </Suspense>

  );
};

const AppIndex = () => {
  return (

    <Suspense fallback={
      <div style={{ width: "100vw", height: "100vh", background: "#fff" }}></div>
    } >
      <AppIndexLazyComponent />
    </Suspense>

  );
};

const landingPage = ["/app"].includes(window.location.pathname);
if (landingPage) {
  root.render(

    <AppIndex />

  );
} else {
  root.render(

    <App />

  );

}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
