import { Footer, NavBar, NavBarProps, Popup } from 'antd-mobile';
import { AndroidClient, getClientType, hideBackBotton, showBackBotton } from '../utils';
import { Location, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';

import { CloseOutline } from 'antd-mobile-icons';
import { useGetLocale } from '../public/LangProvier';
import { motion, AnimatePresence } from 'framer-motion';
import './h5-tabbar-layout.scss';
import { useGetCurrentPage, useGetLastPagePath } from '../public/AppProvier';
import { addAndroidBackListener, removeAndroidBackListener } from '../public/OnAndroidBack';


export type NavigateConfig = {
    title?: string,
    onBack?: () => void | undefined,
    navBarProps?: NavBarProps
}



export default (props: any) => {

    const clientType = getClientType();

    const navigate = useNavigate();

    const RouteComponent = props.routeComponent;

    const [navigateConfig, setNavigateConfig] = useState<NavigateConfig>({});

    const locale = useGetLocale();

    const location = useLocation();

    const getLastPagePath = useGetLastPagePath();

    const currenctPage = useGetCurrentPage(location.pathname);

    const [path, setPath] = useState("");






    // const data = dataCache[`${location.pathname}`];

    const onBack = () => {

        if (clientType == "PC") {
            navigate("/", { replace: true });
            return;
        }

        const path = getLastPagePath();
        const currentPath = currenctPage()?.path;

        if (path == currentPath) {
            navigate("/", { replace: true });
            return;
        }

        if (path) {
            navigate(path);
            return;
        }
        navigate("/", { replace: true });
    }

    useEffect(() => {

        if (clientType == "TELEGRAM_MIN_APP") {
            showBackBotton(() => {
                navigate(-1);
            });
        }





        setPath(location.pathname);



        console.log("init=>{}", location.pathname);


        addAndroidBackListener("navbar",()=>{
            onBack();
        })




        return () => {

            removeAndroidBackListener("navbar")
            hideBackBotton();
            

        }

    }, []);





    if (clientType == "PC" && location.pathname == "/play/game") {

        return RouteComponent ? RouteComponent : props.children;
    }



    if (location.pathname == "/") {
        return null;
    }

    if (!path) {

        return null;
    }



    return (

        <div className="app">
            {clientType == "TELEGRAM_MIN_APP" ? (<></>) : (
                <>
                    <div className={"top"}>
                        {clientType == "PC" ? (
                            <>
                                <NavBar
                                    // onBack={navigateConfig?.onBack ? navigateConfig.onBack : onBack}
                                    back={navigateConfig?.title}
                                    backIcon={null}
                                    right={
                                        <div onClick={navigateConfig?.onBack ? navigateConfig.onBack : onBack}>
                                            <CloseOutline fontSize={24} />
                                        </div>
                                    }
                                >

                                </NavBar>
                            </>
                        ) : (
                            <NavBar
                                onBack={navigateConfig?.onBack ? navigateConfig.onBack : onBack}
                                {...navigateConfig?.navBarProps}
                            >
                                {navigateConfig?.title}
                            </NavBar>
                        )}

                    </div>
                </>
            )}

            <div className={"body"}>
                {clientType == "TELEGRAM_MIN_APP" ? (
                    <div className='tg-title'>
                        {navigateConfig?.title}
                    </div>
                ) : null}
                <div>
                    {RouteComponent ? <RouteComponent onNavigateConfig={(config: NavigateConfig) => {
                        setNavigateConfig(config);
                        var win: any = window;
                        if (config?.title) {
                            win.document.title = config.title;
                        }


                    }} /> : props.children}
                </div>

                {/* <Footer
                    content={`@ ${dayjs().format("YYYY")} ${window.location.hostname} All rights reserved`}
                    style={{ marginBottom: 15 }}
                ></Footer> */}

                <div style={{ height: 45 }}></div>
            </div>
        </div>
    )
}