
export default {

    "login.failed":"Login failed",
    "login.user_disabled":"User Disabled",
    "login.account_error":"Username or password is incorrect",
    "login.lock_error":"Login fails,account locked for 30 minutes",
    "reg.fail":"Register failed",
    "reg.user_name_exists":"Username already exists",
    "text.login":"Login",
    "text.reg":"Register",
    "text.username":"Username",
    "text.password":"Password",
    "text.password2":"Confirm Password",
    "text.passwod2_err":"The new password that you entered do not match!",
    "recent_play":"Recently Plays",
    "open_game_fail":"Failed to open the game",
    "text.ok":"OK",
    "deposit_way":"Deposit Way",
    "deposit":"Deposit",
    "amount":"Amount",
    "confirm_deposit":"Confirm deposit",
    "input_deposit_amount":"Deposit amount between ${start} - ${end}",
    "home":"Home",
    "task":"Task",
    "message":"Message",
    "mine":"Mine",
    "close":"Close",
    "refresh":"Refresh",
    "logout":"Log out",
    "withdraw":"Withdraw",
    "balance":"Balance",
    "deposit_order":"Deposit Order",
    "withdraw_order":"Withdrawal Order",
    "game_records":"Game Records",
    "all":"All",
    "withdraw_account":"Withdraw Account",
    "pay_amount":"Pay ${amount}",
    "pay_success":"Payment successful",
    "pay_success_info":"Your order has been paid successfully",
    "my_order":"My Orders",
    "apply_withdraw":"Withdrawal Application",
    "confirm_withdraw":"Withdrawal confirmation",
    "real_name":"Name",
    "input_real_name":"Please enter your name",
    "text.confirm":"Confirm",
    "text.cancel":"Cancel",
    "add_withdraw_account":"Add Account",
    "pls_select":"Please select",
    "back":"Back",
    "account_type":"Account Type",
    "account_num":"Account Number",
    "pls_input":"Please Input ${value}",
    "fiat_currency":"Fiat Currency",
    "input_amount":"Please enter amount",
    "logout_text":"Are you sure you want to log out?",
    "tip":"Tip",
    "user_center":"User Center",
    "close_game":"Confirm Exit?",
    "receive_account":"Receiving account",
    "insufficient_balance":"Insufficient balance",
    "apply_success":"Application Success",
    "processing_withdraw_order":"There is a withdrawal order that is being paid, please try again later.",
    "create_order_fail":"Order creation failed, please try again",
    "add_success":"Added successfully",
    "delete_success":"Deleted successfully",
    "add":"Add",
    "pls_input_pwd":"Please enter password",
    "password_err":"Wrong password",
    "lang_setting":"Language settings",
    "Slot":"Slot",
    "Fish":"Fish",
    "Bingo":"Bingo",
    "Live":"Live",
    "Poker":"Poker",
    "ForYou":"ForYou",
    "MINI":"Mini",
    "ARCADE":"Arcade",
    "more":"More",
    "copy_success":"Copy Success",
    "wait_pay":"To be paid",
    "paid":"Paid",
    "cancelled":"Cancelled",
    "unpaid":"Unpaid",
    "open":"Open",
    "my_account":"My Accounts",
    "processing":"Processing",
    "fail":"Fail",
    "success":"Successful",
    "my_boxs":"My Boxs",
    "game_rules":"GAME RULES",
    "Me":"Me",
    "download_app_text":"We would like to add it to your home screen for the latest free bets and bonus updates.",
    "click_install":"Click Install",
    "Home":"Home",
    "i_know":"I already know",
    "click_me":"Click me install",
    "Mine":"Mine",
    "lottery":"Lucky draw",
    "no_account":"No account yet?",
    "other_login_way":"Other way",
    "login.access_denied":"Access denied",
    "services":"Services",
    "Promotions":"Promotions",
    "lottery_ending":"This lottery event has ended.",
    "lottery_not_start":"The lottery event has not started yet.",
    "no_more_draw_count":"No more lottery attempts available.",
    "pls_login":"Not logged in. Please log in first.",
    "MyBox":"My Gift Box",
    "SHARE":"Share",
    "my_draw_count":"Available Lottery Attempts",
    "lottery_not_match":"Does not meet the rules of this event.",
    "not_win":"Make persistent efforts",
    "open_rule":"Opening Conditions",
    "my_keys":"My keys",
    "box_not_exist":"“The treasure box does not exist.",
    "open_box_fail":"Does not meet the opening conditions.",
    "win_box":"Successfully received ${amount}. The reward has been automatically sent to your account.",
    "share_twitter":"Share on X/Twitter",
    "share_telegram":"Share on Telegram",
    "share_facebook":"Share on Facebook",
    "share_link":"Copy the link and send to friends",
    "share_whatsapp":"Share on WhatsApp",
    "no_data":"No data",
    "username_tip1":"Account format: 4-16 characters of English letters or numbers",
    "password_tip1":"Password format: 4-16 characters",
    "confirm_delete":"Are you sure to delete?",
    "delete_fail":"Delete Fail",
    "delete_success":"Delete Successful",
    "is_close":"Confirm Close?",
    "deposit_record":"Deposit Record",
    "withdraw_record":"Withdraw Record",
    "withdraw_tip":"The withdrawal conditions are not met. Your total betting amount needs to exceed ${needAmount} to proceed with the withdrawal.",
    "withdraw_amount":"Withdrawable amount",
    "input_withdraw_amount":"Withdraw amount between ${start} - ${end}",
    "bet_amount_all":"Your current total betting amount is ${betAmountAll}.",
    "play_again":"Go and continue playing.",
    "login_password":"Login password."
   

};  