import { useEffect } from "react";
import Loading from "../public/Loading";
import { getClientType } from "../utils"
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

export default ()=>{


    
   const navigate = useNavigate();

   const params = useSearchParams();


   useEffect(()=>{

     const clientType =  getClientType();
     
     if(clientType == "PC"){

        navigate({pathname:"/"},{replace:true});

     }else{
        navigate({pathname:"/"},{replace:true});
     }

     


   },[])


    return (
        <>
            <Loading/>;
        </>
    )
}