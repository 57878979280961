// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-withdarw-account-page .title {
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/user/add-withdarw-account.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AAAR","sourcesContent":[".add-withdarw-account-page{\n    .title{\n        padding: 12px;\n        font-size: 16px;\n        font-weight: bold;\n        margin-bottom: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
