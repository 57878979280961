import { useEffect, useState } from "react";
import api from "../../../config/api";
import { Badge, Button, CenterPopup, NavBar, Popup } from "antd-mobile";
import ImageView from "../../../public/ImageView";
import { CloseOutline, CloseCircleOutline, MessageOutline } from "antd-mobile-icons";
import "./ShowNotice.scss";
import { useGetSiteConfig } from "../../../public/SiteConfigProvier";
import { useGetLocale } from "../../../public/LangProvier";
import { useNavigate } from "react-router";
import { jumpPage } from "../../../utils";
import { useGetLogin } from "../../../public/LoginProvier";


type ShowNoticeProps = {
    onFinish?: () => void;
}

const loadImages = (images: any[], onSuccess: () => void) => {


    var count = 0;

    const loadfinish = () => {
        count++;

        if (count >= images.length) {

            onSuccess();
        }

    }

    images.forEach((url) => {

        var img = new Image();

        img.src = url;

        img.onload = function () {
            loadfinish();
        };

        img.onerror = function () {
            loadfinish();
        };

    })


}


export default (props: ShowNoticeProps) => {


    const [notices, setNotices] = useState<any[]>([]);

    const [index, setIndex] = useState<number>(0);

    const config = useGetSiteConfig();

    const locale = useGetLocale();

    const noticeFinish = sessionStorage.getItem("noticeFinish");

    const { login } = useGetLogin();

    const navigate = useNavigate();

    const [imageLoadingEnd,setImageLoadingEnd] = useState(false);

    const loadNotices = () => {

        if (noticeFinish) {
            props.onFinish?.();
        }


        api("site/notice").cache(true).get({}).then(ret => {

            if (ret.code == 200) {
                setNotices(ret.data);


                loadImages(ret.data?.filter((x:any)=>x.type == 0).map((x:any)=>x.image),()=>{

                    setImageLoadingEnd(true);

                });
            }

        });


    }

    const nextNotice = () => {

        sessionStorage.setItem(`show-notice-${notice?.id}`, "yes");
        if (index == (notices.length - 1)) {
            sessionStorage.setItem("noticeFinish", "yes");
            props?.onFinish?.();
        }
        setIndex(index + 1);

    }



    useEffect(() => {

        loadNotices();
    }, [login]);

    if(!imageLoadingEnd){
        return null;
    }

    if (notices?.length == 0 || notices.length == index) {

        return null;
    }

   



    const notice = notices[index];

    const hasShow = sessionStorage.getItem(`show-notice-${notice?.id}`);

    if (hasShow) {
        return null;
    }



    return (
        <CenterPopup
            visible={true}

            className="notice-popup"
            getContainer={document.body}


        >

            {notice?.type == 0 ? (
                <div className="notice-body">

                    <ImageView className="notice-img" src={notice?.image} onClick={() => {
                        if (notice.jumpData) {
                            jumpPage(notice.jumpData, navigate)
                            return;
                        }

                        navigate(`/notice/detail?id=${notice.id}`);


                    }} />

                    <div style={{ textAlign: "center" }}>
                        <CloseCircleOutline fontSize={48} onClick={nextNotice} />
                    </div>

                </div>
            ) : null}

            {notice?.type == 1 ? (
                <div className="notice-text" >
                    <NavBar backIcon={<MessageOutline />} className="title" right={
                        <CloseOutline onClick={nextNotice} fontSize={24} />
                    }>{notice?.title}</NavBar>
                    <div className="notice-text-content" dangerouslySetInnerHTML={{
                        __html: notice?.content
                    }}></div>
                    {/* <div className="notice-text-bottom">

                        <Button onClick={() => {
                            if (notice.jumpData) {
                                jumpPage(notice.jumpData, navigate)
                                return;
                            }

                            nextNotice();

                        }} color="primary">{locale("i_know")}</Button>
                    </div> */}
                </div>
            ) : null}



        </CenterPopup>
    )


}