type ConfigEnv = {
  baseApi: string,
  brandId:number,
}

const configValues: any = {
 
  baseApi: `${process.env.REACT_APP_API_URL}`,
}

const env: ConfigEnv = {
  ...configValues,
}

export default env
