
export default {

  "login.failed": "登录失败",
  "login.user_disabled": "用户被禁用",
  "login.account_error": "用户名或密码不正确",
  "login.lock_error": "登录失败，账户锁定30分钟",
  "reg.fail": "注册失败",
  "reg.user_name_exists": "用户名已存在",
  "text.login": "登录",
  "text.reg": "注册",
  "text.username": "用户名",
  "text.password": "密码",
  "text.password2": "确认密码",
  "text.passwod2_err": "您输入的新密码不匹配！",
  "recent_play": "最近播放",
  "open_game_fail": "打开游戏失败",
  "text.ok": "确定",
  "deposit_way": "充值方式",
  "deposit": "充值",
  "amount": "金额",
  "confirm_deposit": "确认充值",
  "input_deposit_amount": "充值金额应在 ${start} - ${end} 之间",
  "home": "首页",
  "task": "任务",
  "message": "消息",
  "mine": "我的",
  "close": "关闭",
  "refresh": "刷新",
  "logout": "退出登录",
  "withdraw": "提现",
  "balance": "余额",
  "deposit_order": "充值订单",
  "withdraw_order": "提现订单",
  "game_records": "游戏记录",
  "all": "全部",
  "withdraw_account": "提现账户",
  "pay_amount": "支付 ${amount}",
  "pay_success": "支付成功",
  "pay_success_info": "您的订单已成功支付",
  "my_order": "我的订单",
  "apply_withdraw": "申请提现",
  "confirm_withdraw": "确认提现",
  "real_name": "姓名",
  "input_real_name": "请输入您的姓名",
  "text.confirm": "确认",
  "text.cancel": "取消",
  "add_withdraw_account": "添加账户",
  "pls_select": "请选择",
  "back": "返回",
  "account_type": "账户类型",
  "account_num": "账户号码",
  "pls_input": "请输入 ${value}",
  "fiat_currency": "法定货币",
  "input_amount": "请输入金额",
  "logout_text": "确定要退出登录吗？",
  "tip": "提示",
  "user_center": "用户中心",
  "close_game": "确认退出？",
  "receive_account": "收款账户",
  "insufficient_balance": "余额不足",
  "apply_success": "申请成功",
  "processing_withdraw_order": "有一个正在支付的提现订单，请稍后再试。",
  "create_order_fail": "订单创建失败，请重试",
  "add_success": "添加成功",
  "delete_success": "删除成功",
  "add": "添加",
  "pls_input_pwd": "请输入密码",
  "password_err": "密码错误",
  "lang_setting": "语言设置",
  "Slot": "老虎机",
  "Fish": "捕鱼",
  "Bingo": "宾果",
  "Live": "真人娱乐",
  "Poker": "扑克",
  "ForYou": "推荐",
  "MINI": "小游戏",
  "ARCADE": "街机",
  "more": "更多",
  "copy_success": "复制成功",
  "wait_pay": "待支付",
  "paid": "已支付",
  "cancelled": "已取消",
  "unpaid": "未支付",
  "open": "打开",
  "my_account": "我的账户",
  "processing": "处理中",
  "fail": "失败",
  "success": "成功",
  "my_boxs": "我的盒子",
  "game_rules": "游戏规则",
  "Me": "我",
  "download_app_text": "我们希望将其添加到您的主屏幕，以获取最新的免费投注和奖金更新。",
  "click_install": "点击安装",
  "Home": "首页",
  "i_know": "我知道了",
  "click_me": "点击我安装",
  "Mine": "我的",
  "lottery": "抽奖",
  "no_account": "还没有账户？",
  "other_login_way": "其他登录方式",
  "login.access_denied": "访问被拒绝",
  "services": "服务",
  "Promotions": "优惠活动",
  "lottery_ending": "本次抽奖活动已结束。",
  "lottery_not_start": "抽奖活动未开始。",
  "no_more_draw_count": "没有更多的抽奖次数。",
  "pls_login": "未登录，请先登录。",
  "MyBox": "我的礼物宝箱",
  "SHARE": "分享",
  "my_draw_count": "可用抽奖次数",
  "lottery_not_match": "不满足本次活动规则。",
  "not_win": "再接再厉",
  "open_rule": "开启条件",
  "my_keys": "我的钥匙",
  "box_not_exist": "宝箱不存在。",
  "open_box_fail": "不满足开启条件。",
  "win_box": "成功领取 ${amount}，奖励已自动发送到您的账户。",
  "share_twitter": "分享到 X/Twitter",
  "share_telegram": "分享到 Telegram",
  "share_facebook": "分享到 Facebook",
  "share_link": "复制链接发送给好友",
  "share_whatsapp": "分享到 WhatsApp",
  "no_data": "暂无数据",
  "username_tip1": "账号格式：4-16 个英文字符或数字",
  "password_tip1": "密码格式：4-16 个字符",
  "confirm_delete": "确定要删除吗？",
  "delete_fail": "删除失败",
  "delete_success": "删除成功",
  "is_close": "确认关闭吗？",
  "deposit_record": "存款记录",
  "withdraw_record": "提现记录",
  "withdraw_tip": "未满足提现条件。您的总投注金额需超过 ${needAmount} 才能进行提现。",
  "withdraw_amount": "可提现金额",
  "input_withdraw_amount": "提现金额需在 ${start} - ${end} 之间。",
  "bet_amount_all": "您当前的总投注金额是 ${betAmountAll}。",
  "play_again": "去继续玩。",
  "login_password": "登录密码。"



};