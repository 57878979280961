import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import SelectPopup, { SelectPopupProp } from "./SelectPopup"
import api from "../../../config/api";
import { Button, ErrorBlock, Image } from "antd-mobile";
import { useGetLocale } from "../../../public/LangProvier";
import { AddOutline } from "antd-mobile-icons";
import { useNavigate } from "react-router";

type WithdrawWaySelectPopupProps = {

    value?: any,
    onChange?: any,



} | SelectPopupProp

const UserAccountSelectPopup: FC<WithdrawWaySelectPopupProps> = forwardRef<any, WithdrawWaySelectPopupProps>((props: SelectPopupProp, ref: any) => {


    // console.log("ref=>", ref);

    const [items, setItems] = useState<any>([]);

    const locale = useGetLocale();

    const navigate = useNavigate();

    const selectRef = useRef<any>(null);



    useEffect(() => {

        api("withdraw/account/list").get({}).then(ret => {
            if (ret.code == 200) {
                setItems([
                    ...ret.data,
                ]);
            }
        })
    }, [])




    return (
        <SelectPopup
            {...props}
            propRef={(propRef: any) => {

                // console.log("propRef=>",propRef);

                ref(propRef);
                selectRef.current = propRef;
            }}
            items={items}
            title={
                <Button size="mini" onClick={() => {
                    selectRef?.current?.close?.();
                    navigate("/user/account/add");
                }}  ><AddOutline /> {locale("add")}</Button>
            }

            renderItem={(item: any, index: number) => {

                return {
                    children: <div>
                        {item.name} - {item.accountNumber}
                    </div>,
                    prefix: <img src={item?.way?.image} style={{ borderRadius: 15 }} width={30} height={30} />,
                    description: (
                        <>{item?.way?.shortName}</>
                    ),
                    arrowIcon: <span></span>,


                }
            }}

            onNoData={() => {

                return (
                    <ErrorBlock
                        status="empty"
                        // title={locale("")}      
                        description={
                            <div style={{padding:10}}>
                                <Button color="primary" block  onClick={()=>{
                                     selectRef?.current?.close?.();
                                     navigate("/user/account/add");
                                }} ><AddOutline /> {locale("add")}</Button>
                            </div>

                        }
                    />
                )
            }}

        >
            {props.children}
        </SelectPopup>
    )

});

export default UserAccountSelectPopup;