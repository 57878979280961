import { FC } from "react"

type CurrencySymbolProps = {
    currency?: string
}

const currencyMap: any =
{
    "IDR(K)": "Rp",
    "INR": "₹",
    "VND(K)": "₫",
    "THB": "฿",
    "MXN": "$",
    "BDT": "৳",
    "PKR": "₨",
    "BRL": "R$",
    "PHP": "₱",
    "USDT":"$"
}

const CurrencySymbol: FC<CurrencySymbolProps> = ({ currency }) => {

    const value = currencyMap[`${currency}`];

    return value?value:"";

}

export const getCurrencySymbol = (currency:any)=>{
    const value = currencyMap[`${currency}`];

    return value?value:"";
}

export default CurrencySymbol;