export default {
    "login.failed": "登錄失敗",
    "login.user_disabled": "用戶被禁用",
    "login.account_error": "用戶名或密碼不正確",
    "login.lock_error": "登錄失敗，帳戶鎖定30分鐘",
    "reg.fail": "註冊失敗",
    "reg.user_name_exists": "用戶名已存在",
    "text.login": "登錄",
    "text.reg": "註冊",
    "text.username": "用戶名",
    "text.password": "密碼",
    "text.password2": "確認密碼",
    "text.passwod2_err": "您輸入的新密碼不匹配！",
    "recent_play": "最近播放",
    "open_game_fail": "打開遊戲失敗",
    "text.ok": "確定",
    "deposit_way": "充值方式",
    "deposit": "充值",
    "amount": "金額",
    "confirm_deposit": "確認充值",
    "input_deposit_amount": "充值金額應在 ${start} - ${end} 之間",
    "home": "首頁",
    "task": "任務",
    "message": "消息",
    "mine": "我的",
    "close": "關閉",
    "refresh": "刷新",
    "logout": "退出登錄",
    "withdraw": "提現",
    "balance": "餘額",
    "deposit_order": "充值訂單",
    "withdraw_order": "提現訂單",
    "game_records": "遊戲記錄",
    "all": "全部",
    "withdraw_account": "提現帳戶",
    "pay_amount": "支付 ${amount}",
    "pay_success": "支付成功",
    "pay_success_info": "您的訂單已成功支付",
    "my_order": "我的訂單",
    "apply_withdraw": "申請提現",
    "confirm_withdraw": "確認提現",
    "real_name": "姓名",
    "input_real_name": "請輸入您的姓名",
    "text.confirm": "確認",
    "text.cancel": "取消",
    "add_withdraw_account": "添加帳戶",
    "pls_select": "請選擇",
    "back": "返回",
    "account_type": "帳戶類型",
    "account_num": "帳戶號碼",
    "pls_input": "請輸入 ${value}",
    "fiat_currency": "法定貨幣",
    "input_amount": "請輸入金額",
    "logout_text": "確定要退出登錄嗎？",
    "tip": "提示",
    "user_center": "用戶中心",
    "close_game": "確認退出？",
    "receive_account": "收款帳戶",
    "insufficient_balance": "餘額不足",
    "apply_success": "申請成功",
    "processing_withdraw_order": "有一個正在支付的提現訂單，請稍後再試。",
    "create_order_fail": "訂單創建失敗，請重試",
    "add_success": "添加成功",
    "delete_success": "刪除成功",
    "add": "添加",
    "pls_input_pwd": "請輸入密碼",
    "password_err": "密碼錯誤",
    "lang_setting": "語言設置",
    "Slot": "老虎機",
    "Fish": "捕魚",
    "Bingo": "賓果",
    "Live": "真人娛樂",
    "Poker": "撲克",
    "ForYou": "推薦",
    "MINI": "小遊戲",
    "ARCADE": "街機",
    "more": "更多"
}