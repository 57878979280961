// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
html,
body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* 设置视口高度 */
  overflow: hidden;
  position: relative;
  background-color: var(--adm-color-background);
}

html {
  scroll-behavior: auto;
}

:root:root {
  --adm-color-primary: #6d2bed ;
}

::-webkit-scrollbar {
  width: 2px;
  background-color: var(--adm-color-weak);
}

::-webkit-scrollbar-track {
  background-color: var(--adm-color-background);
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--adm-color-text);
}

::-webkit-scrollbar-corner {
  color: var(--adm-color-background);
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;;EAGI,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,6CAAA;AAAJ;;AAIA;EACI,qBAAA;AADJ;;AAKA;EAGI,6BAAA;AAJJ;;AAQA;EACI,UAAA;EAEA,uCAAA;AANJ;;AASA;EACI,6CAAA;EACA,kBAAA;AANJ;;AASA;EACI,6BAAA;EACA,kBAAA;AANJ;;AASA;EACI,iCAAA;AANJ;;AASA;EAEI,kCAAA;AAPJ","sourcesContent":["// @import '~antd-mobile/es/global';\nhtml,\nbody,#root {\n\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    height: 100%;\n    /* 设置视口高度 */\n    overflow: hidden;\n    position: relative;\n    background-color: var(--adm-color-background);\n    // -webkit-transform-origin-x: scale(0.5); \n\n}\nhtml{\n    scroll-behavior: auto;\n}\n\n\n:root:root {\n    // --adm-color-primary: #ff9119;\n\n    --adm-color-primary: #6d2bed\n   \n}\n\n::-webkit-scrollbar {\n    width: 2px;\n    // height: 2px;\n    background-color: var(--adm-color-weak);\n}\n\n::-webkit-scrollbar-track {\n    background-color: var(--adm-color-background);\n    border-radius:2px;\n}\n\n::-webkit-scrollbar-thumb {\n    background-color: transparent;\n    border-radius: 2px;\n}\n\n::-webkit-scrollbar-thumb:hover {\n    background: var(--adm-color-text);\n}\n\n::-webkit-scrollbar-corner {\n    // background-color: var(--adm-color-background);\n    color: var(--adm-color-background);\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
