import { win } from "../utils";

type onBackFunc = ()=>void;


const listeners:any[] = [];


//
win.onAndroidBack = ()=>{




    if(listeners.length == 0){
        return;
    }

    

    const listener = listeners[listeners.length - 1];

    listener?.onBack?.();


}


export const addAndroidBackListener = (name:any,onBack:onBackFunc)=>{

    const index =  listeners.findIndex((x:any)=>x.name == name);

    if(index >= 0){
        return;
    }


    listeners.push({name:name,onBack:onBack});

}

export const removeAndroidBackListener = (name:any)=>{

    const index =  listeners.findIndex((x:any)=>x.name == name);

    

    if(index > 0){

        listeners.splice(index,1);
    }

    // alert(JSON.stringify(listeners));



}