import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from 'axios';



type ApiRespData<T> = {


    code:number,
    data:T,
    msg:string

};




type Callback<T> =(callback:T)=>void;

type Error ={
    code:number,
    message:string
}


const cacheData:any = {


};

export default class Api {



    private defaultConfig:Callback<AxiosRequestConfig> = ()=>{};

    private respCallback:Callback<AxiosResponse> = ()=>{};

    private errorCallback:Callback<Error> = ()=>{};

    private optionsConfig:AxiosRequestConfig = {};

    private isCache:Boolean = false;

    private saveLocal?:Boolean;


     
    constructor(public baseUrl:string,public path:string){
       
    }

    cache(cache:Boolean,saveLocal?:Boolean):Api{
        this.isCache = cache;
        this.saveLocal = saveLocal;
        if(!cache){
            cacheData[`${this.path}`] = null; 
            return this;
        }
         const data =  cacheData[`${this.path}`];
         if(!data){
             cacheData[`${this.path}`] = {};
         }

       
         

        return this;
    }

     async getCacheData(params:any,api:()=>Promise<any>):Promise<ApiRespData<any>> {

        const keys = Object.keys(params).length?params:{"key":"0"};
        
        const cacheKey =  Object.keys(keys).map(k=>`${k}=${keys[k]}`).join("&");
        
    
        
        const fromCacheData =  cacheData[`${this.path}`];

        // console.log("cacheKey=>",cacheKey,fromCacheData)

        let cache = fromCacheData[`${cacheKey}`];


        if(cache){
            return {
                code:200,
                data:cache,
                msg:""
            }
        }
        if(!cache && this.saveLocal){
            cache = localStorage.getItem(`cache:${this.path}`);
        }

        if(!cache){

           
            
           const {code,data,msg} =  await api();
           if(code == 200){
                fromCacheData[`${cacheKey}`] = data;
                if(this.saveLocal){
                    localStorage.setItem(`cache:${this.path}`,JSON.stringify(data));
                }
           }
           return {
              code,
              data,
              msg
           }
        }
        
        return new Promise((resolve,reject)=>{
            
            reject("no data");
        })

        
    
        
        
    }

    config(config:Callback<AxiosRequestConfig>):Api{
        this.defaultConfig = config;
        return this;
    }

    onResponse(callabck:Callback<AxiosResponse>):Api{

        this.respCallback = callabck;

        return this;
    }

    onError(error:Callback<Error>):Api{
        this.errorCallback = error;
        return this;
    }
    



    private getRequestUrl():string{

        return `${this.baseUrl}/${this.path}`;
    }

    private axiosRequest(options:AxiosRequestConfig<any>):AxiosPromise<AxiosResponse>{
        
      
        options.url = options.url?options.url:this.getRequestUrl();
        
        this.defaultConfig(options);

        // return new Promise()
        return axios(options).then(ret=>{
            this.respCallback(ret);
            return ret;
        }).catch(err=>{
            this.errorCallback({message:err.message,code:err.response?err.response.status:-1});
            return Promise.reject(err);
            // return err;
        });
    }

    axios(options:AxiosRequestConfig):AxiosPromise<any>{
        options.url = options.url?options.url:this.getRequestUrl();
        this.defaultConfig(options);
        return axios(options)
    }
   
    request(options:AxiosRequestConfig<any>):Promise<ApiRespData<any>>{
      
        return this.axiosRequest(options).then(ret=>{

            const data:any = {
                ...ret.data
            }

            return data;
        });
    }


    /**
     * 下载文件
     * @param params 
     * @param fileName 
     * @returns 
     */
    download(params:any,fileName:string):Promise<any>{
        return this.axios({
            url:this.getRequestUrl(),
            method:"GET",
            params:params
        }).then(ret=>{
            // console.log(ret.headers);
            if(ret.headers["content-type"].indexOf("application/json") != -1){
                return ret.data;
            }
            const blob = new Blob([ret?.data], { type: 'application/octet-stream' })
            const blobURL = window.URL.createObjectURL(blob)
            // 创建a标签，用于跳转至下载链接

            window.open(blobURL,"_blank");
            // const tempLink = document.createElement('a')
            // tempLink.style.display = 'none'
            // tempLink.href = blobURL;
            // tempLink.setAttribute('download', fileName);
            // // 兼容：某些浏览器不支持HTML5的download属性
            // if (typeof tempLink.download === 'undefined') {
            //     tempLink.setAttribute('target', '_blank')
            // }
            // // 挂载a标签
            // document.body.appendChild(tempLink)
            // tempLink.click()
            // document.body.removeChild(tempLink)
            // // 释放blob URL地址
            // window.URL.revokeObjectURL(blobURL);
            return {code:200,msg:"下载成功"}
        })
    }


    get(params:any|undefined):Promise<ApiRespData<any>>{

        //是否缓存
        if(this.isCache){
            return this.getCacheData(params,()=>this.request({params:params,method:"GET"}))
        }
       
        return this.request({params:params,method:"GET"});
    }

    
    post(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"POST"})
    }

    put(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"PUT"})
    }

    delete(params:any|undefined):Promise<ApiRespData<any>>{
        return this.request({data:params,method:"DELETE"})
    }


   

}

