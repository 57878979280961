export default {
    "login.failed": "เข้าสู่ระบบล้มเหลว",
    "login.user_disabled": "ผู้ใช้ถูกปิดใช้งาน",
    "login.account_error": "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
    "login.lock_error": "เข้าสู่ระบบล้มเหลว บัญชีถูกล็อคเป็นเวลา 30 นาที",
    "reg.fail": "การลงทะเบียนล้มเหลว",
    "reg.user_name_exists": "ชื่อผู้ใช้มีอยู่แล้ว",
    "text.login": "เข้าสู่ระบบ",
    "text.reg": "ลงทะเบียน",
    "text.username": "ชื่อผู้ใช้",
    "text.password": "รหัสผ่าน",
    "text.password2": "ยืนยันรหัสผ่าน",
    "text.passwod2_err": "รหัสผ่านใหม่ที่คุณป้อนไม่ตรงกัน!",
    "recent_play": "เล่นล่าสุด",
    "open_game_fail": "ไม่สามารถเปิดเกมได้",
    "text.ok": "ตกลง",
    "deposit_way": "วิธีฝากเงิน",
    "deposit": "ฝากเงิน",
    "amount": "จำนวนเงิน",
    "confirm_deposit": "ยืนยันการฝากเงิน",
    "input_deposit_amount": "จำนวนเงินฝากต้องอยู่ระหว่าง ${start} - ${end}",
    "home": "หน้าแรก",
    "task": "งาน",
    "message": "ข้อความ",
    "mine": "ของฉัน",
    "close": "ปิด",
    "refresh": "รีเฟรช",
    "logout": "ออกจากระบบ",
    "withdraw": "ถอนเงิน",
    "balance": "ยอดเงินคงเหลือ",
    "deposit_order": "คำสั่งฝากเงิน",
    "withdraw_order": "คำสั่งถอนเงิน",
    "game_records": "บันทึกการเล่นเกม",
    "all": "ทั้งหมด",
    "withdraw_account": "บัญชีถอนเงิน",
    "pay_amount": "ชำระเงิน ${amount}",
    "pay_success": "ชำระเงินสำเร็จ",
    "pay_success_info": "คำสั่งของคุณได้รับการชำระเงินเรียบร้อยแล้ว",
    "my_order": "คำสั่งของฉัน",
    "apply_withdraw": "การขอถอนเงิน",
    "confirm_withdraw": "ยืนยันการถอนเงิน",
    "real_name": "ชื่อจริง",
    "input_real_name": "กรุณากรอกชื่อของคุณ",
    "text.confirm": "ยืนยัน",
    "text.cancel": "ยกเลิก",
    "add_withdraw_account": "เพิ่มบัญชี",
    "pls_select": "กรุณาเลือก",
    "back": "กลับ",
    "account_type": "ประเภทบัญชี",
    "account_num": "หมายเลขบัญชี",
    "pls_input": "กรุณากรอก ${value}",
    "fiat_currency": "สกุลเงินท้องถิ่น",
    "input_amount": "กรุณากรอกจำนวนเงิน",
    "logout_text": "คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ?",
    "tip": "คำแนะนำ",
    "user_center": "ศูนย์ผู้ใช้",
    "close_game": "ยืนยันการออก?",
    "receive_account": "บัญชีผู้รับ",
    "insufficient_balance": "ยอดเงินไม่เพียงพอ",
    "apply_success": "สมัครสำเร็จ",
    "processing_withdraw_order": "มีคำสั่งถอนเงินที่กำลังดำเนินการอยู่ โปรดลองใหม่อีกครั้งภายหลัง",
    "create_order_fail": "การสร้างคำสั่งล้มเหลว โปรดลองใหม่อีกครั้ง",
    "add_success": "เพิ่มสำเร็จ",
    "delete_success": "ลบสำเร็จ",
    "add": "เพิ่ม",
    "pls_input_pwd": "กรุณากรอกรหัสผ่าน",
    "password_err": "รหัสผ่านไม่ถูกต้อง",
    "lang_setting": "การตั้งค่าภาษา",
    "Slot": "สล็อต",
    "Fish": "ตกปลา",
    "Bingo": "บิงโก",
    "Live": "ถ่ายทอดสด",
    "Poker": "โป๊กเกอร์",
    "ForYou": "สำหรับคุณ",
    "MINI": "มินิ",
    "ARCADE": "อาเขต",
    "more": "เพิ่มเติม",
    "copy_success": "คัดลอกสำเร็จ",
    "wait_pay": "รอการชำระเงิน",
    "paid": "ชำระเงินแล้ว",
    "cancelled": "ยกเลิกแล้ว",
    "unpaid": "ยังไม่ได้ชำระ",
    "open": "เปิด",
    "my_account": "บัญชีของฉัน",
    "processing": "กำลังดำเนินการ",
    "fail": "ล้มเหลว",
    "success": "สำเร็จ",
    "my_boxs": "กล่องของฉัน",
    "game_rules": "กฎของเกม",
    "Me": "ฉัน",
    "download_app_text": "เราต้องการเพิ่มลงในหน้าจอหลักของคุณเพื่อการอัปเดตการเดิมพันฟรีและโบนัสล่าสุด",
    "click_install": "คลิกติดตั้ง",
    "Home": "หน้าแรก",
    "i_know": "ฉันรู้แล้ว",
    "click_me": "คลิกที่ฉันเพื่อติดตั้ง",
    "Mine": "ของฉัน",
    "lottery": "ลอตเตอรี่",
    "no_account": "ยังไม่มีบัญชี?",
    "other_login_way": "วิธีอื่น",
    "login.access_denied": "การเข้าถึงถูกปฏิเสธ",
    "services": "บริการ",
    "Promotions": "โปรโมชั่น",
    "lottery_ending": "กิจกรรมลอตเตอรี่นี้สิ้นสุดลงแล้ว",
    "lottery_not_start": "กิจกรรมลอตเตอรี่ยังไม่เริ่ม",
    "no_more_draw_count": "ไม่มีการจับรางวัลเพิ่มเติม",
    "pls_login": "ยังไม่ได้เข้าสู่ระบบ กรุณาเข้าสู่ระบบก่อน",
    "MyBox": "กล่องของขวัญของฉัน",
    "SHARE": "แชร์",
    "my_draw_count": "จำนวนการจับสลากที่มีอยู่",
    "lottery_not_match": "ไม่ตรงกับกฎของกิจกรรมนี้",
    "not_win": "พยายามต่อไป",
    "open_rule": "เงื่อนไขการเปิด",
    "my_keys": "กุญแจของฉัน",
    "box_not_exist": "กล่องสมบัติไม่มีอยู่",
    "open_box_fail": "ไม่เป็นไปตามเงื่อนไขการเปิด",
    "win_box": "ได้รับ ${amount} สำเร็จ รางวัลได้ถูกส่งไปยังบัญชีของคุณแล้ว",
    "share_twitter": "แชร์บน X/Twitter",
    "share_telegram": "แชร์บน Telegram",
    "share_facebook": "แชร์บน Facebook",
    "share_link": "คัดลอกลิงก์และส่งให้เพื่อน",
    "share_whatsapp": "แชร์บน WhatsApp",
    "no_data": "ไม่มีข้อมูล",
    "username_tip1": "รูปแบบบัญชี: ตัวอักษรภาษาอังกฤษหรือตัวเลข 4-16 ตัว",
    "password_tip1": "รูปแบบรหัสผ่าน: 4-16 ตัวอักษร",
    "confirm_delete": "คุณแน่ใจหรือว่าต้องการลบ?",
    "delete_fail": "การลบล้มเหลว",
    "delete_success": "ลบสำเร็จ",
    "is_close": "ยืนยันการปิด?",
    "deposit_record": "บันทึกการฝาก",
    "withdraw_record": "บันทึกการถอน",
    "withdraw_tip": "ไม่เป็นไปตามเงื่อนไขการถอนเงิน ยอดเดิมพันรวมของคุณต้องเกิน ${needAmount} จึงจะสามารถถอนเงินได้",
    "withdraw_amount": "จำนวนเงินที่ถอนได้",
    "input_withdraw_amount": "จำนวนเงินที่ถอนได้ต้องอยู่ระหว่าง ${start} - ${end}",
    "bet_amount_all": "ยอดเดิมพันรวมของคุณในปัจจุบันคือ ${betAmountAll}",
    "play_again": "ไปและเล่นต่อ",
    "login_password": "รหัสผ่านเข้าสู่ระบบ"

}