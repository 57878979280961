import { Modal, Toast } from "antd-mobile";
import Api from "../public/axios";
import config from "./index";
import { getRealClientType, getUserLanguage } from "../utils";
import { getLocale } from "../public/LangProvier";


export const baseApiUrl = config.baseApi;

export const sessionName = "X-GAME-SID";

const Message = {
    show: false
}

export const getSessionId = () => {

    return localStorage.getItem(`${sessionName}`);
}


export default (path: string) => {

    const sessionId = getSessionId();

    const login = window.localStorage.getItem("login");
    const site = localStorage.getItem("site-config");
    const language = getUserLanguage();

    const loginData: any = login ? JSON.parse(login) : {};
    const siteConfig:any = site?JSON.parse(site):{};

    const deviceId = localStorage.getItem("client-id");


    return new Api(baseApiUrl, path)
        .config(options => {

            options.withCredentials = true;
            const headers: any = {
                "X-BRAND-ID": siteConfig?.key,
                "X-GAME-TOKEN": loginData?.user?.token,
                "X-GAME-LANG":language,
                "X-CLIENT-TYPE":getRealClientType(),
                "X-DEVICE-ID":deviceId
                
            };
            headers[`${sessionName}`] = sessionId ? sessionId : "";
            options.headers = {
                ...options.headers,
                ...headers
            };
        })
        .onResponse(resp => {

            const sid = resp.headers[sessionName.toLowerCase()];

            // console.log("session-name",resp);
            // 重新设置session id
            if (sid && sid != sessionId) {
                localStorage.setItem(`${sessionName}`, sid);
            }
            //是否返回json数据
            if (resp.headers["content-type"] && resp.headers["content-type"].indexOf("application/json") == -1) {
                return;
            }
            const data: any = resp.data;

            if (data?.code == 400 || data?.code == 500) {
                if (Message.show) {
                    return;
                }


                Message.show = true;
                Toast.show({
                    content: getLocale(data?.msg),
                    afterClose() {
                        Message.show = false;
                    },

                });
            }

            // if (data?.code == 400 || data?.code == 500 || data?.code == 403 || data?.code == 401) {

            //     if (Message.show) {
            //         return;
            //     }

            //     Message.show = true;
            //     message.error(data?.msg, 1.2, () => {
            //         Message.show = false;
            //     });

            //     //登录失效
            //     if (data?.code == 401) {
            //         window.location.href = "/login";
            //         return;
            //     }
            // }


        })

        .onError((err) => {
            // message.error(err.message);

            // Toast.show(err.message);

            // return err.message;

            

        })
        ;

};



