import { useEffect } from "react"
import { AndroidClient } from "../../../utils";
import { useGetSiteConfig } from "../../../public/SiteConfigProvier";

export default ()=>{


    const config =  useGetSiteConfig();


    useEffect(()=>{

        

    },[]);

    return (
        <div>

            <div style={{marginBottom:20}}>
                <a href={config?.iosAppUrl} target="_blank" download >下载IOS-{config?.iosAppUrl}</a>
            </div>

            <div>
                <a href={config?.androidAppUrl} target="_blank" download>下载Android</a>
            </div>

        </div>
    )
}