export default {
    "login.failed": "Gagal masuk",
    "login.user_disabled": "Pengguna dinonaktifkan",
    "login.account_error": "Nama pengguna atau kata sandi salah",
    "login.lock_error": "Gagal masuk, akun dikunci selama 30 menit",
    "reg.fail": "Pendaftaran gagal",
    "reg.user_name_exists": "Nama pengguna sudah ada",
    "text.login": "Masuk",
    "text.reg": "Daftar",
    "text.username": "Nama pengguna",
    "text.password": "Kata sandi",
    "text.password2": "Konfirmasi kata sandi",
    "text.passwod2_err": "Kata sandi baru yang Anda masukkan tidak cocok!",
    "recent_play": "Permainan terbaru",
    "open_game_fail": "Gagal membuka permainan",
    "text.ok": "OK",
    "deposit_way": "Cara deposit",
    "deposit": "Deposit",
    "amount": "Jumlah",
    "confirm_deposit": "Konfirmasi deposit",
    "input_deposit_amount": "Jumlah deposit harus antara ${start} - ${end}",
    "home": "Beranda",
    "task": "Tugas",
    "message": "Pesan",
    "mine": "Milikku",
    "close": "Tutup",
    "refresh": "Segarkan",
    "logout": "Keluar",
    "withdraw": "Penarikan",
    "balance": "Saldo",
    "deposit_order": "Pesanan deposit",
    "withdraw_order": "Pesanan penarikan",
    "game_records": "Catatan permainan",
    "all": "Semua",
    "withdraw_account": "Akun penarikan",
    "pay_amount": "Bayar ${amount}",
    "pay_success": "Pembayaran berhasil",
    "pay_success_info": "Pesanan Anda telah berhasil dibayar",
    "my_order": "Pesanan saya",
    "apply_withdraw": "Aplikasi penarikan",
    "confirm_withdraw": "Konfirmasi penarikan",
    "real_name": "Nama",
    "input_real_name": "Silakan masukkan nama Anda",
    "text.confirm": "Konfirmasi",
    "text.cancel": "Batal",
    "add_withdraw_account": "Tambah akun",
    "pls_select": "Silakan pilih",
    "back": "Kembali",
    "account_type": "Jenis akun",
    "account_num": "Nomor akun",
    "pls_input": "Silakan masukkan ${value}",
    "fiat_currency": "Mata uang fiat",
    "input_amount": "Silakan masukkan jumlah",
    "logout_text": "Apakah Anda yakin ingin keluar?",
    "tip": "Tip",
    "user_center": "Pusat pengguna",
    "close_game": "Konfirmasi keluar?",
    "receive_account": "Akun penerima",
    "insufficient_balance": "Saldo tidak mencukupi",
    "apply_success": "Aplikasi berhasil",
    "processing_withdraw_order": "Ada pesanan penarikan yang sedang diproses, silakan coba lagi nanti.",
    "create_order_fail": "Gagal membuat pesanan, silakan coba lagi",
    "add_success": "Berhasil ditambahkan",
    "delete_success": "Berhasil dihapus",
    "add": "Tambah",
    "pls_input_pwd": "Silakan masukkan kata sandi",
    "password_err": "Kata sandi salah",
    "lang_setting": "Pengaturan bahasa",
    "Slot": "Slot",
    "Fish": "Ikan",
    "Bingo": "Bingo",
    "Live": "Langsung",
    "Poker": "Poker",
    "ForYou": "Untukmu",
    "MINI": "Mini",
    "ARCADE": "Arkade",
    "more":"Lebih",
    "copy_success": "Salin Berhasil",
    "wait_pay": "Menunggu Pembayaran",
    "paid": "Sudah Dibayar",
    "cancelled": "Dibatalkan",
    "unpaid": "Belum Dibayar",
    "open": "Buka",
    "my_account": "Akun Saya",
    "processing": "Sedang Diproses",
    "fail": "Gagal",
    "success": "Berhasil",
    "my_boxs": "Kotak Saya",
    "game_rules": "ATURAN PERMAINAN",
    "Me": "Saya",
    "download_app_text": "Kami ingin menambahkannya ke layar beranda Anda untuk mendapatkan pembaruan taruhan gratis dan bonus terbaru.",
    "click_install": "Klik Install",
    "Home": "Beranda",
    "i_know": "Saya Mengerti",
    "click_me": "Klik saya untuk menginstal",
    "Mine": "Saya",
    "lottery": "Undian Berhadiah",
    "no_account": "Belum punya akun?",
    "other_login_way": "Cara lain",
    "login.access_denied": "Akses ditolak",
    "services": "Layanan",
    "Promotions": "Promosi",
    "lottery_ending": "Acara undian ini telah berakhir.",
    "lottery_not_start": "Acara undian belum dimulai.",
    "no_more_draw_count": "Tidak ada kesempatan undian lagi.",
    "pls_login": "Belum masuk. Silakan masuk terlebih dahulu.",
    "MyBox": "Kotak Hadiah Saya",
    "SHARE": "Bagikan",
    "my_draw_count": "Kesempatan Undian Tersedia",
    "lottery_not_match": "Tidak memenuhi aturan acara ini.",
    "not_win": "Tetap berusaha",
    "open_rule": "Syarat Pembukaan",
    "my_keys": "Kunci saya",
    "box_not_exist": "Kotak harta tidak ada.",
    "open_box_fail": "Tidak memenuhi syarat pembukaan.",
    "win_box": "Berhasil menerima ${amount}. Hadiah telah otomatis dikirim ke akun Anda.",
    "share_twitter": "Bagikan di X/Twitter",
    "share_telegram": "Bagikan di Telegram",
    "share_facebook": "Bagikan di Facebook",
    "share_link": "Salin tautan dan kirim ke teman",
    "share_whatsapp": "Bagikan di WhatsApp",
    "no_data": "Tidak ada data",
    "username_tip1": "Format akun: 4-16 karakter huruf atau angka",
    "password_tip1": "Format kata sandi: 4-16 karakter",
    "confirm_delete": "Apakah Anda yakin ingin menghapus?",
    "delete_fail": "Penghapusan Gagal",
    "delete_success": "Penghapusan Berhasil",
    "is_close": "Konfirmasi Tutup?",
    "deposit_record": "Catatan Deposit",
    "withdraw_record": "Catatan Penarikan",
    "withdraw_tip": "Syarat penarikan belum terpenuhi. Total jumlah taruhan Anda harus melebihi ${needAmount} untuk melanjutkan penarikan.",
    "withdraw_amount": "Jumlah yang dapat ditarik",
    "input_withdraw_amount": "Jumlah penarikan harus berada di antara ${start} - ${end}.",
    "bet_amount_all": "Jumlah total taruhan Anda saat ini adalah ${betAmountAll}.",
    "play_again": "Lanjutkan bermain.",
    "login_password": "Kata sandi masuk."
}