// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-profile .balance {
  font-size: 30px;
  color: var(--adm-color-text);
  font-weight: 300;
  margin-bottom: 15px;
  text-align: center;
}
.user-profile .adm-list-body {
  border: 1px solid var(--adm-color-border);
  border-radius: 5px;
}
.user-profile .logout {
  height: 40px;
  line-height: 40px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/user/user-profile.scss"],"names":[],"mappings":"AAII;EACI,eAAA;EACA,4BAAA;EACA,gBAAA;EACA,mBAAA;EACA,kBAAA;AAHR;AAMI;EACI,yCAAA;EACA,kBAAA;AAJR;AAMI;EAEI,YAAA;EACA,iBAAA;EACA,kBAAA;AALR","sourcesContent":[".user-profile {\n\n    // padding: 12px;\n\n    .balance {\n        font-size: 30px;\n        color: var(--adm-color-text);\n        font-weight: 300;\n        margin-bottom: 15px;\n        text-align: center;\n    }\n\n    .adm-list-body{\n        border: 1px solid var(--adm-color-border);\n        border-radius: 5px;\n    }\n    .logout{\n        // margin-top: 20px;\n        height: 40px;\n        line-height: 40px;\n        text-align: center;\n        // border: 1px solid var(--adm-color-border);\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
