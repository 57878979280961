import { useNavigate } from "react-router";
import IconFont from "../public/iconfont/Index";
import { useGetLang, useGetLocale } from "../public/LangProvier";
import { useGetSiteConfig } from "../public/SiteConfigProvier";
import { getClientType, isPcClient } from "../utils";
import "./MenuLayout.scss";
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import { useEffect, useState } from "react";
import { Popover } from "antd-mobile";


const IconMenuItem = ({ item, index, currentIndex, onClick }: any) => {

    const [visable, setVisable] = useState(false);



    return (
        <Popover
            content={item.title}
            key={index}
            visible={visable}
            mode="dark"
            placement="right"
            defaultVisible
            destroyOnHide

        >
            <div
                className={`icon-item ${currentIndex == index ? "icon-item-active" : ""}`}
                onClick={onClick}
                onMouseEnter={e => {
                    setVisable(true);
                }}

                onMouseLeave={e => {
                    setVisable(false);
                }}

            >

                <IconFont className="icon" name={item.icon} />

            </div>
        </Popover>
    )
}


export default (props: any) => {

    const isPC = isPcClient();

    const config = useGetSiteConfig();

    const collapsed = props?.collapsed;

    const locale = useGetLocale();

    const navigate = useNavigate();

    const [items, setItems] = useState<any[]>([]);

    const lang = useGetLang();

    const [currentIndex, setCurrentIndex] = useState(0);







    useEffect(() => {

        if (!isPC) {
            return;
        }

        const menus = [
            { title: locale("Home"), icon: "icon-hotfill", onClick: () => navigate(`/`, { state: { category: "Home" } }) },
            { title: locale("Slot"), icon: "icon-slot", onClick: () => navigate(`/`, { state: { category: "SLOTS" } }) },
            { title: locale("Fish"), icon: "icon-Fish", onClick: () => navigate(`/`, { state: { category: "FISH" } }) },
            { title: locale("Bingo"), icon: "icon-bingo", onClick: () => navigate(`/`, { state: { category: "BINGO" } }) },
            { title: locale("Live"), icon: "icon-livetv", onClick: () => navigate(`/`, { state: { category: "LIVE" } }) },
            { title: locale("Poker"), icon: "icon-poker", onClick: () => navigate(`/`, { state: { category: "POKER" } }) },
            { title: locale("Promotions"), icon: "icon-gift", onClick: () => navigate(`/notice/list`) },
            { title: locale("services"), icon: "icon-customer-service", onClick: () => navigate("/customer/service") },
        ];

        setItems([
            ...menus
        ])


    }, [lang]);


    const current = items[currentIndex];


    if (!isPC) {

    }




    return (
        <>




            {isPC ? (<div className={`main main-collapsed-${collapsed}`}>
                <motion.div className={`pc-menu`}

                    animate={
                        collapsed ? {
                            width: 60
                        } : {
                            width: 250
                        }
                    }

                    initial={
                        { width: 250 }
                    }

                    transition={{ duration: 0.1 }}

                >

                    {collapsed ? (
                        <>
                            <div className="layout-icons">

                                {items.map((item, index) => {

                                    return (
                                        <IconMenuItem
                                            key={index}
                                            index={index}
                                            currentIndex={currentIndex}
                                            item={item}
                                            onClick={() => {
                                                item.onClick?.(item);
                                            }}
                                        />
                                    )
                                })}

                            </div>
                        </>
                    ) : (
                        <div className="layout">

                            {items.map((item, index) => {
                                return (
                                    <div key={index} className="item" onClick={item.onClick}>

                                        <IconFont className="icon" name={item.icon} />
                                        <div className="text">
                                            {item.title}
                                        </div>
                                    </div>

                                )
                            })}

                        </div>
                    )}

                </motion.div>
                {props.children}
            </div>) : props.children}




        </>
    )


}