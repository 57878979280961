import "./iconfont.css";
import { FC, HtmlHTMLAttributes } from "react";



interface IconProps extends HtmlHTMLAttributes<any>{

    name: string,

}


const IconFont: FC<IconProps> = (props) => {

    return (
        <span 
            {...props}
            className={`iconfont ${props.name} ${props.className}`}
        >

        </span>
    )

}

export default IconFont;