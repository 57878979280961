import { FC, useEffect, useState } from 'react';
import {
    AppOutline,
    MessageOutline,
    UnlockOutline,
    UserOutline,
    AddCircleOutline,
    AppstoreOutline,
    FileOutline,
    BankcardOutline,
    GiftOutline

} from 'antd-mobile-icons'
import { Button, NavBar, SafeArea, Space, TabBar, Image, Footer, Popover, Modal } from 'antd-mobile';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useGetSiteConfig } from '../public/SiteConfigProvier';
import { Balance, useGetUserBalance, useRefreshUserBalance } from '../public/UserBalanceProvier';
import { useGetLogin, useSetLogin } from '../public/LoginProvier';
import { useGetLocale } from '../public/LangProvier';
import LoginOrRegisterModal from '../pages/h5/public/LoginOrRegisterModal';
import { AndroidClient, formatAmount, getClientType, isPcClient } from '../utils';
import { useSetLoginRegisterOpen } from '../public/LoginRegisterOpenProvier';
import CurrencySymbol from '../public/CurrencySymbol';
import dayjs from 'dayjs';
import modal from "../public/Modal";
import api from '../config/api';
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import './h5-tabbar-layout.scss';
import MenuLayout from './MenuLayout';
import IconFont from '../public/iconfont/Index';
import Home from '../pages/h5/home';
import Deposit from "../pages/h5/deposit/Index";
import UserProfile from '../pages/h5/user/UserProfile';
import { addAndroidBackListener, removeAndroidBackListener } from '../public/OnAndroidBack';
import CustomerService from '../pages/h5/services/CustomerService';
import NoticeIndex from "../pages/h5/notice/Index";

function setViewportHeight() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

// 页面加载时设置高度
setViewportHeight();

// 监听窗口变化时重新设置高度
window.addEventListener('resize', setViewportHeight);


const Bottom: FC<any> = ({ tabbar, onChange }: any) => {

    const location = useLocation();
    const { pathname } = location;
    const navigate = useNavigate();
    const { login } = useGetLogin();

    const locale = useGetLocale();




    const setRouteActive = (value: string) => {

        if (!login) {
            const auths = ["me", "deposit", "lottery"];

            if (auths.includes(value)) {
                navigate("/login");
                return;
            }
        }

        if (value == "deposit") {
            navigate("/deposit")
            return;
        }

        if (value == "lottery") {
            navigate("/activity/lottery?id=5")
            return;
        }


        onChange?.(value);

        sessionStorage.setItem("tabbar", value);


    }

    const tabs = [
        {
            key: 'home',
            title: locale("home"),
            icon: <AppstoreOutline />,
        },
        {
            key: 'promotions',
            title: locale("Promotions"),
            icon: <GiftOutline />,
        },
        {
            key: 'deposit',
            title: locale("deposit"),
            icon: <AddCircleOutline />,
        },
        {
            key: 'services',
            title: locale("services"),
            icon: <IconFont name='icon-customer-service' style={{ fontSize: 24 }} />,

        },
        {
            key: 'me',
            title: locale("Mine"),
            icon: <UserOutline />,
        },
    ]

    return (
        <TabBar
            className='app-tabbar'
            safeArea={true}
            activeKey={tabbar}
            onChange={value => setRouteActive(value)}
            style={{ paddingBottom: isIosNotchDevice() ? "20px" : 0 }}
        >
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    )
}

const isIosNotchDevice = () => {
    // 检测是否是 iOS 设备
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);



    if (isIOS) {

        if (getClientType() == "TELEGRAM_MIN_APP") {
            return true;
        }

        // 判断安全区域（刘海屏）
        const { innerHeight, screen } = window;
        const ratio = innerHeight / screen.width;

        // iPhone X 及以上刘海屏设备的屏幕高宽比通常在 2.0 以上
        return ratio > 2.0;
    }
    return false;
};





// const tabbars: any = {
//     "home": <Home />,
//     "me": <UserProfile />,
//     "deposit": <Deposit />
// }

const TabarContent = ({ name }: any) => {




    return (
        <>
            {name == "home" ? <Home /> : null}
            {name == "me" ? <UserProfile /> : null}
            {name == "deposit" ? <Deposit /> : null}
            {name == "services" ? <CustomerService /> : null}
            {name == "promotions" ? <NoticeIndex /> : null}
        </>



    )

}

const Header = ({ collapsed, onCollapsedChange, tabbar }: any) => {

    const config = useGetSiteConfig();

    const balance = useGetUserBalance();

    const { login, user } = useGetLogin();

    const setLogin = useSetLogin();

    const locale = useGetLocale()

    const navigate = useNavigate();

    const isPc = isPcClient();

    /**
     * 退出登陆
     */
    const logout = () => {

        modal.confirm({
            title: locale("tip"),
            content: locale("logout_text"),
            okText: locale("text.ok"),
            cancelText: locale("text.cancel"),
            onOk: async () => {

                await api("login/logout").post({});

                setLogin({ login: false, user: null });

                navigate("/", { replace: true });


            }
        })
    }

    if (!isPc && ["me", "message"].includes(tabbar)) {

        return null;

    }


    return (
        <div className={"top"}>
            <NavBar
                backIcon={null}

                back={
                    isPc ? (
                        <div className='top-pc'>
                            <div className='top-icon' onClick={() => {
                                onCollapsedChange(!collapsed);
                            }}>
                                {collapsed ? <IconFont name="icon-menu_right" /> : <IconFont name="icon-menu-left" />}
                            </div>
                            <div className={`top-back top-back-pc`}>
                                {config ? (
                                    <img style={{ width: 60 }} src={`${config.logo}`} />
                                ) : null}

                            </div>
                        </div>
                    ) : (
                        <div className='top-back'>
                            {config ? (
                                <img style={{ width: 80 }} src={`${config.logo}`} />
                            ) : null}

                        </div>
                    )
                }
                right={<div style={{ fontSize: 14 }} className='top-nav-right'>

                    {login ? (
                        <>
                            {isPc ? (


                                <Popover.Menu

                                    className='popover-menu'
                                    mode="dark"
                                    destroyOnHide

                                    actions={[
                                        { key: "deposit", icon: <AddCircleOutline />, text: locale("deposit") },
                                        { key: "withdraw", icon: <BankcardOutline />, text: locale("withdraw") },
                                        { key: "me", icon: <UserOutline />, text: locale("user_center") },
                                        { key: "logout", icon: <UnlockOutline />, text: locale("logout") },
                                    ]}
                                    placement="bottomRight"
                                    trigger={"click"}

                                    onAction={(item) => {

                                        if (item.key == "deposit") {
                                            navigate("/deposit", { replace: true })
                                            return;
                                        }
                                        if (item.key == "withdraw") {
                                            navigate("/withdraw")
                                            return;
                                        }
                                        if (item.key == "me") {
                                            navigate("/me", { replace: true })
                                            return;
                                        }
                                        if (item.key == "logout") {
                                            logout();
                                        }
                                    }}


                                >

                                    <div style={{ height: 45, display: "flex", alignItems: "center", justifyContent: "flex-end" }}>

                                        <span>{formatAmount(balance.currency, balance.balance)}</span>
                                        <UserOutline style={{ fontSize: 20, marginLeft: 5 }} />

                                    </div>
                                </Popover.Menu>
                            ) : (
                                <Space align="center">
                                    <span>{formatAmount(balance.currency, balance.balance)}</span>
                                    <Button color="primary" size="mini" onClick={() => navigate("/deposit")}>{locale("deposit")}</Button>
                                </Space>
                            )}
                        </>
                    ) : (<>

                        <Space>

                            <Button size="mini" color="default" onClick={() => {
                                // // setVisableData({ type: "Login" })
                                // setLoginRegisterOpen({
                                //     open: true,
                                //     type: "Login"
                                // })

                                navigate("/login");






                            }} >{locale("text.login")}</Button>
                            <Button size="mini" color="primary" onClick={() => {
                                // setVisableData({ type: "Register" })
                                // setLoginRegisterOpen({
                                //     open: true,
                                //     type: "Register"
                                // })
                                navigate("/register")
                            }} >{locale("text.reg")}</Button>
                        </Space>

                    </>)}
                </div>}
            ></NavBar>
        </div>
    )
}

export default (props: any) => {



    const { login, user } = useGetLogin();

    const setLogin = useSetLogin();

    const locale = useGetLocale()

    const navigate = useNavigate();

    const clientType = getClientType();

    const refreshUserBalance = useRefreshUserBalance();

    const location = useLocation();

    const [collapsed, setCollapsed] = useState(true);


    const initTabbar = sessionStorage.getItem("tabbar");


    const [tabbar, setTabbar] = useState(initTabbar ? initTabbar : "home");




    useEffect(() => {



        if (login) {
            refreshUserBalance();
        }

        addAndroidBackListener("home", () => {

            AndroidClient?.exit?.();
        });


        return () => {

            removeAndroidBackListener("home")
        }


    }, [login]);



    const bottomHeight = isIosNotchDevice() ? 70 : 50;



    return (



        <MenuLayout collapsed={collapsed}>

            <div className={`app app-${clientType} app-${clientType?.toLowerCase()}-body-min-${bottomHeight}`}>


                <Header collapsed={collapsed} onCollapsedChange={setCollapsed} tabbar={tabbar} />

                <div className={`body bottom-height-${bottomHeight}`}>
                    <div className='body-main'>
                        <TabarContent name={clientType == "PC" ? "home" : tabbar} />
                    </div>

                    <Footer
                    // content={`@ ${dayjs().format("YYYY")} ${window.location.hostname} All rights reserved`}
                    ></Footer>
                    <div className='body-bottom-height'></div>
                </div>
                {clientType != "PC" ? (
                    <>
                        <div className={"bottom"}>
                            <Bottom tabbar={tabbar} onChange={setTabbar} />
                        </div>
                    </>
                ) : null}

            </div>
        </MenuLayout>


    )

}