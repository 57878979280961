
export default
    {
        "login.failed": "লগইন ব্যর্থ হয়েছে",
        "login.user_disabled": "ব্যবহারকারী নিষ্ক্রিয় করা হয়েছে",
        "login.account_error": "ব্যবহারকারীর নাম বা পাসওয়ার্ড সঠিক নয়",
        "login.lock_error": "লগইন ব্যর্থ হয়েছে, অ্যাকাউন্ট ৩০ মিনিটের জন্য লক করা হয়েছে",
        "reg.fail": "নিবন্ধন ব্যর্থ হয়েছে",
        "reg.user_name_exists": "ব্যবহারকারীর নাম ইতিমধ্যেই বিদ্যমান",
        "text.login": "লগইন",
        "text.reg": "নিবন্ধন",
        "text.username": "ব্যবহারকারীর নাম",
        "text.password": "পাসওয়ার্ড",
        "text.password2": "পাসওয়ার্ড নিশ্চিত করুন",
        "text.passwod2_err": "আপনার প্রবেশ করা নতুন পাসওয়ার্ড মিলছে না!",
        "recent_play": "সম্প্রতি খেলা",
        "open_game_fail": "গেম খুলতে ব্যর্থ হয়েছে",
        "text.ok": "ঠিক আছে",
        "deposit_way": "আমানত পদ্ধতি",
        "deposit": "আমানত",
        "amount": "পরিমাণ",
        "confirm_deposit": "আমানত নিশ্চিত করুন",
        "input_deposit_amount": "আমানতের পরিমাণ ${start} - ${end} এর মধ্যে হওয়া উচিত",
        "home": "হোম",
        "task": "কাজ",
        "message": "বার্তা",
        "mine": "আমার",
        "close": "বন্ধ করুন",
        "refresh": "রিফ্রেশ",
        "logout": "লগ আউট",
        "withdraw": "উত্তোলন",
        "balance": "ব্যালেন্স",
        "deposit_order": "আমানতের আদেশ",
        "withdraw_order": "উত্তোলনের আদেশ",
        "game_records": "গেম রেকর্ড",
        "all": "সব",
        "withdraw_account": "উত্তোলন অ্যাকাউন্ট",
        "pay_amount": "${amount} প্রদান করুন",
        "pay_success": "প্রদান সফল হয়েছে",
        "pay_success_info": "আপনার অর্ডার সফলভাবে পরিশোধ করা হয়েছে",
        "my_order": "আমার আদেশ",
        "apply_withdraw": "উত্তোলনের আবেদন",
        "confirm_withdraw": "উত্তোলনের নিশ্চয়তা",
        "real_name": "নাম",
        "input_real_name": "অনুগ্রহ করে আপনার নাম লিখুন",
        "text.confirm": "নিশ্চিত করুন",
        "text.cancel": "বাতিল",
        "add_withdraw_account": "অ্যাকাউন্ট যোগ করুন",
        "pls_select": "অনুগ্রহ করে নির্বাচন করুন",
        "back": "ফিরে যান",
        "account_type": "অ্যাকাউন্টের ধরণ",
        "account_num": "অ্যাকাউন্ট নম্বর",
        "pls_input": "অনুগ্রহ করে ${value} লিখুন",
        "fiat_currency": "ফিয়াট মুদ্রা",
        "input_amount": "অনুগ্রহ করে পরিমাণ লিখুন",
        "logout_text": "আপনি কি লগ আউট করতে চান?",
        "tip": "টিপস",
        "user_center": "ব্যবহারকারী কেন্দ্র",
        "close_game": "প্রস্থান নিশ্চিত করবেন?",
        "receive_account": "প্রাপ্তির অ্যাকাউন্ট",
        "insufficient_balance": "অপর্যাপ্ত ব্যালেন্স",
        "apply_success": "আবেদন সফল",
        "processing_withdraw_order": "একটি উত্তোলনের আদেশ প্রক্রিয়াধীন রয়েছে, অনুগ্রহ করে পরে আবার চেষ্টা করুন।",
        "create_order_fail": "অর্ডার তৈরি ব্যর্থ হয়েছে, অনুগ্রহ করে আবার চেষ্টা করুন",
        "add_success": "সফলভাবে যোগ করা হয়েছে",
        "delete_success": "সফলভাবে মুছে ফেলা হয়েছে",
        "add": "যোগ করুন",
        "pls_input_pwd": "অনুগ্রহ করে পাসওয়ার্ড লিখুন",
        "password_err": "ভুল পাসওয়ার্ড",
        "lang_setting": "ভাষার সেটিংস",
        "Slot": "স্লট",
        "Fish": "মাছ",
        "Bingo": "বিঙ্গো",
        "Live": "লাইভ",
        "Poker": "পোকার",
        "ForYou": "প্রস্তাবিত",
        "MINI": "মিনি",
        "ARCADE": "আর্কেড",
        "more": "আরও",
        "copy_success": "সফলভাবে কপি করা হয়েছে",
        "wait_pay": "প্রদানের অপেক্ষায়",
        "paid": "পরিশোধিত",
        "cancelled": "বাতিল করা হয়েছে",
        "unpaid": "অপরিশোধিত",
        "open": "খোলা",
        "my_account": "আমার অ্যাকাউন্ট",
        "processing": "প্রক্রিয়াধীন",
        "fail": "ব্যর্থ",
        "success": "সফল",
        "my_boxs": "আমার বাক্স",
        "game_rules": "খেলার নিয়মাবলী",
        "Me": "আমি",
        "download_app_text": "সর্বশেষ বিনামূল্যে বাজি এবং বোনাস আপডেটের জন্য আমরা এটি আপনার হোম স্ক্রিনে যুক্ত করতে চাই।",
        "click_install": "ইনস্টল ক্লিক করুন",
        "Home": "হোম",
        "i_know": "আমি ইতিমধ্যে জানি",
        "click_me": "আমাকে ক্লিক করুন ইনস্টল করতে",
        "Mine": "আমার",
        "lottery": "লাকি ড্র",
        "no_account": "এখনও কোনো অ্যাকাউন্ট নেই?",
        "other_login_way": "অন্য পদ্ধতি",
        "login.access_denied": "প্রবেশ নিষেধ",
        "services": "সেবা",
        "Promotions": "প্রমোশন",
        "lottery_ending": "এই লটারির ইভেন্টটি শেষ হয়েছে।",
        "lottery_not_start": "লটারির ইভেন্টটি এখনো শুরু হয়নি।",
        "no_more_draw_count": "আর লটারির সুযোগ নেই।",
        "pls_login": "লগইন করা হয়নি। প্রথমে লগইন করুন।",
        "MyBox": "আমার গিফট বক্স",
        "SHARE": "শেয়ার",
        "my_draw_count": "লটারি অংশগ্রহণের উপলভ্য সংখ্যা",
        "lottery_not_match": "এই ইভেন্টের নিয়ম পূরণ হয়নি।",
        "not_win": "পরিশ্রম অব্যাহত রাখুন",
        "open_rule": "খোলার শর্তাবলী",
        "my_keys": "আমার চাবি",
        "box_not_exist": "ট্রেজার বক্সটি বিদ্যমান নেই।",
        "open_box_fail": "খোলার শর্তাবলী পূরণ হয়নি।",
        "win_box": "${amount} সফলভাবে প্রাপ্ত হয়েছে। পুরস্কারটি আপনার অ্যাকাউন্টে স্বয়ংক্রিয়ভাবে পাঠানো হয়েছে।",
        "share_twitter": "X/Twitter-এ শেয়ার করুন",
        "share_telegram": "Telegram-এ শেয়ার করুন",
        "share_facebook": "Facebook-এ শেয়ার করুন",
        "share_link": "লিঙ্কটি কপি করে বন্ধুদের পাঠান",
        "share_whatsapp": "WhatsApp-এ শেয়ার করুন",
        "no_data": "কোনও তথ্য নেই",
        "username_tip1": "অ্যাকাউন্ট ফরম্যাট: ৪-১৬ অক্ষরের ইংরেজি বর্ণ বা সংখ্যা",
        "password_tip1": "পাসওয়ার্ড ফরম্যাট: ৪-১৬ অক্ষরের",
        "confirm_delete": "আপনি কি মুছে ফেলার ব্যাপারে নিশ্চিত?",
        "delete_fail": "মুছে ফেলা ব্যর্থ হয়েছে",
        "delete_success": "সফলভাবে মুছে ফেলা হয়েছে",
        "is_close": "বন্ধ করার ব্যাপারে নিশ্চিত?",
        "deposit_record": "ডিপোজিট রেকর্ড",
        "withdraw_record": "উত্তোলন রেকর্ড",
        "withdraw_tip": "উত্তোলনের শর্ত পূরণ করা হয়নি। উত্তোলনের জন্য আপনার মোট বাজির পরিমাণ ${needAmount}-এর বেশি হতে হবে।",
        "withdraw_amount": "উত্তোলনযোগ্য পরিমাণ",
        "input_withdraw_amount": "উত্তোলনের পরিমাণ ${start} - ${end} এর মধ্যে হতে হবে।",
        "bet_amount_all": "আপনার বর্তমান মোট বাজির পরিমাণ ${betAmountAll}।",
        "play_again": "যাও এবং খেলা চালিয়ে যাও।",
        "login_password": "লগইন পাসওয়ার্ড।"


    }



