import { useEffect } from "react"

export default (props:any)=>{

    const RouteComponent = props.routeComponent;
    return (
        <>
           {RouteComponent? <RouteComponent/>:props.children}
        </>
    )
}