// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.select-prop-modal .body {
  padding: 2px;
  max-height: 400px;
  overflow-y: auto;
  min-height: 250px;
}
.select-prop-modal .body .adm-list-item-content-prefix {
  display: flex;
  align-items: center;
}
.select-prop-modal-PC {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  left: 0px;
  top: 0px;
}
.select-prop-modal-PC .adm-center-popup-wrap {
  min-width: 400px;
  border-radius: 8px;
  min-height: 50px;
  position: relative;
  /* 添加阴影 */
  max-height: calc(100vh - 100px);
  overflow-y: hidden;
  transform: none;
  left: 0px;
  top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/public/select-popup.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAGZ;EAEI,YAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;AAFR;AAIQ;EACI,aAAA;EACA,mBAAA;AAFZ;AAiBA;EAII,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,cAAA;EAIA,SAAA;EAEA,QAAA;AAvBJ;AA0BI;EACI,gBAAA;EAEA,kBAAA;EACA,gBAAA;EACA,kBAAA;EAGA,SAAA;EACA,+BAAA;EACA,kBAAA;EACA,eAAA;EACA,SAAA;EACA,QAAA;AA3BR","sourcesContent":[".select-prop-modal {\n\n\n    .body {\n        // min-height: 40vh;\n        padding: 2px;\n        max-height: 400px;\n        overflow-y: auto;\n        min-height: 250px;\n\n        .adm-list-item-content-prefix {\n            display: flex;\n            align-items: center;\n            \n        }\n\n    \n    }\n\n    .item:hover,.item:active{\n        // background-color: var(--adm-color-text);\n        \n        // color: var(--adm-color-border);\n    }\n\n}\n\n.select-prop-modal-PC {\n\n\n\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    z-index: 99999;\n\n    // --min-width: 480px;\n\n    left: 0px;\n\n    top: 0px;\n\n\n    .adm-center-popup-wrap {\n        min-width:400px;\n        // left: calc(50vw - 240px);\n        border-radius: 8px;\n        min-height: 50px;\n        position: relative;\n        // border: 1px solid var(--adm-color-border);\n        // box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);\n        /* 添加阴影 */\n        max-height: calc(100vh - 100px);\n        overflow-y: hidden;\n        transform: none;\n        left: 0px;\n        top:0px;\n\n\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
