import { NavBar, Form, Input, Button } from "antd-mobile"
import { useGetSiteConfig} from "../../../public/SiteConfigProvier"
import { useNavigate } from "react-router";
import { LoginOrRegisterFormView } from "../public/LoginOrRegisterModal";
import { useGetLocale } from "../../../public/LangProvier";
import { useSetLogin } from "../../../public/LoginProvier";
import { NavigateConfig } from "../../../layout/NavbarLayout";
import { useEffect } from "react";


type RegisterProps = {
    onNavigateConfig?:(config:NavigateConfig)=>void
}


export default ({onNavigateConfig}:RegisterProps) => {

    const config = useGetSiteConfig();

    const navigate  = useNavigate();

    const locale =  useGetLocale();
    const setLogin = useSetLogin();

    useEffect(()=>{

        onNavigateConfig?.({
            onBack() {
                navigate("/",{replace:true});
            },
        });

    },[]);

    return (
        <div>
            <LoginOrRegisterFormView 
            
                type="Register"
                onSuccess={(user:any)=>{
                    setLogin({
                        login: true,
                        user: user
                    });
                    navigate("/",{replace:true});
                }}

                onChange={(v:any)=>{

                    if(v.type == "Login"){
                        navigate("/login",{replace:true});
                    }

                }}

                

            
            />
        </div>
    )

}