import { useNavigate, useSearchParams } from "react-router-dom"

import "./Chaport.scss";
import { hexToString, isPcClient, stringToHex } from "../../../utils";
import { useGetLogin } from "../../../public/LoginProvier";
import Modal from "../../../public/Modal";
import { useGetLocale } from "../../../public/LangProvier";

export default () => {


    const [search] = useSearchParams();

    const isPC = isPcClient();

    const navigate = useNavigate();

    const {login,user} = useGetLogin();

    const deviceId = localStorage.getItem("client-id");

    const locale = useGetLocale();

    


    return (
        <div className="chaport-page" >
            <iframe
                src={`/p/chaport.html#appId=${search.get("appId")}&username=${login?`${user.username}(ID:${user.id})`:""}&clientId=${login?`${stringToHex(`${user.id}`)}`:deviceId}`}
                style={{ width: "100%", border: 0, height: "100vh" }}

            />

            <div className="close" onClick={()=>{

                Modal.confirm({
                    content:locale("is_close"),
                    onOk:async()=>{
                        if(isPC){
                            window.close();
                            return;
                        }
        
                        navigate("/",{replace:true});
                    }
                })
              
                
            }} >
                <img src={require("./close.png")} id="close-btn" />
            </div>

        </div>
    )

}