// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site-loading .image-container {
  position: relative;
  width: 300px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.site-loading .image-container img {
  width: 60px;
  animation: spin 0.5s linear infinite;
}
.site-loading .image-container .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  border: 5px solid var(--adm-color-text);
  border-top: 5px solid var(--adm-color-primary);
  border-radius: 50%;
  animation: spin 0.5s linear infinite;
  transform: translate(-50%, -50%);
}
@keyframes spin {
  0%, 100% {
    filter: grayscale(100%);
  }
  50% {
    filter: grayscale(0);
  }
}`, "",{"version":3,"sources":["webpack://./src/public/site-loading.scss"],"names":[],"mappings":"AAEI;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EAEA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;AAFR;AAKI;EAII,WAAA;EAEA,oCAAA;AAPR;AAcI;EACI,kBAAA;EACA,QAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,uCAAA;EACA,8CAAA;EACA,kBAAA;EACA,oCAAA;EACA,gCAAA;AAZR;AAeI;EACI;IAEI,uBAAA;EAdV;EAgBM;IAEI,oBAAA;EAfV;AACF","sourcesContent":[".site-loading {\n\n    .image-container {\n        position: relative;\n        width: 300px;\n        height: 200px;\n        // background-color: #fff;\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        flex-direction: column;\n    }\n\n    .image-container img {\n        // position: absolute;\n        // top: 50%;\n        // left: 50%;\n        width: 60px;\n        // height: 50px;\n        animation: spin 0.5s linear infinite;\n        // transform: translate(-50%, -50%);\n        // margin-bottom: 5px;\n        \n\n    }\n\n    .image-container .loader {\n        position: absolute;\n        top: 50%;\n        left: 50%;\n        width: 50px;\n        height: 50px;\n        border: 5px solid var(--adm-color-text);\n        border-top: 5px solid var(--adm-color-primary);\n        border-radius: 50%;\n        animation: spin 0.5s linear infinite;\n        transform: translate(-50%, -50%);\n    }\n\n    @keyframes spin {\n        0%, 100% {\n            // opacity: 0;\n            filter: grayscale(100%);\n        }\n        50% {\n            // opacity: 1;\n            filter: grayscale(0);\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
