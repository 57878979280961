import React, { createContext, FC, useContext } from "react";
import api from "../config/api";


export type Balance = {
    balance?: number,
    currency?: string,
}|null


type UserBalanceProps = {
    balance?: Balance,
    setBalance: (balance: Balance) => void
} | null

const UserBalanceContext = createContext<UserBalanceProps>(null);

export const useGetUserBalance = () => {

    const contenxt = useContext(UserBalanceContext);

    const balance: Balance = {
        ...contenxt?.balance
    }

    return balance;


}

export const useSetUserBalance = () => {
    const contenxt = useContext(UserBalanceContext);
    return (balance: Balance) => {
        contenxt?.setBalance(balance);
    }
}

/**
 * 
 * @returns 刷新用户余额
 */
export const useRefreshUserBalance = ()=>{

    const contenxt = useContext(UserBalanceContext);
   
    return  ()=>{
        api("user/balance").get({}).then(ret=>{
            if(ret.code == 200){
                contenxt?.setBalance({...ret.data});
            }
        });
    }

}

type UserBalanceProiverProps = {
    balance: UserBalanceProps,
    children?: React.ReactNode
}

const UserBalanceProiver: FC<UserBalanceProiverProps> = ({ children, balance }) => {
    return (
        <UserBalanceContext.Provider value={balance}>
            {children}
        </UserBalanceContext.Provider>
    )

}


export default UserBalanceProiver;