import { NavBar, Form, Input, Button, Tag, Tabs } from "antd-mobile"
import { useGetSiteConfig } from "../../../public/SiteConfigProvier"
import "./index.scss";
import ReCaptcha from "../../../public/ReCaptcha";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { useGetLocale } from "../../../public/LangProvier";
import { LoginOrRegisterFormView } from "../public/LoginOrRegisterModal";
import { useSetLogin } from "../../../public/LoginProvier";
import { NavigateConfig } from "../../../layout/NavbarLayout";
import { useSearchParams } from "react-router-dom";



type LoginProps = {
    onNavigateConfig?:(config:NavigateConfig)=>void
}

export default ({onNavigateConfig}:LoginProps) => {

    const config = useGetSiteConfig();

    const navigate = useNavigate();

    const [btnType, setBtnType] = useState<any>("Login");

    const locale = useGetLocale();

    const setLogin = useSetLogin();

    const [search] = useSearchParams();

    const onBack = ()=>{
        if(search.get("backUrl")){
            window.location.replace(`${search.get("backUrl")}`);
            return;
        }
        navigate("/",{replace:true});
    }

    useEffect(()=>{
            onNavigateConfig?.({
                onBack() {
                    onBack();
                },
            })
    },[])

    return (
        <div>
            <LoginOrRegisterFormView
                type={btnType}
                onSuccess={(user:any)=>{
                    setLogin({
                        login: true,
                        user: user
                    });
                    onBack();
                }}

                onChange={(v:any)=>{

                    setBtnType(v.type);

                }}
            />

           


        </div>
    )

}