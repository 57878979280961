import { Button, ErrorBlock } from "antd-mobile";
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetLocale } from "../../../public/LangProvier";
import api from "../../../config/api";
import Loading from "../../../public/Loading";
import { useSetLogin } from "../../../public/LoginProvier";
import { getSystem } from "../../../utils";
import { navigateBack } from "../../../public/AppProvier";

export default () => {

  const [search] = useSearchParams();

  const error = search.get("error");

  const locale = useGetLocale();

  // const [loading,setLoading] = useState(false);


  const getStateParams = (state: any) => {

    if (!state) {
      return [];
    }

    const params: any = {};

    state.split("&").forEach((value: any) => {
      const arr = value.split("=");
      if (arr.length == 2) {
        params[`${arr[0]}`] = arr[1];
      }
    })
    return params;


  }

  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  const accessToken = params.get('access_token');
  const state = getStateParams(params.get("state"));

  const [loginResult, setLoginResult] = useState<any>(null);

  const setLogin = useSetLogin();

  const navigate = useNavigate();


  useEffect(() => {



    if (error) {
      return;
    }



    api("login/auth").post({
      clientId: state.clientId,
      accessToken: accessToken
    }).then(ret => {
      setLoginResult(ret);
      if (ret.code == 200) {
        setLogin({ login: true, user: ret.data });
        const system = getSystem();

        if (system == "IOS") {
          navigate("/", { replace: true });
          return;
        }
        window.close();

      }

    })









  }, []);


  if (error) {

    return (
      <div style={{ width: "100vw", display: "flex", alignItems: "center", justifyContent: "center", paddingTop: "20%" }}>
        <ErrorBlock
          title={locale("login.access_denied")}
          description={
            <div style={{ width: 300 }} onClick={() => {
              const system = getSystem();

              if (system == "IOS") {
                navigate("/", { replace: true });
                return;
              }
              window.close();
              //  setTimeout(()=>{
              //    window.location.href=""
              //  },2000);
            }}>
              <Button block color="primary">{locale("text.ok")}</Button>
            </div>
          }
        />
      </div>
    )
  }

  if (!loginResult) {
    return (
      <Loading />
    )
  }


  return (
    <>
      {loginResult?.code != 200 ? (
        <ErrorBlock
          status="default"
          title={loginResult?.msg}
        >

        </ErrorBlock>
      ) : null}
    </>
  )
}