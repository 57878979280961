import { Button, Divider, Form, Grid, Input, Modal, Popup, PopupProps, Space, Toast } from "antd-mobile";
import { FC, useEffect, useRef, useState } from "react";
import api from "../../../config/api";
import { useGetSiteConfig } from "../../../public/SiteConfigProvier";
import "./login-or-register-modal.scss"
import { useGetLocale } from "../../../public/LangProvier";
import ReCaptcha from "../../../public/ReCaptcha";
import {
    GoogleReCaptchaProvider,
    useGoogleReCaptcha,
} from 'react-google-recaptcha-v3';
import { useGetLogin, useSetLogin } from "../../../public/LoginProvier";
import { getClientType, getInviteFrom, getRealClientType, getSystem } from "../../../utils";
import UploadImage from "./UploadImage";
import { useNavigate } from "react-router";


type Type = "Login" | "Register";

interface LoginOrRegisterModalProps extends PopupProps {
    type?: Type,
    onChange?: any

}

type FormProps = {
    type?: Type,
    onSuccess?: any,
    onChange?: any
}

const GoogleReCaptcha = (props: any) => {

    const config = useGetSiteConfig();


    if (config?.recaptcha && config.recaptcha.open) {

        return (
            <GoogleReCaptchaProvider reCaptchaKey={`${config.recaptcha?.clientId}`}>
                {props.children}
            </GoogleReCaptchaProvider>
        )

    }

    return (
        <>
            {props.children}
        </>
    )



}


const OtherLoginWay = () => {

    const [loading, setLoading] = useState(false);
    const locale = useGetLocale();

    const config = useGetSiteConfig();

    const setLogin = useSetLogin();

    const navigate = useNavigate();

    const timer = useRef<any>(null);

    const popupWindow = useRef<any>(null);

    const authCheck = (clientId: any) => {

        api(`login/auth/check/${clientId}`).get({}).then(ret => {

            if (ret.code == 200) {
                setLogin({ login: true, user: ret.data });
                navigate("/", { replace: true });
                popupWindow.current?.close();
                clearInterval(timer.current);
                return;
            }

        })


    }

    function openInNewTab(url: any) {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getOauthData = (type: any) => {

        if (timer.current) {
            clearInterval(timer.current);
        }

        if (popupWindow.current) {
            popupWindow.current?.close?.();
        }


        const toast = Toast.show({
            icon: 'loading',
            content: 'Loading...',
        });

        const inviteFrom = getInviteFrom();

        api("login/auth/data").post({
            type: type,
            fromUserId: inviteFrom?.userId,
            fromChannelId: inviteFrom?.channelId

        }).then(ret => {

            if (ret.code == 200) {

                const width = 400;
                const height = 600;

                const screenWidth = window.screen.width;
                const screenHeight = window.screen.height;

                // 计算窗口左上角的 x 和 y 坐标
                const left = (screenWidth - width) / 2;
                const top = (screenHeight - height) / 2;

                //window.location.href = ret.data.oauthUrl;

                const client = getClientType();

                if (client == "PC") {
                    popupWindow.current = window.open(ret.data?.oauthUrl, 'popup', `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=yes,resizable=yes`);
                } else {

                    const system = getSystem();

                    if (system == "IOS") {
                        window.location.href = ret.data.oauthUrl;
                    } else {
                        window.open(ret.data?.oauthUrl, '_blank')
                    }

                    // openInNewTab(ret.data?.oauthUrl);



                }


                timer.current = setInterval(() => authCheck(ret.data?.clientId), 3000);

            }

        }).finally(() => {
            const system = getSystem();
            if (system == "IOS") {
                return;
            }
            toast.close();
        })

    }



    useEffect(() => {


        return () => {

            if (timer.current) {
                clearInterval(timer.current);
            }
            if (popupWindow) {
                popupWindow.current?.close();
            }

        }

    }, []);

    if (config?.otherLoginWays?.length == 0) {
        return null;
    }

    const types = config?.otherLoginWays?.map(x => x.type);


    return (
        <div>
            <div>
                <Divider >{locale("other_login_way")}</Divider>
            </div>
            <div className="other-way">
                <Space style={{ "--gap": "20px" }}>
                    {types?.includes("TELEGRAM_BOT") ? (
                        <div className="login-img-btn" onClick={() => getOauthData("TELEGRAM_BOT")}>
                            <img src={require("../../../public/assets/telegram.png")} />
                        </div>
                    ) : null}
                    {types?.includes("GOOGLE_AUTH") ? (
                        <div className="login-img-btn" onClick={() => getOauthData("GOOGLE_AUTH")}>
                            <img src={require("../../../public/assets/google.png")} />
                        </div>
                    ) : null}

                    {types?.includes("FACEBOOK_AUTH") ? (
                        <div className="login-img-btn" onClick={() => getOauthData("FACEBOOK_AUTH")}>
                            <img src={require("../../../public/assets/Facebook.png")} />
                        </div>
                    ) : null}

                </Space>
            </div>
        </div>
    )

}


const LoginForm: FC<FormProps> = (props) => {


    const [loading, setLoading] = useState(false);
    const locale = useGetLocale();



    return (

        <Form
            onFinish={values => {
                setLoading(true);
                api("login/account").post({
                    ...values
                }).then(ret => {
                    if (ret.code == 200) {
                        props.onSuccess?.(ret.data);
                    }
                }).finally(() => {
                    setLoading(false);
                })

            }}
            footer={<>

                <div style={{ paddingTop: 10, marginBottom: 20 }}>
                    <Button loading={loading} block type="submit" color="primary" size="large">{locale("text.login")}</Button>
                </div>
                {/* <div style={{ textAlign: "center" }}>
                   <span>{locale("no_account")}</span>   <a href="/register">{locale("text.reg")}</a>
                </div> */}

                <OtherLoginWay />

            </>}
        >
            <Form.Item name={"username"} rules={[{ required: true }]}>
                <Input placeholder={locale("text.username")} />
            </Form.Item>
            <Form.Item name={"password"} rules={[{ required: true }]}>
                <Input placeholder={locale("text.password")} type="password" />
            </Form.Item>







        </Form>
    )

}

const RegisterForm: FC<FormProps> = (props) => {


    const [loading, setLoading] = useState(false);
    const locale = useGetLocale();
    const config = useGetSiteConfig();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const getCaptcha = async () => {

        if (config?.recaptcha && config.recaptcha.open) {

            return await executeRecaptcha?.("submit");
        }

        return null;

    }






    const inviteFrom = getInviteFrom();

    const onRegister = async (values: any) => {

        const captcha = await getCaptcha();

        const ret = await api("register").post({
            ...values,
            fromUserId: inviteFrom.userId,
            fromChannelId: inviteFrom.channelId,
            captcha: captcha
        });

        if (ret?.code == 200) {
            props.onSuccess?.(ret.data);
        }

    }


    return (



        <Form
            onFinish={values => {
                setLoading(true);

                onRegister(values).finally(() => {
                    setLoading(false);
                })

            }}
            footer={<>

                <div>
                    <Button loading={loading} block type="submit" color="primary" size="large">{locale("text.reg")}</Button>
                </div>
                <OtherLoginWay />
            </>}
        >
            <Form.Item name={"username"} rules={[{ required: true },{pattern:/^[a-zA-Z0-9]{4,16}$/,message:locale("username_tip1")}]}>
                <Input placeholder={locale("text.username")} autoComplete="off" />
            </Form.Item>
            <Form.Item name={"password"} rules={[{ required: true },{pattern:/^.{4,16}$/,message:locale("password_tip1")}]}>
                <Input placeholder={locale("text.password")} type="password" autoComplete="new-password" />
            </Form.Item>
            <Form.Item name={"confirmPassword"} dependencies={['password']} rules={[{ required: true }, ({ getFieldValue }) => ({
                validator(rule, value, callback) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error(locale("text.passwod2_err")));
                },
            })]}>
                <Input placeholder={locale("text.password2")} type="password" autoComplete="new-password" />
            </Form.Item>
        </Form>

    )

}

export const LoginOrRegisterFormView: FC<FormProps> = (props) => {

    const config = useGetSiteConfig();
    const locale = useGetLocale();


    return (
        <>

            <GoogleReCaptcha>



                <div className="login-or-register-modal">

                    <div>
                        <div className="login-logo">
                            <img src={config?.logo} />
                        </div>
                    </div>
                    <Grid columns={2} gap={0} style={{ marginBottom: 30 }}>
                        <Grid.Item>
                            <Button
                                style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderRight: 0
                                }}
                                block
                                color={props?.type == "Login" ? "primary" : "default"}
                                onClick={() => {
                                    props.onChange?.({ open: true, type: "Login" })
                                }}
                            >{locale("text.login")}</Button>
                        </Grid.Item>
                        <Grid.Item>
                            <Button
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderLeft: 0
                                }}
                                block
                                color={props?.type == "Register" ? "primary" : "default"}
                                onClick={() => {
                                    props.onChange?.({ open: true, type: "Register" })
                                }}

                            >{locale("text.reg")}</Button>
                        </Grid.Item>
                    </Grid>



                    <div>
                        {props.type == "Login" ? <LoginForm {...props} /> : (
                            <>

                                <RegisterForm {...props} />

                            </>
                        )}
                    </div>

                </div>
            </GoogleReCaptcha>
        </>
    )



}

const LoginOrRegisterModal: FC<LoginOrRegisterModalProps> = (props) => {


    const setLogin = useSetLogin();

    const clientType = getClientType();


    return (
        <Popup
            {...props}
            className={`app-login-modal-${clientType}`}

        >
            {props.visible ? <LoginOrRegisterFormView
                type={props.type}
                onSuccess={(user: any) => {

                    props.onClose?.();

                    setLogin({
                        login: true,
                        user: user
                    });


                }}
                onChange={props.onChange}
            /> : null}

        </Popup>
    )
}

export default LoginOrRegisterModal;