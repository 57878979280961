

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import "./index.scss";


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

var styles:any = {
  "--background":`${process.env.REACT_APP_BACKGROUND}`,
  "--adm-color-primary":`${process.env.REACT_APP_THEME_COLOR}`,
  "--category-active-background":`${process.env.REACT_APP_CATEGORY_ACTIVE}`,
  "--category-active-box-shadow":`${process.env.REACT_APP_CATEGORY_ACTIVE_BOX}`,
  "--category-box-shadow":`${process.env.REACT_APP_CATEGORY_BOX}`,
  "--category-border":`${process.env.REACT_APP_CATEGORY_BORDER}`,
  "--category-background":`${process.env.REACT_APP_CATEGORY_BACKGROUND}`,
  "--search-title-background":`${process.env.REACT_APP_SEARCH_TITLE_BACKGROUND}`,
  "--home-backgroud":`${process.env.REACT_APP_HOME_BACKGROUD}`,
  "--adm-color-border":`${process.env.REACT_APP_COLOR_BORDER}`,
  "--item-game-box":`${process.env.REACT_APP_ITEM_GAME_BOX}`,
  "--item-game-bg":`${process.env.REACT_APP_ITEM_GAME_BG}`,
  "--vendor-item-bg":`${process.env.REACT_APP_VENDOR_ITEM_BG}`,
  "--button-primary-background":`${process.env.REACT_APP_BUTTON_PRIMARY_BACKGROUND}`,
  "--adm-color-box":`${process.env.REACT_APP_COLOR_BOX}`,
 
};

Object.keys(styles).forEach(key=>{

  const value = styles[`${key}`];
  if(value && value != "undefined"){
    const root = window.document.documentElement;
    root.style.setProperty(key,value);
  }

})

// 使用 React.lazy 动态导入组件
const LazyComponent = React.lazy(() => import('./App'));

const AppIndexLazyComponent = React.lazy(() => import('../src/pages/h5/app/Index'));

const App = () => {
  return (

    <Suspense fallback={
      <div style={{ width: "100vw", height: "100vh", background: "var(--background)" }}>

       
          <div className="loader"></div>
        

      </div>
    } >
      <LazyComponent />
    </Suspense>

  );
};

const AppIndex = () => {
  return (

    <Suspense fallback={
      <div style={{ width: "100vw", height: "100vh", background: "#fff" }}></div>
    } >
      <AppIndexLazyComponent />
    </Suspense>

  );
};

const landingPage = ["/app"].includes(window.location.pathname);
if (landingPage) {
  root.render(

    <AppIndex />

  );
} else {
  root.render(

    <App />

  );

}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
