import React, { useEffect } from 'react';
import { Avatar, List, Button, Space, Grid, NavBar } from 'antd-mobile';
import { UnorderedListOutline, SetOutline, TagOutline, BellOutline, PayCircleOutline, BankcardOutline, AddCircleOutline, GlobalOutline } from 'antd-mobile-icons';
import { useGetLogin, useSetLogin } from '../../../public/LoginProvier';
import Loading from '../../../public/Loading';
import "./user-profile.scss";
import { useNavigate } from 'react-router';
import { useGetUserBalance } from '../../../public/UserBalanceProvier';
import CurrencySymbol from '../../../public/CurrencySymbol';
import { useGetLocale } from '../../../public/LangProvier';
import { formatAmount } from '../../../utils';
import Modal from '../../../public/Modal';
import api from '../../../config/api';
import IconFont from '../../../public/iconfont/Index';

const UserProfile = ({ onNavigateConfig }: any) => {

  const login = useGetLogin();

  const navigate = useNavigate();


  const { balance, currency } = useGetUserBalance();

  const locale = useGetLocale();
  
  const setLogin = useSetLogin();


  useEffect(() => {


    onNavigateConfig?.({ title: locale("user_center") })

    if (!login) {


      navigate("/login", { replace: true })


    }

  }, [login.login]);

  if (!login.login) {

    return null;

  }

  const user = login.user;

   /**
     * 退出登陆
     */
   const logout = () => {

    Modal.confirm({
        title: locale("tip"),
        content: locale("logout_text"),
        okText: locale("text.ok"),
        cancelText: locale("text.cancel"),
        onOk: async () => {

            await api("login/logout").post({});
            setLogin({ login: false, user: null });
            sessionStorage.setItem("tabbar", "home");
            navigate("/home", { replace: true });


        }
    })
}


  return (
    <div className='user-profile'>

      {/* <NavBar backIcon={null} ></NavBar> */}
      <div style={{padding:12}}>

      <List

      // mode="card"
      >
        <List.Item
          prefix={
            <Avatar src='' style={{ '--size': '40px', "--border-radius": "5px" }} />
          }
          description={
            <div>UID: {user?.id}</div>
          }
          onClick={() => {

          }}

        >
          {user?.username}

        </List.Item>
        <List.Item
          title={locale("balance")}
          // prefix={
          //   <PayCircleOutline />
          // }
          description={
            <></>
          }
        >
          <div>

            <div
              className='balance'
            >
              {formatAmount(currency, balance)}
            </div>

            <div style={{ marginBottom: 10 }}>
              <Grid columns={2} gap={10} >
                <Grid.Item>

                  <Button onClick={() => {

                    navigate("/deposit")

                  }} color="primary" block fill="outline">{locale("deposit")}</Button>


                </Grid.Item>
                <Grid.Item>
                  <Button onClick={() => navigate("/withdraw")} color="danger" block fill="outline">{locale("withdraw")}</Button>
                </Grid.Item>
              </Grid>
            </div>

          </div>
        </List.Item>

      </List>

      <List style={{ marginTop: '20px' }}  >

        {/* <List.Item
          prefix={<IconFont name='icon-game-----' style={{fontSize:20}} />}
          onClick={() => {

          }}
        >
          {locale("game_records")}
        </List.Item> */}
        <List.Item
          prefix={<IconFont name='icon-cunkuanjilu' style={{fontSize:20}} />}
          onClick={() => {
            navigate("/deposit/record")
          }}
        >
          {locale("deposit_record")}
        </List.Item>
        <List.Item
          prefix={<IconFont name='icon-tikuanjilu' style={{fontSize:20}} />}
          onClick={() => {
            navigate("/withdraw/record")
          }}
        >
          {locale("withdraw_record")}
        </List.Item>
        <List.Item
          prefix={<BankcardOutline />}
          onClick={() => {
            navigate("/withdraw/account")
          }}
        >
          {locale("my_account")}
        </List.Item>
       
        <List.Item
          prefix={<GlobalOutline />}
          onClick={() => { navigate("/user/setting/language") }}
        >
          {locale("lang_setting")}
        </List.Item>
      </List>

      </div>
     
      <div className='logout'>
          <span onClick={()=>logout()}>{locale("logout")}</span>
      </div>

      
    </div>
  );
};

export default UserProfile;