// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* 设置视口高度 */
  overflow: hidden;
  position: relative;
  background: var(--background);
}

html {
  scroll-behavior: auto;
}

.loader {
  width: 100vw;
  height: 5px;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), var(--adm-color-primary), rgba(0, 0, 0, 0.1));
  background-size: 200% 100%;
  animation: load 1.5s infinite;
}

@keyframes load {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;;;EAII,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;EACA,6BAAA;AACJ;;AAIA;EACI,qBAAA;AADJ;;AAIA;EACI,YAAA;EACA,WAAA;EACA,oGAAA;EACA,0BAAA;EACA,6BAAA;AADJ;;AAIE;EACE;IACE,2BAAA;EADJ;EAGE;IACE,4BAAA;EADJ;AACF","sourcesContent":["html,\nbody,\n#root {\n\n    margin: 0;\n    padding: 0;\n    width: 100%;\n    height: 100%;\n    /* 设置视口高度 */\n    overflow: hidden;\n    position: relative;\n    background: var(--background);\n\n\n}\n\nhtml {\n    scroll-behavior: auto;\n}\n\n.loader {\n    width: 100vw;\n    height: 5px;\n    background: linear-gradient(90deg, rgba(0, 0, 0, 0.1), var(--adm-color-primary), rgba(0, 0, 0, 0.1));\n    background-size: 200% 100%;\n    animation: load 1.5s infinite;\n  }\n\n  @keyframes load {\n    from {\n      background-position: 200% 0;\n    }\n    to {\n      background-position: -200% 0;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
