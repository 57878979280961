import { Avatar, Button, CapsuleTabs, Card, Divider, Ellipsis, ErrorBlock, Grid, Image, InfiniteScroll, List, NoticeBar, Popup, PullToRefresh, Space, Swiper, SwiperRef, Tabs, Tag, Toast } from "antd-mobile"
import { useGetLang, useGetLocale } from "../../../public/LangProvier";
import { RightOutline, EyeFill, PlayOutline } from 'antd-mobile-icons'
import { useLocation, useNavigate } from "react-router";
import { FC, useEffect, useRef, useState } from "react";
import api from "../../../config/api";
import { PlayGamePopup } from "../play/Index";
import { useSetLoginRegisterOpen } from "../../../public/LoginRegisterOpenProvier";
import { useGetLogin } from "../../../public/LoginProvier";
import Loading from "../../../public/Loading";
import SiteLoading from "../../../public/SiteLoading";
import { formatNumberView, getClientType, getClientWidth, getRealClientType, getRemUnit, getSystem, jumpPage, paginateArray, win } from "../../../utils";
import './index.scss';
import dayjs, { locale } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

import { useGetSiteConfig } from "../../../public/SiteConfigProvier";
import IconFont from "../../../public/iconfont/Index";
import getLang from "../../../config/lang";
import { motion } from 'framer-motion';
import { usePlayGame } from "../../../public/PlayGameProvier";
import { useOnPageShow } from "../../../public/AppProvier";
import ImageView from "../../../public/ImageView";
import ShowNotice from "./ShowNotice";
import ShowDownloadApp from "./ShowDownloadApp";
dayjs.extend(relativeTime);






const PCGameLayout = (props: any) => {

    const { gameList, onPlayClick, vender } = props;

    if (!gameList?.length) {
        return null
    }

    return (
        <Card
            {...props}
            className={`home-section game-list-section ${props.className}`}
        // bodyStyle={{ padding: "20px 20px" }}
        // title={vender?vender?.name:""}


        >
            <Grid
                columns={8}
                gap={20}
            >
                {gameList?.map((game: any, index: number) => {

                    if (props.maxSize && index >= props.maxSize) {
                        return null;
                    }

                    return (
                        <Grid.Item key={index} onClick={() => onPlayClick(game)} className="game-item-bg" >
                            <motion.div whileHover={{ scale: 1.05 }}>


                                <ImageView
                                    src={`${game.imageSquare}`}
                                    className="section-item-image"


                                />

                                <div className="section-item-text">
                                    <Ellipsis content={game.name} rows={2} />
                                    {game?.lastOpenTime ? (
                                        <div className="date">{dayjs(game?.lastOpenTime).fromNow()}</div>
                                    ) : null}
                                </div>
                            </motion.div>

                        </Grid.Item>
                    )

                })}
            </Grid>
        </Card>
    )
}

const H5GameListLayout = (props: any) => {

    const { gameList, onPlayClick } = props;

    const locale = useGetLocale();

    if (gameList.length == 0) {
        return null;
    }

    return (
        <List className={`list-items ${props.className?props.className:""}`} style={{ ...props.style }}>
            {gameList?.map((it: any, index: number) => {
                return (
                    <List.Item
                        key={index}
                        arrowIcon={false}
                     
                        prefix={
                            <ImageView
                                src={`${it.imageSquare}`}
                                className="section-item-image-2"
                                width={60}
                                height={60}


                            />
                        }
                        extra={
                            <div>
                                <Button onClick={() => {
                                    onPlayClick?.(it);
                                }} size="mini" fill='outline' color="primary">{locale("play")}</Button>
                            </div>
                        }
                        description={
                            <div>
                                <Space>
                                    <Tag color="default" fill='outline'>
                                        {it.vendor?.name}
                                    </Tag>
                                    <Tag fill='outline'>
                                        <EyeFill />
                                        <span style={{ marginLeft: 3 }}>
                                            {formatNumberView(it?.visitCount)}
                                        </span>

                                    </Tag>
                                </Space>
                            </div>
                        }

                    >
                        <div style={{ marginBottom: 3 }}>
                            <Ellipsis content={it.name} rows={1} />
                        </div>

                    </List.Item>
                )
            })}

        </List>
    )

}

type GameListProps = {
    category: any,
    onPlayClick: any,
    clientType: any,
    active?: boolean
}

const GameList: FC<GameListProps> = ({ category, onPlayClick, clientType, active }) => {

    const [gameList, setGameList] = useState<any>([]);

    const [vendors, setVendors] = useState<any>([]);

    const [hasMore, setHasMore] = useState(true);

    const [pageNo, setPageNo] = useState<number>(0);

    const locale = useGetLocale();


    const [vendor, setVendor] = useState<any>({ id: null, name: locale("all") });

    const pageSize = clientType == "PC" ? 24 : 10;



    const lang = useGetLang();


    const loadGameList = async (num: number, vendorId?: any) => {


        const { code, data } = await api("game/list").cache(true).get({
            pageNo: num,
            pageSize: pageSize,
            category: category == "ALL" ? "" : category,
            vendorId: vendorId
        });

        if (code != 200) {
            setHasMore(false);
        }

        if (code == 200) {
            setGameList(num == 1 ? [
                ...data
            ] : [
                ...gameList,
                ...data
            ]);
            setHasMore(() => data.length == pageSize);
            setPageNo(num);
        }
    }

    const loadVendors = async () => {
        const { code, data } = await api("game/vendors").cache(true).get({
            category: category == "ALL" ? "" : category
        });

        if (code == 200) {
            setVendors(data);
        }
    }

    const loadMore = async () => {


        await loadGameList(pageNo + 1, vendor?.id);


    }

    useEffect(() => {

        console.log("category=> init", category, clientType);

        // if (category) {
        //     loadVendors();

        // }
        setHasMore(true);
        setPageNo(0);
        setGameList([]);
        loadVendors();
        // loadMore();

    }, [lang]);

    // console.log("gameList=>", category, clientType, hasMore)

    const PCGameVendorRender = ({ items, vendor, onChange }: any) => {

        if (!items?.length) {
            return null;
        }



        return (

            <Grid columns={12} gap={"5px"}>
                {[{ id: null, name: locale("all") }, ...items]?.map((it: any) => {
                    return (
                        <Grid.Item key={it.id}>

                            <div className={`vendors-item ${it.id == vendor?.id ? "active" : ""}`} onClick={() => {
                                onChange?.(it);
                            }} >
                                {/* 
                                
                                */}
                                {/* {it?.id ? <img src={it.image} style={{ width: 80, height: 20, objectFit:"contain" }} /> : <Ellipsis content={it?.name} />} */}
                                {/* <img src={it.image} style={{ width: "50%",  objectFit:"contain" }} /> */}
                                <Ellipsis content={it?.name} rows={1} />

                            </div>
                        </Grid.Item>

                    )
                })}
            </Grid>


        )
    }


    if (clientType == "PC") {

        return (
            <>
                <div className="vendors-items">
                    <PCGameVendorRender
                        items={vendors}
                        vendor={vendor}
                        onChange={(v: any) => {
                            setVendor(v);
                            setGameList([]);
                            setPageNo(0);
                            setHasMore(true);
                            // loadGameList(1, v?.id);
                        }} />
                </div>

                <div className="pc-games-layout">
                    <PCGameLayout vender={vendor} gameList={gameList} onPlayClick={onPlayClick} />
                </div>
                <InfiniteScroll
                    hasMore={hasMore}
                    loadMore={loadMore}
                    threshold={150}
                />

            </>
        )

    }

    if(vendors?.length == 0){
        return null;
    }

    return (

        <>


            <div className="h5-vendor-items">
                <Space style={{ "--gap": "10px",whiteSpace:"nowrap" }} >



                    {[{ id: null, name: locale("all") }, ...vendors]?.map((it: any, index) => {
                        return (

                            <Button key={index} onClick={() => {
                                setVendor(it);
                                setGameList([]);
                                setPageNo(0);
                                setHasMore(true);
                            }} color={it.id == vendor?.id ? "primary" : "default"} style={{ fontSize: 12 }} size="mini">{it.name}</Button>



                        )
                    })}

                </Space>
            </div>

            <PullToRefresh onRefresh={async () => {

                await loadGameList(1);
            }}

            >
                <div>
                    <H5GameListLayout className="all-game-items" gameList={gameList} onPlayClick={onPlayClick}  />
                    <InfiniteScroll
                        hasMore={hasMore}
                        loadMore={loadMore}
                        threshold={150}
                    />
                </div>



            </PullToRefresh>
        </>
    )
}




type HomeItemsProps = {
    playRecords: any,
    category: any,
    navigate: any,
    locale: any,
    clientType: any,
    sections: any,
    fontSize: any,
    onPlayGame: any
}

const H5GameLayout = ({ gameList, onPlayGame }: any) => {

    return (
        <Space className="section-space" >

            {gameList?.map((game: any, index: number) => {

                return (
                    <div key={index} onClick={() => onPlayGame(game)}>
                        <ImageView
                            src={`${game.imageSquare}`}
                            className="section-item-image"


                        />
                        <div className="section-item-text">
                            <div>
                                <Ellipsis content={game.name} direction="end" />
                            </div>
                            {game.lastOpenTime ? (
                                <>
                                    <div className="date" >
                                        {dayjs(game.lastOpenTime).fromNow()}
                                    </div>
                                </>
                            ) : null}

                        </div>
                    </div>
                )

            })}
        </Space>
    )

}

/**
 * 首页推荐
 * @param param
 * @returns 
 */
const HomeTabLayout: FC<HomeItemsProps> = ({ playRecords, category, navigate, locale, clientType, sections, fontSize, onPlayGame }) => {

    const { login } = useGetLogin();

    const playRecordProps = {
        title: <div className="section-title">
            <img src={require("./recent_play.png")} />
            <span>{locale("recent_play")}</span>
        </div>,
        extra: <a className="card-right-text" onClick={() => {

            navigate("/user/play/records");

        }}>{locale("more")} <RightOutline /> </a>

    }
    return (
        <div className="items-layout">
            {(playRecords?.length && login) ? (

                <>
                    {clientType == "PC" ? (
                        <>
                            <PCGameLayout

                                gameList={playRecords}
                                onPlayClick={onPlayGame}

                                maxSize={8}
                                {...playRecordProps}
                                bodyStyle={{ padding: "20px 20px" }}

                            />
                        </>
                    ) : (
                        <Card
                            className="home-section h5-section section-play-records"
                            bodyClassName="recent-body"
                            {...playRecordProps}
                        >

                            <H5GameLayout gameList={playRecords} onPlayGame={onPlayGame} />

                        </Card>
                    )}

                </>
            ) : null}



            {sections.map((it: any, index: any) => {

                const items: [] = it.games;

                if (clientType == "PC") {

                    return (
                        <PCGameLayout
                            key={index}
                            gameList={items}
                            onPlayClick={onPlayGame}
                            title={
                                <div className="section-title">
                                    <img src={it.image} />
                                    <span>{it.name}</span>
                                </div>
                            }
                            bodyStyle={{ padding: "20px 20px" }}
                        />
                    )
                }


                const gameItems = paginateArray(items, 8);


                return (
                    <div key={index}>
                        <Card
                            className="home-section h5-section"
                            title={
                                <div className="section-title">
                                    <img src={it.image} />
                                    <span>{it.name}</span>
                                </div>
                            }
                            bodyClassName="recent-body"

                        >
                            <Swiper indicator={false} key={index} slideSize={100} >
                                {gameItems.map((games: any[], index) => {


                                    const horizontalItems = games.slice(0, 4);

                                    const verticalItems = games.slice(horizontalItems.length, games.length);
                                    return (

                                        <Swiper.Item key={index}>
                                            <div
                                                className="section-body"
                                            >
                                                <H5GameLayout gameList={horizontalItems} onPlayGame={onPlayGame} />
                                            </div>

                                            <div>
                                                <H5GameListLayout  gameList={verticalItems} onPlayClick={onPlayGame} />
                                            </div>
                                        </Swiper.Item>


                                    )
                                })}
                            </Swiper>



                        </Card>



                    </div>
                )
            })}

        </div>
    )
}



export default (props: any) => {



    const cacheBanners = localStorage.getItem("site-banners");
    const cacheSections = localStorage.getItem("site-sections");
    const cachePlayRecords = localStorage.getItem("user-play-records");

    const locale = useGetLocale();

    const navigate = useNavigate();


    const location = useLocation();

    const params = location.state;




    const [category, setCategory] = useState("Home");

    const [banners, setBanners] = useState(cacheBanners ? JSON.parse(cacheBanners) : []);
    const [sections, setSections] = useState(cacheSections ? JSON.parse(cacheSections) : []);
    const [playRecords, setPlayRecords] = useState(cachePlayRecords ? JSON.parse(cachePlayRecords) : []);
    const [homeLoading, setHomeLoading] = useState(false);
    const [fontSize, setFontSize] = useState(12);
    const [clientType, setClientType] = useState(getClientType());

    const { login, user } = useGetLogin();

    const config = useGetSiteConfig();

    const lang = useGetLang();

    const playGame = usePlayGame();

    const [showAppDownload, setShowAppDownload] = useState(false);


    const loadBanners = async () => {

        const { code, data } = await api("site/banner").cache(true).get({});

        if (code == 200) {
            setBanners(data);
            localStorage.setItem("site-banners", JSON.stringify(data));
        }

    }

    const loadSections = async () => {


        const { code, data } = await api("site/section").cache(true).get({});
        if (code == 200) {
            setSections(data);
            localStorage.setItem("site-sections", JSON.stringify(data));
        }
    }

    const loadPlayRecords = async () => {

        const { code, data } = await api("user/play/records").get({ pageSize: 10 });
        if (code == 200) {
            setPlayRecords(data);
            localStorage.setItem("user-play-records", JSON.stringify(data));
        }
    }


    const reloadPlayRecords = () => {

        loadPlayRecords();

    }


    const loadData = async () => {


        await loadBanners();
        await loadPlayRecords();
        await loadSections();

    }

    const onPlayGame = (game: any) => {

        if (!login) {
            navigate("/login");
            return;
        }
        // navigate("/play/game",{state:{game:game}});
        //打开游戏
        playGame({
            game: game, onClose() {
                reloadPlayRecords();
            },
        });
    }


    const tabs = [
        {
            title: locale("ForYou"), category: "Home", icon: "icon-hotfill", layout: <HomeTabLayout
                playRecords={playRecords}
                category={"Home"}
                sections={sections}
                clientType={clientType}
                fontSize={fontSize}
                onPlayGame={onPlayGame}
                navigate={navigate}
                locale={locale}

            />
        },
        // { title: locale("all"), category: "ALL", layout: <GameList category={"ALL"} onPlayClick={onPlayGame} clientType={clientType} /> },
        {
            title: locale("Slot"), category: "SLOTS", icon: "icon-slot", layout: <GameList
                category={"SLOTS"}
                onPlayClick={onPlayGame}
                clientType={clientType} />
        },
        {
            title: locale("Fish"), category: "FISH", icon: "icon-Fish", layout: <GameList
                category={"FISH"}
                onPlayClick={onPlayGame}
                clientType={clientType} />
        },
        {
            title: locale("Bingo"), category: "BINGO", icon: "icon-bingo", layout: <GameList
                category={"BINGO"}
                onPlayClick={onPlayGame}
                clientType={clientType} />
        },
        {
            title: locale("Live"), category: "LIVE", icon: "icon-livetv", layout: <GameList
                category={"LIVE"}
                onPlayClick={onPlayGame}
                clientType={clientType} />
        },
        {
            title: locale("Poker"), category: "POKER", icon: "icon-poker", layout: <GameList
                category={"POKER"}
                onPlayClick={onPlayGame}
                clientType={clientType} />
        },
        // { title: locale("MINI"), category: "MINI", layout: <GameList category={"MINI"} onPlayClick={onPlayGame} clientType={clientType} /> },
        //  { title: locale("ARCADE"), category: "ARCADE", layout: <GameList category={"ARCADE"} onPlayClick={onPlayGame} clientType={clientType} /> },

    ];


    //  const onPageShow = useOnPageShow();








    useEffect(() => {



        document.title = `${config?.name}`


        setFontSize(getRemUnit());

        if (params?.category) {

            setCategory(params?.category);

            return;
        }


        if (cacheBanners && cacheSections || cachePlayRecords) {
            loadData();
            return;

        }

        setHomeLoading(true);

        loadData().finally(() => {
            setHomeLoading(false);
        });



    }, [login, params, lang]);


    if (homeLoading) {

        return (

            <SiteLoading
                style={{
                    height: window.innerHeight - 100
                }}
            />
        )
    }



    return (

        <div className={`app-home app-${clientType}-home`}>
            {/* <a href="/app" >下载APP</a> */}
            <div style={{ display: (category == "Home" || clientType == "PC") ? "block" : "none" }}>
                <div className="swiper-layout">
                    <Swiper
                        className="root"
                        slideSize={clientType == "PC" ? 33.3333 : 100}
                        autoplay

                    >
                        {banners?.map((bn: any, index: any) => {
                            return (
                                <Swiper.Item key={index}>

                                    <div
                                        className={"banner-content"}

                                        onClick={() => {
                                            if(bn.jumpData){
                                                jumpPage(bn.jumpData,navigate);
                                            }

                                        }}
                                    >
                                        <ImageView src={`${bn.image}`} lazy={false} className="banner-image" />

                                    </div>
                                </Swiper.Item>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
            <div>
                {clientType == "PC" ? (
                    <>
                        <CapsuleTabs activeKey={category}
                            className={`app-home-tabs app-tab-${clientType}`}
                            onChange={key => setCategory(key)}
                        >
                            {tabs.map((v, i) => {
                                return (
                                    <CapsuleTabs.Tab forceRender={false} key={v.category} title={
                                        <div className="tab-title">
                                            <IconFont className="icon" name={v.icon} />
                                            <span>
                                                {v.title}
                                            </span>
                                        </div>
                                    }>

                                        {v.layout}

                                    </CapsuleTabs.Tab>
                                )
                            })}
                        </CapsuleTabs>
                    </>
                ) : (
                    <>
                        <Tabs

                            activeKey={category}


                            className={`app-home-tabs app-tab-${clientType}`}
                            style={{ "--content-padding": "0px" }}
                            onChange={key => {
                                setCategory(key);
                            }}

                        >
                            {tabs.map((v, i) => <Tabs.Tab key={v.category} forceRender={false} title={v.title}>{v.layout}</Tabs.Tab>)}
                        </Tabs>

                    </>
                )}

            </div>

            <ShowNotice onFinish={() => {
                setShowAppDownload(true);
            }} />
            <ShowDownloadApp open={showAppDownload} />


        </div>
    )
}