import { Image, ImageProps, Skeleton } from "antd-mobile"
import "./ImageView.scss";

type ImageViewProps = ImageProps;


export default (props: ImageViewProps) => {



    return (
        <Image lazy {...props}  placeholder={
            <Skeleton animated style={{"--width":"100%","--height":"100%"}}/>   
        } />


    )
}