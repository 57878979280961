import { CheckList, List, Toast } from "antd-mobile"
import { useEffect, useState } from "react"
import { getLocale, useGetLang, useGetLocale, useSetLang } from "../../../public/LangProvier";
import { getUserLanguage } from "../../../utils";
import { useNavigate } from "react-router";
import api from "../../../config/api";

const languageMapping: any = {
    'zh-CN': 'zh',
    'zh-TW': 'hk',
    'zh-HK': 'hk',
    'en': 'en',
    'bn': 'bn',
    'th': 'th',
    'id': 'id',
    'vi': 'vi',
    'es': 'es',
    'pt': 'pt'
};

const langgages = [
    { name: "en", value: "English" },
    { name: "zh", value: "简体中文" },
    // { name: "hk", value: "繁體中文", icon: "🇭🇰" },
    { name: "bn", value: "বাংলা", },
    { name: "th", value: "ภาษาไทย", },
    { name: "id", value: "Indonesia", },
    { name: "vi", value: "Tiếng Việt",},
    // { name: "es", value: "español", icon: "🇲🇽" },
    // { name: "pt", value: "Português", icon: "🇧🇷" }
]

export default ({ onNavigateConfig }: any) => {


    const locale = useGetLocale();

    const [language, setLanguage] = useState<any>(getUserLanguage());

    const setLang = useSetLang();

    const lang = useGetLang();

    const navigate = useNavigate();

    useEffect(() => {

        onNavigateConfig?.({
            title: locale("lang_setting")
        })

    }, [lang]);

    const updateUserLanguage = (language:any)=>{

        api("user/update").post({
            language
        })
    }



    return (
        <div>

            <CheckList

                multiple={false}
                value={[language]}
                
                onChange={values => {

                    if (!values.length) {
                        return;
                    }

                    setLanguage(values[0]);

                    setLang(`${values[0]}`);

                    localStorage.setItem("user-language", `${values[0]}`);

                    updateUserLanguage(values[0]);
                    


                    navigate("/", { replace: true });

                    // Toast.show({
                    //     content: getLocale(""),


                    // });



                }} >
                {langgages.map(lang => {
                    return (
                        <CheckList.Item value={lang.name} key={lang.name}>{lang.value}</CheckList.Item>
                    )
                })}
            </CheckList>



        </div>
    )
}