// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chaport-page {
  width: 100vw;
  height: 100vh;
}
.chaport-page .close {
  width: 40px;
  height: 40px;
  /* background-color: #000; */
  position: fixed;
  z-index: 99999999999;
  top: 12px;
  right: 10px;
  background-color: #fff;
  border-radius: 50%;
}
.chaport-page .close img {
  width: 40px;
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/services/Chaport.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,aAAA;AAAJ;AACI;EACI,WAAA;EACA,YAAA;EACA,4BAAA;EACA,eAAA;EACA,oBAAA;EACA,SAAA;EACA,WAAA;EACA,sBAAA;EACA,kBAAA;AACR;AACI;EACI,WAAA;AACR","sourcesContent":[".chaport-page{\n\n    width: 100vw;\n    height: 100vh;\n    .close{\n        width: 40px;\n        height: 40px;\n        /* background-color: #000; */\n        position: fixed;\n        z-index: 99999999999;\n        top: 12px;\n        right: 10px;\n        background-color: #fff;\n        border-radius: 50%;\n    }\n    .close img{\n        width: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
