import React, { createContext, FC, useContext } from "react";


export type GameProps ={

    game?:any,
    onClose?:any
}|null|undefined



export type PlayGameProps = {

    value?: GameProps,
    setValue: (value?: GameProps) => void
} | null|undefined

const PlayGameContext = createContext<PlayGameProps>(null);



export const usePlayGame = () => {
    const contenxt = useContext(PlayGameContext);
    return (value: GameProps) => {
        contenxt?.setValue(value)
    }
}

type PlayGameProiverProps = {
    value: PlayGameProps,
    children?: React.ReactNode
}

const PlayGameProiver: FC<PlayGameProiverProps> = ({ children, value }) => {
    return (
        <PlayGameContext.Provider value={value}>
            {children}
        </PlayGameContext.Provider>
    )

}


export default PlayGameProiver;