const typeTemplate = '${label} একটি বৈধ ${type} নয়';
const bnBD = {
  locale: 'bn-BD',
  common: {
    confirm: 'নিশ্চিত',
    cancel: 'বাতিল',
    loading: 'লোড হচ্ছে',
    close: 'বন্ধ করুন'
  },
  Calendar: {
    title: 'তারিখ নির্বাচন',
    confirm: 'নিশ্চিত করুন',
    start: 'শুরু',
    end: 'শেষ',
    today: 'আজ',
    markItems: ['সোম', 'মঙ্গল', 'বুধবার', 'বৃহস্পতিবার', 'শুক্রবার', 'শনিবার', 'রবিবার'],
    yearAndMonth: '${year} সাল ${month} মাস'
  },
  Cascader: {
    placeholder: 'দয়া করে নির্বাচন করুন'
  },
  Dialog: {
    ok: 'আমি জানি'
  },
  DatePicker: {
    tillNow: 'এখন পর্যন্ত'
  },
  ErrorBlock: {
    default: {
      title: 'পৃষ্ঠায় কিছু সমস্যা ঘটেছে',
      description: 'পরে চেষ্টা করুন'
    },
    busy: {
      title: 'আগামী পথে যানজট',
      description: 'পুনরায় রিফ্রেশ করুন'
    },
    disconnected: {
      title: 'নেটওয়ার্ক কিছু ব্যস্ত',
      description: 'মদ্যপান করে সহায়তা করুন'
    },
    empty: {
      title: 'আপনার প্রয়োজনীয় কিছু পাওয়া যায়নি',
      description: 'অন্য কিছু খুঁজুন'
    }
  },
  Form: {
    required: 'অবশ্যই প্রয়োজন',
    optional: 'বিকল্প',
    defaultValidateMessages: {
      default: 'ক্ষেত্র যাচাইকরণ ত্রুটি ${label}',
      required: 'দয়া করে ${label} প্রবেশ করুন',
      enum: '${label} এর মধ্যে একটি হতে হবে [${enum}]',
      whitespace: '${label} শূন্যস্থান চরিত্র হতে পারে না',
      date: {
        format: '${label} তারিখের ফরম্যাট অবৈধ',
        parse: '${label} তারিখে রূপান্তরিত করা যায়নি',
        invalid: '${label} একটি অবৈধ তারিখ'
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      },
      string: {
        len: '${label} ${len} টি অক্ষর হতে হবে',
        min: '${label} সর্বনিম্ন ${min} টি অক্ষর',
        max: '${label} সর্বাধিক ${max} টি অক্ষর',
        range: '${label} ${min}-${max} অক্ষরের মধ্যে থাকতে হবে'
      },
      number: {
        len: '${label} এর মান ${len} হতে হবে',
        min: '${label} এর সর্বনিম্ন মান ${min}',
        max: '${label} এর সর্বাধিক মান ${max}',
        range: '${label} এর পরিমাণ ${min}-${max} এর মধ্যে থাকতে হবে'
      },
      array: {
        len: '${label} ${len}টি হতে হবে',
        min: 'সর্বনিম্ন ${min}টি ${label}',
        max: 'সর্বাধিক ${max}টি ${label}',
        range: '${label} সংখ্যা ${min}-${max} এর মধ্যে থাকতে হবে'
      },
      pattern: {
        mismatch: '${label} প্যাটার্নের সাথে মেলে না ${pattern}'
      }
    }
  },
  ImageUploader: {
    uploading: 'আপলোড হচ্ছে...',
    upload: 'আপলোড'
  },
  InfiniteScroll: {
    noMore: 'আর কিছু নেই',
    failedToLoad: 'লোড করতে ব্যর্থ',
    retry: 'পুনরায় লোড করুন'
  },
  Input: {
    clear: 'পরিষ্কার'
  },
  Mask: {
    name: 'পটভূমি আবরণ'
  },
  Modal: {
    ok: 'আমি জানি'
  },
  PasscodeInput: {
    name: 'পাসকোড ইনপুট ক্ষেত্র'
  },
  PullToRefresh: {
    pulling: 'তাজা করতে টানুন',
    canRelease: 'তাজা করতে মুক্ত করুন',
    complete: 'তাজা সফল'
  },
  SearchBar: {
    name: 'অনুসন্ধান বাক্স'
  },
  Slider: {
    name: 'স্লাইডার'
  },
  Stepper: {
    decrease: 'হ্রাস করুন',
    increase: 'বৃদ্ধি করুন'
  },
  Switch: {
    name: 'সুইচ'
  },
  Selector: {
    name: 'নির্বাচক'
  }
};
export default bnBD;