import { useEffect, useState } from "react"
import Loading from "../../../public/Loading";
import { CenterPopup, InfiniteScroll, List, NavBar } from "antd-mobile";
import dayjs from "dayjs";
import api from "../../../config/api";
import { jumpPage } from "../../../utils";
import { useNavigate } from "react-router";
import { CloseOutline, MessageOutline } from "antd-mobile-icons";
import "./index.scss";

export default () => {


    const [notices, setNotices] = useState<any[]>([]);

    const [hasMore, setHasMore] = useState(true);

    const [pageNo, setPageNo] = useState<number>(0);

    const [notice, setNotice] = useState<any>(null);

    const navigate = useNavigate();

    const loadList = async (num: number) => {

        setPageNo(num);

        const { code, data } = await api("notice/list").cache(true).get({
            pageNo: num,
            pageSize: 20
        });

        if (code != 200) {
            setHasMore(false);
        }

        if (code == 200) {
            setNotices(num == 1 ? [
                ...data
            ] : [
                ...notices,
                ...data
            ]);

            setHasMore(data?.length > 0);
        }
    }


    useEffect(() => {



    }, []);





    return (
        <div className="notice">

            <List>
                {notices.map(it => {
                    return (
                        <List.Item
                            key={it.id}
                            arrowIcon={it.banner ? <span></span> : true}
                            className={`${it.banner?"has-banner":""}`}
                            onClick={() => {

                                const jumpData: any = it.jumpData ? JSON.parse(it.jumpData) : { type: "none" };

                                if (jumpData.type != "none") {
                                    jumpPage(it.jumpData, navigate)
                                    return;
                                }
                                if (it.content) {
                                    setNotice({
                                        ...it
                                    });
                                }


                            }}



                            description={

                                <div>
                                    {it?.banner ? (
                                        <div style={{padding:10,paddingLeft:0,paddingRight:0,paddingBottom:0}}>
                                            <img src={it.banner} style={{ width: "100%" }} />
                                        </div>
                                    ) : null}
                                    <div>
                                        {dayjs(it.updateTime).format("YYYY-MM-DD")}
                                    </div>
                                </div>

                            }
                        >
                            {it.title}
                        </List.Item>
                    )
                })}
            </List>
            <InfiniteScroll

                hasMore={hasMore}

                loadMore={async () => {

                    await loadList(pageNo + 1)

                }}

            />

            <CenterPopup
                visible={notice ? true : false}

                className="notice-popup"
                getContainer={document.body}



            >
                {notice ? (
                    <div className="notice-text" >
                        <NavBar className="title" backIcon={null} right={
                            <CloseOutline onClick={() => setNotice(null)} fontSize={24} />
                        }>{notice?.title}</NavBar>
                        <div className="notice-text-content" dangerouslySetInnerHTML={{
                            __html: notice?.content
                        }}></div>

                    </div>

                ) : null}




            </CenterPopup>

        </div>
    )


}