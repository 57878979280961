import { DotLoading, SpinLoading } from "antd-mobile"



const Loading  = () => {


 
        return (
            <div style={{height:"60vh", display: "flex",flex:1, width: "100%", alignItems: "center", justifyContent: "center",position:"relative" }}>
                <SpinLoading color="primary"  />
            </div>
        )
    
}


export default Loading;
