import { useEffect } from "react"
import { eventLog } from "../utils";

export default ()=>{

    const pathname = window.location.pathname;

    useEffect(()=>{
        if(pathname && pathname != "/"){
            eventLog("view",pathname);
        }
       
    },[pathname]);


    return (
        <>
        </>
    )

}