import { FC, useEffect, useState } from "react";
import { useGetSiteConfig } from "../../../public/SiteConfigProvier";
import { useGetLocale } from "../../../public/LangProvier";
import { getRealClientType, getSystem } from "../../../utils";
import { Badge, Button, List, Popup } from "antd-mobile";
import ImageView from "../../../public/ImageView";
import { ArrowDownCircleOutline, DownOutline } from "antd-mobile-icons";

type ShowDownloadAppProps = {

    open: boolean,
}


const ShowDownloadApp: FC<ShowDownloadAppProps> = ({ open }) => {

    const downloadClose = sessionStorage.getItem("download-close");

    const [visable, setVisable] = useState(false);

    const config = useGetSiteConfig();
    const locale = useGetLocale();
    const type = getRealClientType();
    useEffect(() => {

        if (!open) {
            return;
        }


        if (downloadClose) {
            setVisable(false);
            return;
        }

        if (type == "H5") {

            setTimeout(() => {
                setVisable(true);
            }, 2000);

        }



    }, [open]);



    if (type != "H5") {
        return null;
    }


    return (
        <Popup
            visible={visable}
            showCloseButton
            onClose={() => {
                setVisable(false);
                sessionStorage.setItem("download-close", "yes");
            }}

        >

            <List style={{ paddingTop: 40,"--border-bottom":"0px","--border-top":"0px" }}

                

            >
                <List.Item
                    prefix={
                        <ImageView src={config?.siteLogo} style={{ width: 60 }} />
                    }


                >
                    <div>
                        {locale("download_app_text")}
                    </div>

                </List.Item>


            </List>

            <div style={{ padding: 40, paddingBottom: 60 }}>
                <a href={getSystem() == "IOS" ? config?.iosAppUrl : config?.androidAppUrl} target="_blank" download >

                
                        <Button block type="button" color="primary" size="large"  ><ArrowDownCircleOutline/> {locale("click_me")}</Button>
                   
                </a>
            </div>





        </Popup>
    )
}

export default ShowDownloadApp;