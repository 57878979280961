// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-account .adm-list-body {
  border: 1px solid var(--adm-color-border);
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/user/MyAccount.scss"],"names":[],"mappings":"AAEI;EACI,yCAAA;AADR","sourcesContent":[".my-account{\n\n    .adm-list-body{\n        border: 1px solid var(--adm-color-border);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
