import { FC, useEffect, useState } from "react"
import api from "../config/api"
import SiteConfigProiver, { Config } from "../public/SiteConfigProvier";
import Loading from "../public/Loading";
import LangProiver, { getLocale } from "../public/LangProvier";
import { useLocation, useNavigate } from "react-router";
import "dayjs/locale/zh-cn";
// import "dayjs/locale/zh-hk";
import "dayjs/locale/id";
import "dayjs/locale/en";
import "dayjs/locale/bn";
import "dayjs/locale/vi";
import "dayjs/locale/th";
// import "dayjs/locale/pt-br";
// import "dayjs/locale/es";
import VI from "antd-mobile/es/locales/vi-VN";
import ID from "antd-mobile/es/locales/id-ID";
// import PT from "antd-mobile/es/locales/pt-BR";
import TH from "antd-mobile/es/locales/th-TH";
// import ES from "antd-mobile/es/locales/es-ES";
// import zhHK from "antd-mobile/es/locales/zh-HK";
import zhCN from "antd-mobile/es/locales/zh-CN";
import enUs from "antd-mobile/es/locales/en-US";
import bnBD from "../config/locale/bn-BD";
import dayjs from "dayjs";
import PlayGameProiver, { GameProps, PlayGameProps } from "../public/PlayGameProvier";
import { PlayGamePopup } from "../pages/h5/play/Index";
import { Button, ConfigProvider, ErrorBlock } from "antd-mobile";
import { RedoOutline } from "antd-mobile-icons";
import { encodeBase64, eventLog, updateInviteFrom } from "../utils";
import PageChangeEventLog from "../public/PageChangeEventLog";
import { useSearchParams } from "react-router-dom";



const dayjsLocale: any = {
    "zh": "zh-cn",
    "en": "en",
    "bn": "bn",
    "vi": "vi",
    "id": "id",
    "pt": "pt-br",
    "th": "th",
    "es": "es",
    "hk": "zh-hk"
};


const locale: any = {
    "zh": zhCN,
    "en": enUs,
    "bn": bnBD,
    "vi": VI,
    "id": ID,
    // "pt": PT,
    "th": TH,
    // "es": ES,
    // "hk": zhHK
};

const defaultLanguage = localStorage.getItem("user-language");

dayjs.locale(defaultLanguage ? dayjsLocale[`${defaultLanguage}`] : dayjsLocale["en"]);



const MainLayout = (props: any) => {

    const siteConfig = localStorage.getItem("site-config");

    const [config, setConfig] = useState<Config>(siteConfig ? JSON.parse(siteConfig) : null);

    const [language, setLanguage] = useState<any>(defaultLanguage);

    const navigate = useNavigate();

    const location = useLocation();

    const [gameData, setGameData] = useState<GameProps | undefined | null>(null);

    const [hasError, setHasError] = useState<any>(null);

    const [params] = useSearchParams();


    const initUserForm = ()=>{

        const fromUserId = params.get("fromUserId");
        const fromChannelId = params.get("fromChannelId");

        if(fromUserId || fromChannelId){
            const from:any = {
                userId:fromUserId,
                channelId:fromChannelId
            }
            updateInviteFrom(from);
        }

    }


    const loadConfig = () => {



        api("site/config").get({}).then(ret => {

            if (ret.code != 200) {

                setHasError(ret?.msg);
                return;
            }

            if (ret.code == 200) {
                setConfig(ret.data);
                localStorage.setItem("site-config", JSON.stringify(ret.data));
                //没有设置语言
                if (!language) {
                    setLanguage(ret.data?.locale);
                    dayjs.locale(dayjsLocale[`${ret.data?.locale}`]);
                    localStorage.setItem("user-language", ret.data?.locale)
                }

                //打开界面
                eventLog("open");

            }

        }).catch(error => {
            // console.log("error=>",error);
            setHasError(error?.message);
        }).finally(() => {


        })
    }


    useEffect(() => {

        initUserForm();

        loadConfig();

    }, []);


    // console.log("has error=>", hasError)

    if (hasError) {

        return (
            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100vw",height:"70vh"}}>

                <ConfigProvider locale={language ? locale[`${language}`] : locale["en"]}>
                    <ErrorBlock

                        status="disconnected"
                        title={`${hasError}`}
                        style={{minWidth:300}}
                        description={
                            <div style={{ padding: 20 }}>
                                <Button color="primary" block onClick={()=>{
                                    window.location.reload();
                                }}><RedoOutline/> {getLocale("Reload")}</Button>
                            </div>
                        }

                    />
                </ConfigProvider>
            </div>

        )
    }



    if (config == null) {

        return <Loading />;
    }



    const RouteComponent = props.routeComponent;


    return (
        <ConfigProvider locale={language ? locale[`${language}`] : locale["en"]}>
            <SiteConfigProiver
                value={config}
            >

                <LangProiver value={{
                    locale: language,
                    setLocale(locale) {
                        setLanguage(locale);
                        localStorage.setItem("user-language", locale);
                        dayjs.locale(dayjsLocale[locale]);
                    },
                }} >
                    <PlayGameProiver value={{
                        value: gameData,
                        setValue(value) {
                            setGameData(value)
                        },
                    }}>

                        {RouteComponent ? <RouteComponent /> : props.children}
                        <PlayGamePopup
                            game={gameData?.game}
                            onClose={() => {
                                gameData?.onClose?.();
                                setGameData(null);
                            }}

                        />

                    </PlayGameProiver>
                </LangProiver>
            </SiteConfigProiver>
            <PageChangeEventLog/>
        </ConfigProvider>
    )


}



export default MainLayout;