import React, { createContext, FC, useContext, useState } from "react";

import zhLocale from "../config/locale/zh-locale";
import enLocale from "../config/locale/en-locale";
import bnLocale from "../config/locale/bn-locale";
import thLocale  from "../config/locale/th-locale";
import idLocale from "../config/locale/id-locale";
import viLocale from "../config/locale/vi-locale";
import ptLocale from "../config/locale/pt-locale";
import esLocale from "../config/locale/es-locale";
import hkLocale from "../config/locale/hk-locale";
import { getUserLanguage } from "../utils";


const localeMap: any = {
    "zh": zhLocale,
    "en": enLocale,
    "bn": bnLocale,
    "vi":viLocale,
    "th":thLocale,
    "id":idLocale,
    "pt":ptLocale,
    "es":esLocale,
    "hk":hkLocale
}

type LangProps = {
    locale?: string,
    setLocale: (locale: string) => void
} | null

const LangContext = createContext<LangProps>(null);



export const getLocale = (name:string,value?:any)=>{

    const lang  =   getUserLanguage();

    const localeValues: any = {
        ...localeMap[`${lang}`]
    }

    return getValue(localeValues,name,value);
}

function getValue (localeValues:any,name: string, value?: any) {

    const template = localeValues[`${name}`];

    if(!template){
        return name;
    }

    const data = {
        ...value
    };

    // 模板替换
    return template.replace(/\$\{(.*?)\}/g, (match:any, key:any) => data[key]);

}

export const useGetLocale = () => {

    const contenxt = useContext(LangContext);

    let locale = contenxt?.locale;

    const localeValues: any = {
        ...localeMap[`${locale}`]
    }




    return (name: string, value?: any)=>{

        return getValue(localeValues,name,value);
     
    }

}

export const useSetLang = () => {
    const contenxt = useContext(LangContext);
    return (locale: string) => {
        contenxt?.setLocale(locale);


    }
}

export const useGetLang = ()=>{
    const contenxt = useContext(LangContext);
    return contenxt?.locale;
}

type LangProiverProps = {
    // value: LangProps,
    value:LangProps
    children?: React.ReactNode,
    // onSetLang:(lang:string)=>void
}

const LangProiver: FC<LangProiverProps> = ({ children,value }) => {


    return (
        <LangContext.Provider value={value}>
            {children}
        </LangContext.Provider>
    )

}


export default LangProiver;