import { FC, forwardRef, useEffect, useImperativeHandle, useState } from "react"
import SelectPopup, { SelectPopupProp } from "./SelectPopup"
import api from "../../../config/api";
import { Image } from "antd-mobile";
import { useGetLocale } from "../../../public/LangProvier";

type WithdrawWaySelectPopupProps = {

    value?: any,
    onChange?: any,



} | SelectPopupProp

const WithdrawWaySelectPopup: FC<WithdrawWaySelectPopupProps> = forwardRef<any, WithdrawWaySelectPopupProps>((props: SelectPopupProp, ref) => {


    // console.log("ref=>", ref);

    const [items, setItems] = useState<any>([]);

    const locale = useGetLocale();



    useEffect(() => {

        api("withdraw/way/list").get({}).then(ret => {
            if (ret.code == 200) {
                setItems([
                    ...ret.data,
                ]);
            }
        })
    }, [])




    return (
        <SelectPopup
            {...props}
            propRef={ref}
            items={items}
            title={locale("pls_select")}

            renderItem={(item: any, index: number) => {

                return {
                    children: item.shortName,
                    prefix: <img src={item.image} style={{ borderRadius: 15 }}  width={30} height={30} />,
                    description:item?.name,
                    arrowIcon:<span></span>,


                }
            }}

        >
            {props.children}
        </SelectPopup>
    )

});

export default WithdrawWaySelectPopup;