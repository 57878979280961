import React, { createContext, FC, useContext } from "react";
import { NavigateOptions, useLocation, useNavigate } from "react-router";

type onFunc = ()=>void|null|undefined;

export type PageProps = {
    
    path:string,
    view:any,
    onShow?:onFunc,
    onHide?:onFunc,
    visible:Boolean,
    onBack?:onFunc

}



export type AppProps = {
    
    pages:PageProps[],
    onSetPages?:(pages?:PageProps[])=>void,
    

}|null

const AppContext = createContext<AppProps>({pages:[]});



export const useOnPageShow = ()=>{
    
    const contenxt = useContext(AppContext);

    const pathname = window.location.pathname;

    const page =  contenxt?.pages?.find(p=>p.path == pathname);



    return (onShow:onFunc)=>{

        if(page){
            page.onShow = onShow;
        }
        contenxt?.onSetPages?.(contenxt.pages);
    }
}

export const useGetLastPagePath = ()=>{

    const contenxt = useContext(AppContext);

   

    return ()=>{

        const pages = contenxt?.pages?contenxt.pages:[];

        console.info("pages=>",pages);

        // const page =  contenxt?.pages?.find(p=>p.path == pathname);
        const lastPage = pages.length > 1 ? pages[pages.length - 2] : null;

        console.info("lastPage=>",lastPage);

        return lastPage?.path;

    }

}


export const useGetCurrentPage = (pathname:string)=>{

    const contenxt = useContext(AppContext);

    const pages = contenxt?.pages?contenxt.pages:[];

    

    return ()=>{

        const page  =  pages.find(x=>x.path == pathname);

        return page;

    }

}


export const navigateBack = ()=>{

    // const  useGetLastPagePath();
}




type AppProiverProps = {
    value:AppProps,
    children?:React.ReactNode
}

const AppProiver:FC<AppProiverProps> =  ({children,value})=>{
        return (
            <AppContext.Provider value={value}>
                {children}
            </AppContext.Provider>
        )

}


export default AppProiver;