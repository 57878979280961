import api from "../config/api";

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { Navigate, NavigateFunction } from "react-router-dom"
import { getCurrencySymbol } from "../public/CurrencySymbol";

const  UAParser = require("ua-parser-js");

const ua = new UAParser();


type ClientType = "TELEGRAM_MIN_APP" | "H5" | "PC" | null;

export const win: any = window;



function isTelegramMinapp() {
    const userAgent = navigator.userAgent;
    return /Telegram/i.test(userAgent);
}
export function isTelegram() {

    if (typeof win.Telegram !== 'undefined' && typeof win.Telegram.WebApp !== 'undefined' && win.Telegram.WebApp.initData) {
        return true;
    }

    return false;
}

export function setTgHeaderColor(color: any) {

    win?.Telegram?.WebApp?.setHeaderColor?.(color)
}

export function setTgBackgroundColor(color: any) {

    win?.Telegram?.WebApp?.setBackgroundColor(color)
}

export function setBottomBarColor(color: any) {

    win?.Telegram?.WebApp?.setBottomBarColor?.(color)
}

export function expand() {

    win?.Telegram?.WebApp?.expand?.();

}

export function tgMiniAppReady() {

    win?.Telegram?.WebApp?.ready?.();

}


export function getTelegramWebApp() {

    return win?.Telegram?.WebApp;

}

export function disableVerticalSwipes() {

    win?.Telegram?.WebApp?.disableVerticalSwipes();

}

export function showBackBotton(fn: any) {

    win?.Telegram?.WebApp?.BackButton?.show();
    win?.Telegram?.WebApp?.onEvent('backButtonClicked', fn);

}

export function hideBackBotton(fn?: any) {


    win?.Telegram?.WebApp?.BackButton?.hide();
    win?.Telegram?.WebApp?.offEvent("backButtonClicked", fn);

}

export const getRemUnit = () => {
    const baseSize = 12;
    let clientWidth = document.documentElement.clientWidth;


    if (isPcClient()) {
        if (clientWidth >= 375) {
            clientWidth = 375;
        }
    }


    const newFontSize = (clientWidth / 375) * baseSize;  // 基于设计稿375px的比例进行缩放

    return newFontSize;
}


export const getClientWidth = () => {


    let clientWidth = document.documentElement.clientWidth;

    return clientWidth;
}




function isMobileH5() {
    const userAgent = navigator.userAgent;

    // 检查是否是 iPhone、iPad、Android 或其他移动设备
    return /iPhone|iPad|iPod|Android|Mobile/i.test(userAgent);
}

//
export function getClientType(): ClientType {

    if (isTelegramMinapp() || isTelegram()) {
        return "TELEGRAM_MIN_APP";
    }
    return isMobileH5() ? "H5" : "PC";

    // return "TELEGRAM_MIN_APP";

}

export function isPcClient() {

    return getClientType() === "PC";
}

export function formatNumberView(num: any) {
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M'; // 百万级别
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K'; // 千级别
    } else {
        return num.toString(); // 小于 1000 直接返回
    }
}

const languageMapping: any = {
    'zh-CN': 'zh',
    'zh-TW': 'hk',
    'zh-HK': 'hk',
    'en': 'en',
    'bn': 'bn',
    'th': 'th',
    'id': 'id',
    'vi': 'vi',
    'es': 'es',
    'pt': 'pt'
};

export function getUserLanguage() {

    const defaultLanguage = localStorage.getItem("user-language");

    if (defaultLanguage) {
        return defaultLanguage;
    }

    const lang = navigator.language;

    // const baseLang =  // 只取前缀部分
    if (languageMapping[lang]) {
        return languageMapping[lang];
    }

    return "en";
}

export const AndroidClient = win?.AndroidClient;



export function openFullscreen() {

    if (AndroidClient) {
        AndroidClient.openFullscreen?.();
    }

    // const elem: any = document.documentElement; // 全屏显示整个文档
    // if (elem.requestFullscreen) {
    //     elem.requestFullscreen();
    // } else if (elem.webkitRequestFullscreen) { // Safari
    //     elem.webkitRequestFullscreen();
    // } else if (elem.msRequestFullscreen) { // IE11
    //     elem.msRequestFullscreen();
    // }
}

export function closeFullscreen(){
    if (AndroidClient) {
        AndroidClient.closeFullscreen?.();
    }
}


const currencyMap: any = {
    "PHP": {
        currency: "PHP",
        locale: "fil-PH",
    },
    "IDR(K)": {
        currency: "IDR",
        locale: "id-ID",
    },
    "VND(K)": {
        currency: "VND",
        locale: "vi-VN"
    },
    "THB": {
        currency: "THB",
        locale: "th-TH",
    },
    "BDT": {
        currency: "BDT",
        locale: "bn-BD",
    },
    "INR": {
        currency: "INR",
        locale: "hi-IN",
    },
    "BRL": {
        currency: "BRL",
        locale: "pt-BR",
    },
    "PKR": {
        currency: "PKR",
        locale: "en-PK",
    },
    "MXN": {
        currency: "PHP",
        locale: "fil-PH",
    },
    "USDT": {
        currency: "USD",
        locale: "en_US"
    }
}

export const showCurrency = (currency: any) => {
    return `${currency}`.replace("(K)", "")
}

export const formatAmount = (currency: any, amount: any) => {

    if (!currency) {
        return new Intl.NumberFormat(undefined, {
            style: "decimal",
            minimumFractionDigits: amount == 0 ? 2 : (amount % 1 === 0 ? 0 : 2), // 整数时为 0，小数时为 2
            maximumFractionDigits: 2
        }).format(amount)
    }

    if (currency == "BDT") {
        const value = new Intl.NumberFormat(undefined, {
            style: "decimal",
            currency: currency,
            minimumFractionDigits: amount == 0 ? 2: (amount % 1 === 0 ? 0 : 2), // 整数时为 0，小数时为 2
            maximumFractionDigits: 2
        }).format(amount);

        return `${getCurrencySymbol(currency)}${value}`
    }

    const data = currencyMap[currency];
    if (!data) {
        return amount;
    }


    return new Intl.NumberFormat(data.locale, {
        style: "currency",
        currency: data.currency,
        minimumFractionDigits: amount == 0 ? 2 : (amount % 1 === 0 ? 0 : 2), // 整数时为 0，小数时为 2
        maximumFractionDigits: 2
    }).format(amount)

}

export const getRealClientType = () => {


    const clientType = getClientType();

    if (clientType == "PC" ) {

        return clientType;
    }

    // console.log("clientType=>{}",clientType)

    if (AndroidClient) {
        return "Android";
    }

    if (win.navigator.standalone) {
        return "IOS";
    }

    return clientType;

}

export const getDeviceId = async () => {

    if (AndroidClient) {
        return AndroidClient.getDeviceId?.();
    }
    const clientId = localStorage.getItem("client-id");
    if (clientId) {
        return clientId;
    }

    const agent = await FingerprintJS.load();

    const ret = await agent.get();

    const visitorId = ret.visitorId;

    localStorage.setItem("client-id", visitorId);

    return visitorId;

}

export function encodeBase64(str: string) {
    const utf8Bytes: any = new TextEncoder().encode(str); // 转为 UTF-8 字节数组
    const base64String = btoa(String.fromCharCode(...utf8Bytes));
    return base64String;
}

export const eventLog = async (type: any, value?: any) => {

    const deviceId = await getDeviceId();

    const result = ua.getResult();

    const data = JSON.stringify({
        event: type,
        v: `${value ? value : ""}`,
        deviceId: `${deviceId}`,
        referrer: `${document.referrer}`,
        clientType: getRealClientType(),
        lang: window.navigator.language,
        browser:result?.browser?.name,
        os:result?.os?.name,
        model:result?.device?.model


    });

    api("event/log").post({
        data: `${encodeBase64(data)}`
    }).then(ret => {

    })


}

export function getSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // 检查 iOS
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'IOS';
    }

    // 检查 Android
    if (/android/i.test(userAgent)) {
        return 'ANDROID';
    }

    return 'unknown';
}


export function paginateArray(array: any[], pageSize: any) {
    let result = [];
    for (let i = 0; i < array.length; i += pageSize) {
        result.push(array.slice(i, i + pageSize));
    }
    return result;
}

export function jumpPage(jumpData: any, navigate: NavigateFunction) {

    const { type, data }: any = jumpData ? JSON.parse(jumpData) : {};


    if (type == "deposit") {
        navigate("/deposit");
        return;
    }

    if (type == "lottery") {
        navigate(`/activity/lottery?id=${data?.id}`)
    }
    if (type == "notice") {
        navigate(`/notice/dettail?id=${data?.id}`)
    }


    if (type == "page" && data) {
        navigate(data);
        return;
    }

}

export function stringToHex(str: string) {
    let hex = '';
    for (let i = 0; i < str.length; i++) {
        hex += str.charCodeAt(i).toString(16).padStart(2, '0');
    }
    return hex;
}


export function hexToString(hex: string) {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
    return str;
}

type InviteForm = {
    userId?:any,
    channelId?:any
}

export function updateInviteFrom(from:InviteForm){

    localStorage.setItem("invite-from",JSON.stringify(from));
}

export function getInviteFrom():InviteForm {

    const data = localStorage.getItem("invite-from");

    console.log("invite-from",data);

    return data?JSON.parse(data):{};
}
