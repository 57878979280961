// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.withdarw-page {
  padding: 0px;
}
.withdarw-page .balance {
  padding: 12px;
  font-size: 16px;
  padding-top: 0px;
}
.withdraw-tip {
  color: var(--adm-color-danger);
  padding: 16px;
  padding-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/h5/withdraw/index.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;AAAJ;AAEI;EACI,aAAA;EACA,eAAA;EACA,gBAAA;AAAR;AAWA;EACI,8BAAA;EACA,aAAA;EACA,gBAAA;AATJ","sourcesContent":[".withdarw-page{\n\n    padding: 0px;\n    \n    .balance{\n        padding: 12px;\n        font-size: 16px;\n        padding-top: 0px;\n        // padding-bottom: 10px;\n\n        .balance-item{\n            // margin-bottom: 10px;\n        }\n    }\n\n   \n}\n\n.withdraw-tip{\n    color: var(--adm-color-danger);\n    padding: 16px;\n    padding-top: 0px;\n  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
