import { useState, FC, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { Button, CheckList, ErrorBlock, Picker, Popup, Radio, Form, Input, Space, Toast, DatePicker, PickerProps, NavBar, CenterPopup, CenterPopupProps, List } from "antd-mobile";
import { CheckCircleFill, AddOutline, CloseOutline, LeftOutline } from 'antd-mobile-icons'
import { useNavigate } from "react-router";
import { useGetLocale } from "../../../public/LangProvier";

import "./select-popup.scss"
import { getClientType } from "../../../utils";
import { time } from "console";


// type OnChangeFunc = (value: any) => void;


export type SelectPopupProp = {

    items?: any[],
    renderItem?: any,
    children?: any,
    value?: any,
    onChange?: any,
    propRef?: any,
    title?: any,
    onClose?: any,
    onNoData?: any

};


export type SelectPopupRef = {
    open?: () => void,
    close?: () => void
}



const SelectPopup: FC<SelectPopupProp> = (props) => {



    const clientType = getClientType();

    const [visable, setVisable] = useState(false);

    const { children, value, items, renderItem, onChange } = props;

    const [currentIndex, setCurrentIndex] = useState<any>(null);



    useImperativeHandle(props.propRef, () => ({

        open: () => {

            setVisable(true);
        },
        close: () => {

            setVisable(false)
        }

    }));

    const PopupContent = () => {

      


        return (
            <>
                <div>
                    <NavBar backIcon={null} back={props.title} right={
                        <div onClick={() => {
                            setVisable(false)
                        }}>
                            <CloseOutline style={{ fontSize: 24 }} />
                        </div>
                    } ></NavBar>
                </div>

                <div className="body">

                    {items?.length ? (<List>

                        {items?.map((item: any, index: number) => {
                            const itemProps = renderItem(item, index)
                            return (
                                <List.Item

                                    {...itemProps}
                                    key={index}
                                    className={`item ${currentIndex == index ? "active" : ""}`}
                                    onClick={() => {
                                        const selected = items[index];
                                        onChange?.(selected);
                                        setCurrentIndex(index);
                                        setVisable(false);
                                    }}>
                                    {itemProps?.children}
                                </List.Item>
                            )
                        })}

                    </List>):(
                        <>
                            {props.onNoData?.()}
                        </>
                    )}



                </div>

            </>
        )
    }


    return (
        <div>
            <div>
                {children?.(value)}
            </div>

            {clientType == "PC" ? (
                <CenterPopup
                    {...props}
                    className={`select-prop-modal select-prop-modal-${clientType}`}
                    destroyOnClose
                    getContainer={document.body}
                    visible={visable}

                >

                    <PopupContent />

                </CenterPopup>
            ) : (
                <Popup
                    {...props}
                    className={`select-prop-modal select-prop-modal-${clientType}`}
                    destroyOnClose
                    getContainer={document.body}
                    visible={visable}
                >
                    <PopupContent />

                </Popup>
            )}


        </div>
    )


};

export default SelectPopup;
