import { FC, useEffect, useState } from "react";
import { NavigateConfig } from "../../../layout/NavbarLayout";
import { useGetLocale } from "../../../public/LangProvier";
import { List, Image, Button, Space, Tag, InfiniteScroll } from "antd-mobile";
import api from "../../../config/api";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate } from "react-router";
import { usePlayGame } from "../../../public/PlayGameProvier";



dayjs.extend(relativeTime);



type PlayRecordsProps = {
    onNavigateConfig?: (config: NavigateConfig) => void
}

const PlayRecords: FC<PlayRecordsProps> = ({ onNavigateConfig }) => {




    const [gameList, setGameList] = useState<any>([]);

    const [hasMore, setHasMore] = useState(true);

    const [pageNo, setPageNo] = useState<number>(0);
    const playGame = usePlayGame();

    const locale = useGetLocale();

    const navigate = useNavigate();

    const loadPlayRecords = async (num: number) => {

        setPageNo(num);

        const { code, data } = await api("user/play/records").get({
            pageNo: num,
            pageSize: 20
        });

        if (code != 200) {
            setHasMore(false);
        }

        if (code == 200) {
            setGameList(num == 1 ? [
                ...data
            ] : [
                ...gameList,
                ...data
            ]);

            setHasMore(data?.length > 0);
        }
    }

    useEffect(() => {



        onNavigateConfig?.({
            title: locale("recent_play")
        })

    }, []);

    return (

        <div>
            <List>
                {gameList?.map((it: any, index: number) => {

                    return (
                        <List.Item

                            key={index}

                            arrowIcon={false}

                            prefix={
                                <Image
                                    src={`${it.imageSquare}`}

                                    className="section-item-image"
                                    lazy={false}
                                    loading="eager"
                                    width={40}
                                    height={40}
                                    style={{ borderRadius: 5 }}


                                />
                            }
                            extra={

                                <div>
                                    <Button size="mini" fill='outline' color="primary">{locale("open")}</Button>
                                </div>
                            }
                            description={
                                <div>
                                    <Space>
                                        <Tag color="default" fill='outline'>
                                            {dayjs(it.lastOpenTime).fromNow()}
                                        </Tag>
                                    </Space>
                                </div>
                            }

                            onClick={() => {
                                playGame({
                                    game: it, onClose: () => {

                                    }
                                });
                            }}

                        >
                            {it.name}
                        </List.Item>
                    )
                })}

            </List>
            <InfiniteScroll

                hasMore={hasMore}

                loadMore={async () => {

                    await loadPlayRecords(pageNo + 1)

                }}

            />
        </div>



    )

}

export default PlayRecords;