import { FC, useEffect, useState } from "react"
import { useLocation } from "react-router";
import AppProiver, { PageProps } from "../public/AppProvier";
import H5TabbarLayout from "./H5TabbarLayout";
import { getRoutes } from "../config/routes";
import EmptyLayout from "./EmptyLayout";
import { motion, AnimatePresence, useAnimation } from 'framer-motion';
import "./AppLayout.scss";
import PlayGameProiver from "../public/PlayGameProvier";
import { PlayGamePopup } from "../pages/h5/play/Index";


const routes = getRoutes();

type ViewProps = {
    pathname?: string,
    visable?: any
}

const AppView: FC<ViewProps> = ({ pathname, visable }) => {

    const route = routes.find(x => x.path == pathname);

    console.debug("route=>", route);

    if (!route) {
        return null;
    }

    const Layout = route.layout ? route.layout : EmptyLayout;
    const Component = route.component ? route.component : EmptyLayout;

    if (!visable) {
        // return null;
    }


    if (pathname == "/") {

        return (
            <H5TabbarLayout>
                <Component />
            </H5TabbarLayout>
        )
    }

    return (
        <>
            <Layout routeComponent={Component} />
        </>

    )

}

export default (props: any) => {



    const [pages, setPages] = useState<PageProps[]>([]);

    const { pathname } = useLocation();




    useEffect(() => {


        const lastPage = pages.length > 0 ? pages[pages.length - 1] : null;

        if (lastPage) {

            lastPage.visible = false;
            lastPage.onHide?.();
        }


        const index = pages.findIndex(page => page.path == pathname);

        if (index == -1) {

            pages.push({
                path: pathname,
                view: null,
                visible: true
            });

        } else {
            const page = pages[index];
            page.visible = true;
            page.onShow?.();

        }

        const newIndex = pages.findIndex(page => page.path == pathname);

        const newPages = pages.filter((x, i) => i <= newIndex);



        setPages([
            ...newPages
        ]);


    }, [pathname]);


    return (

      


            <div className="app-layout">

                <AppProiver value={{

                    pages: pages,
                    onSetPages(pages) {
                        if (pages) {
                            setPages([...pages]);
                        }
                    },

                }}>

                    

                    {pages.map((page, index) => {


                        return page.visible ? (
                            <div key={page.path}

                                className="app-page"
                                style={page.visible ? { display: "block", opacity: 1, zIndex: 10 } : { display: "none", opacity: 0, zIndex: 0 }}

                            >
                                <AppView visable={page.visible} pathname={page.path} />
                            </div>
                        ) : null
                    })}



                </AppProiver>


            </div>

        
    )


}
