import { DotLoading, SpinLoading } from "antd-mobile"
import { useGetSiteConfig } from "./SiteConfigProvier";
import "./site-loading.scss";
import React, { FC } from "react";




const SiteLoading:FC<React.HTMLAttributes<any>> = (props: any) => {


    const config = useGetSiteConfig();


    return (

        <div style={{
            height: "100vh",
            display: "flex",
            flex: "1",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            ...props.style
        }
        }>
            {config ? (
                <div className="site-loading">
                    <div className="image-container">
                        <img src={config?.siteLogo} className="loaded-image" />
                        <DotLoading color="primary" style={{ fontSize: "24px" }} />
                    </div>
                </div>
            ) : null}
        </div>
    )

}


export default SiteLoading;
