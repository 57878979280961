import { Component, FC, FunctionComponent } from "react";
import { Route, RouteProps, Routes } from "react-router-dom";
import login from "../pages/h5/login";
import register from "../pages/h5/register";
import Index from "../pages/index";
import EmptyLayout from "../layout/EmptyLayout";
import UserProfile from "../pages/h5/user/UserProfile";
import Deposit from "../pages/h5/deposit/Index";
import PlayRecords from "../pages/h5/user/PlayRecords";
import NavbarLayout from "../layout/NavbarLayout";
import Result from "../pages/h5/deposit/Result";
import { getClientType } from "../utils";
import H5TabbarLayout from "../layout/H5TabbarLayout";
import Withdraw from "../pages/h5/withdraw/Index";
import AddWithdarwAccount from "../pages/h5/user/AddWithdarwAccount";
import LangSetting from "../pages/h5/user/LangSetting";
import AppIndex from "../pages/h5/app/Index";
import LaunchIndex from "../pages/h5/launch/Index";
import MyOrders, { DepositOrder, WithdrawOrder } from "../pages/h5/user/MyOrders";
import PlayGamePage from "../pages/h5/play/Index";
import MyAccount from "../pages/h5/user/MyAccount";
import Lottery from "../pages/h5/lottery/Index";
import NoticeIndex from "../pages/h5/notice/Index";
import AuthLoginCallback from "../pages/h5/login/AuthLoginCallback";
import CustomerService from "../pages/h5/services/CustomerService";
import Chaport from "../pages/h5/services/Chaport";





export type PageRoute = {

    path: string,
    layout?: any | Component | FunctionComponent,
    component?: any | Component | FunctionComponent,
    // children?: PageRoute[]

}



type RouteViewProps = {
    route: PageRoute,
    key: string,
    index: number,

}




export const renderRoutes = (routes: PageRoute[]) => {

    // const children: PageRoute[] | undefined = route.children;

    return routes?.map((route: PageRoute, index: number) => {

        const Layout: any = route.layout ? route.layout : EmptyLayout;
        const routeComponent: any = route.component;

        return (
            <Route key={index} path={route.path} element={
                <>
                    <Layout routeComponent={routeComponent} >
                        <Routes>
                            {/* {route.children ? renderRoutes(route.children) : null} */}
                        </Routes>
                    </Layout>
                </>
            }>

            </Route>
        )
    });


}



export function getRoutes() {

    const clientType = getClientType();

    const isPC = clientType == "PC";

    const route: PageRoute[] = [

        { path: "/", component: isPC ? EmptyLayout : H5TabbarLayout },
        { path: "/home", component: isPC ? EmptyLayout : H5TabbarLayout },
        { path: "/me", component: UserProfile, layout: isPC ? NavbarLayout : null },
        { path: "/deposit", component: Deposit, layout: NavbarLayout },
        { path: "/user/play/records", component: PlayRecords, layout: NavbarLayout },
        { path: "/login", layout: NavbarLayout, component: login, },
        { path: "/register", layout: NavbarLayout, component: register },
        { path: "/deposit/result", component: Result },
        { path: "/withdraw", component: Withdraw, layout: NavbarLayout },
        { path: "/user/account/add", component: AddWithdarwAccount, layout: NavbarLayout },
        { path: "/user/setting/language", component: LangSetting, layout: NavbarLayout },
        { path: "/user/orders", component: MyOrders, layout: NavbarLayout },
        { path: "/pc/index", component: Index },
        { path: "/play/game", component: PlayGamePage },
        { path: "/withdraw/account", component: MyAccount, layout: NavbarLayout },
        { path: "/app", component: AppIndex, layout: NavbarLayout },
        { path: "/launch", component: LaunchIndex },
        { path: "/activity/lottery", component: Lottery, layout: NavbarLayout },
        { path: "/notice/list", component: NoticeIndex, layout: NavbarLayout },
        { path: "/login/google/callback", component: AuthLoginCallback },
        { path: "/login/facebook/callback", component: AuthLoginCallback },
        { path: "/customer/service", component: CustomerService, layout: NavbarLayout },
        { path: "/chaport", component: Chaport, layout: null },
        { path: "/deposit/record", component: DepositOrder, layout: NavbarLayout },
        { path: "/withdraw/record", component: WithdrawOrder, layout: NavbarLayout }
    ]

    return route;
}

