// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-layout {
  width: 100vw;
  height: 100vh;
  position: relative;
}
.app-layout .app-page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 1;
}`, "",{"version":3,"sources":["webpack://./src/layout/AppLayout.scss"],"names":[],"mappings":"AAAA;EAEI,YAAA;EACA,aAAA;EACA,kBAAA;AAAJ;AAGI;EACI,kBAAA;EACA,YAAA;EACA,aAAA;EACA,QAAA;EACA,SAAA;EACA,UAAA;AADR","sourcesContent":[".app-layout{\n\n    width: 100vw;\n    height: 100vh;\n    position: relative;\n\n\n    .app-page{\n        position: absolute;\n        width: 100vw;\n        height: 100vh;\n        top: 0px;\n        left: 0px;\n        z-index: 1;\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
