export default {
    "login.failed": "Đăng nhập thất bại",
    "login.user_disabled": "Người dùng đã bị vô hiệu hóa",
    "login.account_error": "Tên người dùng hoặc mật khẩu không đúng",
    "login.lock_error": "Đăng nhập thất bại, tài khoản bị khóa trong 30 phút",
    "reg.fail": "Đăng ký thất bại",
    "reg.user_name_exists": "Tên người dùng đã tồn tại",
    "text.login": "Đăng nhập",
    "text.reg": "Đăng ký",
    "text.username": "Tên người dùng",
    "text.password": "Mật khẩu",
    "text.password2": "Xác nhận mật khẩu",
    "text.passwod2_err": "Mật khẩu mới bạn nhập không khớp!",
    "recent_play": "Chơi gần đây",
    "open_game_fail": "Mở trò chơi thất bại",
    "text.ok": "OK",
    "deposit_way": "Phương thức nạp tiền",
    "deposit": "Nạp tiền",
    "amount": "Số tiền",
    "confirm_deposit": "Xác nhận nạp tiền",
    "input_deposit_amount": "Số tiền nạp phải nằm trong khoảng ${start} - ${end}",
    "home": "Trang chủ",
    "task": "Nhiệm vụ",
    "message": "Tin nhắn",
    "mine": "Của tôi",
    "close": "Đóng",
    "refresh": "Làm mới",
    "logout": "Đăng xuất",
    "withdraw": "Rút tiền",
    "balance": "Số dư",
    "deposit_order": "Lệnh nạp tiền",
    "withdraw_order": "Lệnh rút tiền",
    "game_records": "Lịch sử chơi game",
    "all": "Tất cả",
    "withdraw_account": "Tài khoản rút tiền",
    "pay_amount": "Thanh toán ${amount}",
    "pay_success": "Thanh toán thành công",
    "pay_success_info": "Đơn hàng của bạn đã được thanh toán thành công",
    "my_order": "Đơn hàng của tôi",
    "apply_withdraw": "Yêu cầu rút tiền",
    "confirm_withdraw": "Xác nhận rút tiền",
    "real_name": "Tên thật",
    "input_real_name": "Vui lòng nhập tên của bạn",
    "text.confirm": "Xác nhận",
    "text.cancel": "Hủy",
    "add_withdraw_account": "Thêm tài khoản",
    "pls_select": "Vui lòng chọn",
    "back": "Trở về",
    "account_type": "Loại tài khoản",
    "account_num": "Số tài khoản",
    "pls_input": "Vui lòng nhập ${value}",
    "fiat_currency": "Tiền pháp định",
    "input_amount": "Vui lòng nhập số tiền",
    "logout_text": "Bạn có chắc chắn muốn đăng xuất không?",
    "tip": "Mẹo",
    "user_center": "Trung tâm người dùng",
    "close_game": "Xác nhận thoát?",
    "receive_account": "Tài khoản nhận",
    "insufficient_balance": "Số dư không đủ",
    "apply_success": "Đăng ký thành công",
    "processing_withdraw_order": "Đang xử lý lệnh rút tiền, vui lòng thử lại sau.",
    "create_order_fail": "Tạo đơn hàng thất bại, vui lòng thử lại",
    "add_success": "Thêm thành công",
    "delete_success": "Xóa thành công",
    "add": "Thêm",
    "pls_input_pwd": "Vui lòng nhập mật khẩu",
    "password_err": "Sai mật khẩu",
    "lang_setting": "Cài đặt ngôn ngữ",
    "Slot": "Máy đánh bạc",
    "Fish": "Câu cá",
    "Bingo": "Bingo",
    "Live": "Trực tiếp",
    "Poker": "Poker",
    "ForYou": "Dành cho bạn",
    "MINI": "Mini",
    "ARCADE": "Trò chơi điện tử",
    "more":"Thêm",
    "copy_success": "Sao chép thành công",
    "wait_pay": "Đang chờ thanh toán",
    "paid": "Đã thanh toán",
    "cancelled": "Đã hủy",
    "unpaid": "Chưa thanh toán",
    "open": "Mở",
    "my_account": "Tài khoản của tôi",
    "processing": "Đang xử lý",
    "fail": "Thất bại",
    "success": "Thành công",
    "my_boxs": "Hộp của tôi",
    "game_rules": "QUY TẮC TRÒ CHƠI",
    "Me": "Tôi",
    "download_app_text": "Chúng tôi muốn thêm vào màn hình chính của bạn để nhận các cập nhật khuyến mãi và cược miễn phí mới nhất.",
    "click_install": "Nhấn vào Cài đặt",
    "Home": "Trang chủ",
    "i_know": "Tôi đã biết",
    "click_me": "Nhấn vào tôi để cài đặt",
    "Mine": "Của tôi",
    "lottery": "Quay số may mắn",
    "no_account": "Chưa có tài khoản?",
    "other_login_way": "Cách khác",
    "login.access_denied": "Truy cập bị từ chối",
    "services": "Dịch vụ",
    "Promotions": "Khuyến mãi",
    "lottery_ending": "Sự kiện quay số này đã kết thúc.",
    "lottery_not_start": "Sự kiện quay số chưa bắt đầu.",
    "no_more_draw_count": "Không còn lượt quay số.",
    "pls_login": "Chưa đăng nhập. Vui lòng đăng nhập trước.",
    "MyBox": "Hộp quà của tôi",
    "SHARE": "Chia sẻ",
    "my_draw_count": "Số lần quay số có sẵn",
    "lottery_not_match": "Không phù hợp với quy tắc của sự kiện này.",
    "not_win": "Hãy cố gắng thêm",
    "open_rule": "Điều kiện mở",
    "my_keys": "Chìa khóa của tôi",
    "box_not_exist": "Hộp kho báu không tồn tại.",
    "open_box_fail": "Không đáp ứng điều kiện mở.",
    "win_box": "Nhận thành công ${amount}. Phần thưởng đã được gửi tự động vào tài khoản của bạn.",
    "share_twitter": "Chia sẻ trên X/Twitter",
    "share_telegram": "Chia sẻ trên Telegram",
    "share_facebook": "Chia sẻ trên Facebook",
    "share_link": "Sao chép liên kết và gửi cho bạn bè",
    "share_whatsapp": "Chia sẻ trên WhatsApp",
    "no_data": "Không có dữ liệu",
    "username_tip1": "Định dạng tài khoản: 4-16 ký tự là chữ cái tiếng Anh hoặc số",
    "password_tip1": "Định dạng mật khẩu: 4-16 ký tự",
    "confirm_delete": "Bạn có chắc chắn muốn xóa không?",
    "delete_fail": "Xóa thất bại",
    "delete_success": "Xóa thành công",
    "is_close": "Xác nhận đóng?",
    "deposit_record": "Ghi nhận nạp tiền",
    "withdraw_record": "Ghi nhận rút tiền",
    "withdraw_tip": "Điều kiện rút tiền chưa được đáp ứng. Tổng số tiền đặt cược của bạn cần vượt quá ${needAmount} để có thể rút tiền.",
    "withdraw_amount": "Số tiền có thể rút",
    "input_withdraw_amount": "Số tiền rút phải nằm trong khoảng ${start} - ${end}.",
    "bet_amount_all": "Tổng số tiền đặt cược hiện tại của bạn là ${betAmountAll}.",
    "play_again": "Hãy tiếp tục chơi.",
    "login_password": "Mật khẩu đăng nhập."
}