import { CapsuleTabs, Card, Grid, InfiniteScroll, List, PullToRefresh, Tabs, Toast } from "antd-mobile"
import { useGetLocale } from "../../../public/LangProvier"
import { NavigateConfig } from "../../../layout/NavbarLayout"
import { useEffect, useState } from "react"
import api from "../../../config/api"
import IconFont from "../../../public/iconfont/Index"
import "./MyOrders.scss";
import dayjs from "dayjs"
import localizedFormat from "dayjs/plugin/localizedFormat";
import { formatAmount } from "../../../utils"
import { RightOutline } from "antd-mobile-icons"
import { useSearchParams } from "react-router-dom"

dayjs.extend(localizedFormat);

type MyOrdersProps = {
    onNavigateConfig?: (config: NavigateConfig) => void
}


const OrderList = ({ type }: any) => {
    const [orders, setOrders] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);
    const [pageNo, setPageNo] = useState<number>(0);
    const [status, setOrderStatus] = useState<any>("");
    const pageSize = 15;
    const locale = useGetLocale();

    const loadOrdersList = async (num: number, type?: any, orderStatus?: any) => {



        const { code, data } = await api("user/orders").get({
            pageNo: num,
            pageSize: pageSize,
            type,
            orderStatus
        });

        if (code != 200) {
            setHasMore(false);
        }

        if (code == 200) {
            setOrders(num == 1 ? [
                ...data
            ] : [
                ...orders,
                ...data
            ]);
            setHasMore(() => data.length == pageSize);
            setPageNo(num);
        }
    }


    const loadMore = async () => {

        await loadOrdersList(pageNo + 1, type, status);

    }

    useEffect(() => {

        setOrderStatus("");
        setOrders([]);
        setPageNo(0);
        setHasMore(true);

    }, [type]);

    const DepositStatusItems = [
        { title: locale("all"), value: "" },
        { title: locale("unpaid"), value: 0 },
        { title: locale("paid"), value: 1 },
        { title: locale("cancelled"), value: 6 },
    ]


    const WithdarwStatusItems: any[] = [
        { title: locale("all"), value: "" },
        { title: locale("processing"), value: 0 },
        { title: locale("success"), value: 1 },
        { title: locale("fail"), value: 2 },
        // { title: locale("cancelled"), value: 3 },
    ];

    const statusItems: any[] = type == 0 ? DepositStatusItems : WithdarwStatusItems;

    const DepositItem = ({ order, index }: any) => {

        const statusText: any = {
            "0": locale("unpaid"),
            "1": locale("paid"),
            "6": locale("cancelled")
        }

        return (
            <List.Item

                key={index}
                title={<div className="title" onClick={() => {
                    navigator.clipboard.writeText(order.id).then(ret => {

                        Toast.show(locale("copy_success"));
                    })
                }}>
                    <span>{order.id}</span>
                    <IconFont className="title-icon" name="icon-copy" />
                </div>}
                arrowIcon={<RightOutline />}
                prefix={
                    <img
                        src={`${order.way?.image}`}

                        width={30}
                        height={30}
                        style={{ borderRadius: 5 }}
                    />
                }
                extra={

                    <div className="extra">
                        <div className="amount">
                            {formatAmount(order.currency, order.payAmount)}
                        </div>
                        <div className={`status-text status-text-${order.status}`}>
                            {statusText[`${order.status}`]}
                        </div>

                    </div>
                }
                description={<span className="date">{dayjs(order.createTime).format("LLLL")}</span>}


            />

        )
    }

    const WithdarwItem = ({ order, index }: any) => {

        const statusText: any = {
            "0": locale("processing"),
            "1": locale("success"),
            "2": locale("fail"),
            "3": locale("cancelled")
        }

        return (
            <List.Item

                key={index}
                title={<div className="title" onClick={() => {
                    navigator.clipboard.writeText(order.id).then(ret => {

                        Toast.show(locale("copy_success"));
                    })
                }}>
                    <span>{order.id}</span>
                    <IconFont className="title-icon" name="icon-copy" />
                </div>}
                arrowIcon={<RightOutline />}
                extra={

                    <div className="extra">
                        <div className="amount">
                            {formatAmount(order.currency, order.payAmount)}
                        </div>
                        <div className={`status-text status-text-${order.status}`}>
                            {statusText[`${order.status}`]}
                        </div>

                    </div>
                }
                description={<span className="date">{dayjs(order.createTime).format("LLLL")}</span>}


            >
                <div className="withdraw-info">
                    <div>
                        <div className="way">
                            <img
                                src={`${order.way?.image}`}

                                width={20}
                                height={20}
                                style={{ borderRadius: 5 }}
                            />
                            <div className="way-name">
                                <div>{order.way?.shortName}</div>
                                {order?.way?.name ? (<div>{order?.way?.name}</div>) : null}
                            </div>
                        </div>
                    </div>
                    <div>{locale("real_name")}: {order?.account?.name}</div>
                    <div>{locale("account_num")}: {order?.account?.account}</div>
                </div>
            </List.Item>

        )
    }

    return (
        <div>
            <div className="order-status-items">
                <Grid columns={statusItems.length}>
                    {statusItems.map((it: any, index: number) => {
                        return (
                            <Grid.Item key={index} onClick={() => {
                                setOrderStatus(it?.value);
                                setHasMore(true);
                                setOrders([]);
                                setPageNo(0)
                                // loadOrdersList(1, type, it?.value);
                            }}>
                                <div className={`item ${status === it?.value ? "item-active" : ""}`}>
                                    {it.title}
                                </div>
                            </Grid.Item>
                        )
                    })}

                </Grid>
            </div>
            <PullToRefresh onRefresh={async () => {

                setPageNo(0);

                await loadOrdersList(1, type, status);
            }}>


                {orders?.length ? (
                    <List className="order-items" mode="card">

                        {orders?.map((order: any, index: number) => {

                            return type == 0 ? <DepositItem order={order} key={index} index={index} /> : <WithdarwItem order={order} key={index} index={index} />;
                        })}

                    </List>
                ) : null}


                <InfiniteScroll
                    hasMore={hasMore}
                    loadMore={loadMore}
                // threshold={150}

                />
            </PullToRefresh>
        </div>

    )

}


export default ({ onNavigateConfig }: MyOrdersProps) => {

    const locale = useGetLocale();

    const [search] = useSearchParams();

    const active = search.get("active");


    const [key, setKey] = useState(active ? active : "deposit");




    useEffect(() => {

        onNavigateConfig?.({
            title: locale("my_order")
        });

    }, []);

    return (
        <div className="user-my-orders">
            <CapsuleTabs activeKey={key} onChange={setKey}>
                <CapsuleTabs.Tab title={locale("deposit")} key={"deposit"} >
                    <OrderList type={0} />
                </CapsuleTabs.Tab>
                <CapsuleTabs.Tab title={locale("withdraw")} key={"withdraw"} >
                    <OrderList type={1} />
                </CapsuleTabs.Tab>
            </CapsuleTabs>
        </div>
    )
}

export const DepositOrder = ({ onNavigateConfig }: MyOrdersProps) => {

    const locale = useGetLocale();




    useEffect(() => {

        onNavigateConfig?.({
            title: locale("deposit_record")
        });

    }, []);


    return (
        <div className="user-my-orders">
            <OrderList type={0} />
        </div>
    )

}

export const WithdrawOrder = ({ onNavigateConfig }: MyOrdersProps) => {

    const locale = useGetLocale();




    useEffect(() => {

        onNavigateConfig?.({
            title: locale("withdraw_record")
        });

    }, []);


    return (
        <div className="user-my-orders">
            <OrderList type={1} />
        </div>
    )

}