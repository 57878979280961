import React, { FC, useEffect, useRef, useState } from 'react';
import { Input, Button, Space, Toast, Grid, Radio, Divider, Image, Card, Ellipsis, Popup, Result, Badge } from 'antd-mobile';
import { useGetLocale } from '../../../public/LangProvier';

import api from '../../../config/api';
import Loading from '../../../public/Loading';
import { useGetSiteConfig } from '../../../public/SiteConfigProvier';
import CurrencySymbol from '../../../public/CurrencySymbol';
import { useGetLogin } from '../../../public/LoginProvier';
import { useGetLoginRegisterOpen, useSetLoginRegisterOpen } from '../../../public/LoginRegisterOpenProvier';
import SiteLoading from '../../../public/SiteLoading';
import { formatAmount, getClientType } from '../../../utils';
import { useRefreshUserBalance } from '../../../public/UserBalanceProvier';
import { useNavigate } from 'react-router';
import { PayPagePopup } from "./Pay";
import "./index.scss";
import ImageView from '../../../public/ImageView';
import { NavigateConfig } from '../../../layout/NavbarLayout';
import { FileOutline } from 'antd-mobile-icons';
import IconFont from '../../../public/iconfont/Index';


type DeposotProps = {
    onNavigateConfig?:(config:NavigateConfig)=>void
}



export default ({ onNavigateConfig }: DeposotProps) => {

    const depositData = localStorage.getItem("deposit-ways");
    const [amount, setAmount] = useState('');
    const [payWays, setPayWays] = useState<any>(depositData ? JSON.parse(depositData) : null);
    const [payWay, setPayWay] = useState<any>(null);
    const config = useGetSiteConfig();
    const [btnLoading, setBtnLoading] = useState(false);
    const [payData, setPayData] = useState<any>(null);
    const login = useGetLogin();
    const locale = useGetLocale();
    const loginOpen = useSetLoginRegisterOpen();
    const clientType = getClientType();
    const refreshUserBalance = useRefreshUserBalance();
    const navigate = useNavigate();

    const loadPayWayList = () => {

        api("payment/way/list").cache(true).get({}).then(ret => {
            if (ret.code == 200) {
                setPayWays(ret.data);
                if (ret.data?.length > 0) {
                    setPayWay(ret.data?.[0])
                }
                localStorage.setItem("deposit-ways", JSON.stringify(ret.data));
            }
        });


    }

    const handleRecharge = () => {

        if (!login.login) {

            loginOpen({ open: true, type: "Login" })
            return;

        }


        if (!amount || amount < payWay?.startAmount || amount > payWay?.endAmount) {
            Toast.show({
                content: locale("input_deposit_amount", { start: payWay?.startAmount, end: payWay?.endAmount }),
                duration: 1500,
            });
            return;
        }

        setBtnLoading(true);

        api("payment/deposit").post({
            wayId: payWay?.id,
            amount: amount,
            activityId: payWay?.activityId,
            successUrl: `${config?.siteUrl}/deposit/result`
        }).then(ret => {
            if (ret.code == 200) {
                setPayData({
                    ...ret.data,
                    way: payWay
                });
            }

        }).finally(() => {

            setBtnLoading(false);
        })


    };

    const handleSelectAmount = (selectedAmount: any) => {
        setAmount(selectedAmount);
    };

    const getUsdtAmount = (amount: any, way: any) => {

        return (parseFloat(amount) / parseFloat(way?.usdtRate)).toFixed(2);

    }

    useEffect(() => {

        onNavigateConfig?.({
            title: locale("deposit"),
            navBarProps:{
                right:(
                    <Space>
                         <IconFont name='icon-Recharge' style={{ fontSize: 24 }} onClick={()=>navigate("/deposit/record")} />
                         <IconFont name='icon-customer-service' style={{ fontSize: 24 }} onClick={()=>navigate("/customer/service")} />   
                    </Space>
                   
                )
            }
            
        });

        if (!login.login) {
            navigate("/login");
            return;
        }



        if (payWays != null) {
            if (payWays?.length) {
                setPayWay(payWays[0]);
            }
        }



        loadPayWayList();

    }, [login.login]);



    if (payWays == null) {

        return (
            <Loading />
        )
    }



    const getGiveAmount = (value: string, rate: any) => {

        const v = parseFloat(value);
        const r = parseFloat(rate) * 0.01;
        const a = v * r;

        return formatAmount(null, a);

    }

    const give = payWay?.activityAmounts?.find?.((x: any) => (amount >= x.startAmount && amount < x.endAmount));


    return (
        <div style={{}} className='deposit-page'>
            <Card

            >
                <Grid columns={2} gap={"10px"} style={{ marginBottom: "12px" }}>
                    {payWays?.map((way: any) => {

                        const maxRate = way.activityAmounts?.length ? way.activityAmounts?.map((x: any) => x.giveRate).reduce((a: number, b: number) => Math.max(a, b)) : 0;

                        return (
                            <Grid.Item key={way.id} className='pay-way-item' onClick={() => {
                                setPayWay({ ...way });
                                setAmount(way?.startAmount);
                            }}>
                                <div

                                    className={`bg ${payWay?.id == way.id ? "active" : ""}`}

                                >

                                    <div>
                                        <ImageView className='img' src={way.image} />
                                    </div>
                                    <div className='text'>
                                        {way.name}
                                    </div>

                                    {maxRate ? (
                                        <div className='give'>
                                            {`+${maxRate}%`}
                                        </div>
                                    ) : null}




                                </div>
                            </Grid.Item>
                        )
                    })}

                </Grid>

                <Divider>
                    {payWay?.name}
                </Divider>

                <Grid columns={3} gap={"12px"}>
                    {payWay?.moneys?.map((value: any) => {

                        const act = payWay?.activityAmounts?.find?.((x: any) => (value >= x.startAmount && value < x.endAmount));


                        return (
                            <div className={`amount-item ${amount == value ? "amount-item-active" : ""}`} key={value} onClick={() => handleSelectAmount(value)}>

                                <span>
                                    {formatAmount(null, value)}
                                </span>

                                {act ? (
                                    <div className='give-amount'>+{getGiveAmount(value, act.giveRate)}</div>
                                ) : null}


                            </div>
                        )
                    })}
                </Grid>
            </Card>
            <Divider>
                {locale("input_deposit_amount", { start: payWay?.startAmount, end: payWay?.endAmount })}
            </Divider>
            <Card>
                <div className='amount'>

                    <div className='amount-input'>
                        <div className='symbol'>
                            <CurrencySymbol currency={config?.currency} />
                        </div>
                        <Input
                            placeholder={locale("input_deposit_amount", { start: payWay?.startAmount, end: payWay?.endAmount })}
                            value={amount}
                            onChange={(value) => {
                                console.log("amount=>", value)
                                if (/^[1-9]\d*$/.test(value)) {
                                    const amountValue: any = parseInt(value);
                                    setAmount(amountValue);
                                } else {
                                    setAmount(value);
                                }
                            }}
                            type="number"
                        />
                        {give ? (
                            <div className='give'>
                                +{getGiveAmount(amount,give.giveRate)}
                            </div>
                        ):null}
                    </div>
                </div>


                <div className='btn'>
                    <Button loading={btnLoading} color="primary" size="large" onClick={handleRecharge} block>
                        {amount ? locale("pay_amount", { amount: payWay?.usdtRate ? `${getUsdtAmount(amount, payWay)} USDT` : formatAmount(config?.currency, amount) }) : locale("confirm_deposit")}
                    </Button>
                </div>
                <div className='activity-remark' dangerouslySetInnerHTML={{
                    __html:payWay?.activityRemark ? payWay?.activityRemark : ""
                }}>
                   
                </div>
            </Card>

            <PayPagePopup
                payData={payData}
                onClose={() => {
                    setPayData(null);
                    refreshUserBalance();
                }} onPaySuccess={() => {
                    setPayData(null);
                    refreshUserBalance();
                }} />
        </div>
    );
};
