import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, HashRouter, ScrollRestoration, Route, useLocation } from 'react-router-dom';
import { getRoutes, PageRoute, renderRoutes } from './config/routes';

import { getClientType, setTgHeaderColor, setTgBackgroundColor, setBottomBarColor, expand, disableVerticalSwipes, isPcClient, tgMiniAppReady, win, hexToString, getRealClientType } from "./utils/index";

import { AnimatePresence } from 'framer-motion';
import MainLayout from './layout/MainLayout';
import AppLayout from './layout/AppLayout';
import PCAppLayout from './layout/PCAppLayout';
import SiteLayout from './layout/SiteLayout';


const routes: any = [];


function RouterApp() {
  const routes = getRoutes();
  const location = useLocation();

  const isPC = isPcClient();

  return (


    <>
      {isPC ? (
        <PCAppLayout>
          <Routes>
            {renderRoutes(routes)}
          </Routes>
        </PCAppLayout>
      ) : (
        <AppLayout>
          <Routes>
            {renderRoutes(routes)}
          </Routes>
        </AppLayout>
      )}
    </>

  )
}

function App() {



  useEffect(() => {



    // alert(getRealClientType());

    document.documentElement.setAttribute(
      'data-prefers-color-scheme',
      'dark'
    )


    if (getClientType() == "TELEGRAM_MIN_APP") {

      setTgHeaderColor("#1a1a1a");
      setTgBackgroundColor("#1a1a1a");
      setBottomBarColor("#1a1a1a");
      disableVerticalSwipes();

      expand();

      tgMiniAppReady();

      const startParam = win.Telegram?.WebApp?.initDataUnsafe?.start_param;

      if(startParam){
         
        if(`${startParam}`.startsWith("d-")){
            var params = new URLSearchParams(hexToString( `${startParam}`.replace("d-","")));
            const page = params.get("page");
            console.log("page=>",page);
            if(page && window.location.pathname == "/"){
              window.location.replace(page);
              return;
            }

        }

      }

    


    }



  }, []);




  return (
    <BrowserRouter>
      <MainLayout>
        <SiteLayout>
          <RouterApp />
        </SiteLayout>
      </MainLayout>
    </BrowserRouter>

  );
}

export default App;
