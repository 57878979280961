import React, { createContext, FC, useContext } from "react";

export type Config = {
   name:string,
   logo:string,
   siteUrl:string,
   siteLogo:string,
   currency:string,
   locale:string,
   iosAppUrl?:string,
   androidAppUrl?:string,
   otherLoginWays?:any[],
   adConfigs?:any[],
   recaptcha?:{open?:boolean,clientId?:string}
}|null


const SiteConfigContext = createContext<Config>(null);

export const useGetSiteConfig = ()=>{

    const contenxt = useContext(SiteConfigContext);

    return contenxt;

}

// export const useSetSiteConfig = ()=>{
//     const contenxt = useContext(SiteConfigContext);
//     return (values:any|Config)=>{
//      contenxt?.setConfig(values);
//     }
// }

type SiteConfigProiverProps = {
    value:Config,
    children?:React.ReactNode
}

const SiteConfigProiver:FC<SiteConfigProiverProps> =  ({children,value})=>{
        return (
            <SiteConfigContext.Provider value={value}>
                {children}
            </SiteConfigContext.Provider>
        )

}


export default SiteConfigProiver;