export default {
    "login.failed": "Falha no login",
    "login.user_disabled": "Usuário desativado",
    "login.account_error": "Nome de usuário ou senha incorretos",
    "login.lock_error": "Falha no login, conta bloqueada por 30 minutos",
    "reg.fail": "Falha no registro",
    "reg.user_name_exists": "Nome de usuário já existe",
    "text.login": "Entrar",
    "text.reg": "Registrar",
    "text.username": "Nome de usuário",
    "text.password": "Senha",
    "text.password2": "Confirmar senha",
    "text.passwod2_err": "As novas senhas que você inseriu não coincidem!",
    "recent_play": "Jogos Recentes",
    "open_game_fail": "Falha ao abrir o jogo",
    "text.ok": "OK",
    "deposit_way": "Método de Depósito",
    "deposit": "Depósito",
    "amount": "Quantia",
    "confirm_deposit": "Confirmar depósito",
    "input_deposit_amount": "O valor do depósito deve estar entre ${start} - ${end}",
    "home": "Início",
    "task": "Tarefa",
    "message": "Mensagem",
    "mine": "Meu",
    "close": "Fechar",
    "refresh": "Atualizar",
    "logout": "Sair",
    "withdraw": "Retirada",
    "balance": "Saldo",
    "deposit_order": "Pedido de Depósito",
    "withdraw_order": "Pedido de Retirada",
    "game_records": "Registros de Jogos",
    "all": "Todos",
    "withdraw_account": "Conta de Retirada",
    "pay_amount": "Pagar ${amount}",
    "pay_success": "Pagamento bem-sucedido",
    "pay_success_info": "Seu pedido foi pago com sucesso",
    "my_order": "Meus Pedidos",
    "apply_withdraw": "Solicitação de Retirada",
    "confirm_withdraw": "Confirmação de Retirada",
    "real_name": "Nome",
    "input_real_name": "Por favor, insira seu nome",
    "text.confirm": "Confirmar",
    "text.cancel": "Cancelar",
    "add_withdraw_account": "Adicionar Conta",
    "pls_select": "Por favor, selecione",
    "back": "Voltar",
    "account_type": "Tipo de Conta",
    "account_num": "Número da Conta",
    "pls_input": "Por favor, insira ${value}",
    "fiat_currency": "Moeda Fiat",
    "input_amount": "Por favor, insira a quantia",
    "logout_text": "Tem certeza de que deseja sair?",
    "tip": "Dica",
    "user_center": "Centro do Usuário",
    "close_game": "Confirmar saída?",
    "receive_account": "Conta receptora",
    "insufficient_balance": "Saldo insuficiente",
    "apply_success": "Aplicação bem-sucedida",
    "processing_withdraw_order": "Há um pedido de retirada em processamento, tente novamente mais tarde.",
    "create_order_fail": "Falha ao criar o pedido, tente novamente",
    "add_success": "Adicionado com sucesso",
    "delete_success": "Excluído com sucesso",
    "add": "Adicionar",
    "pls_input_pwd": "Por favor, insira a senha",
    "password_err": "Senha incorreta",
    "lang_setting": "Configurações de idioma",
    "Slot": "Caça-níqueis",
    "Fish": "Pesca",
    "Bingo": "Bingo",
    "Live": "Ao vivo",
    "Poker": "Pôquer",
    "ForYou": "Para Você",
    "MINI": "Mini",
    "ARCADE": "Arcade",
    "more":"Mais",
    
}