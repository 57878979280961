import { useLocation, useNavigate } from "react-router"
import Home from "../pages/h5/home"
import H5TabbarLayout from "./H5TabbarLayout"
import NavbarLayout from "./NavbarLayout";
import { Popup } from "antd-mobile";
import { FC, useEffect, useState } from "react";
import { useGetSiteConfig } from "../public/SiteConfigProvier";
import EmptyLayout from "./EmptyLayout";
import { getRoutes } from "../config/routes";


const routes = getRoutes();

type ViewProps = {
    pathname?: string
}

const AppView:FC<ViewProps> = ({ pathname }) => {

    const route = routes.find(x => x.path == pathname);

    console.log("route=>",route);

    if (!route) {
        return null;
    }

    const Layout = route.layout ? route.layout :EmptyLayout;
    const Component = route.component ? route.component : EmptyLayout;

    return (
        <>
           <Layout routeComponent={Component}/>
        </>

    )

}


export default (props: any) => {

    const location = useLocation();

    const path = location.pathname;

    const [visible, setVisable] = useState(false);

    const config = useGetSiteConfig();



    useEffect(() => {

        // console.log("path=>",path);

        setVisable(!["/","/home"].includes(path)) ;

        if(path == "/"){
            document.title = `${config?.name}`
        }

    }, [path]);

    // console.log("children=>", props.children);

    if(["/activity/lottery","/login/google/callback","/login/facebook/callback","/chaport"].includes(path)){

        return  <AppView pathname={path}/>
    }


    return (



        <>
            <H5TabbarLayout/>

            
            <Popup
                visible={visible}
                className={`${path == "/play/game"?"":"app-pc-modal"} `}
                destroyOnClose
                // position="right"
            
                onClose={() => {
                    setVisable(false);
                }}
            >
                {visible ? <AppView pathname={path}/> : null}
            </Popup>
        </>


    )

}