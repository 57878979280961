import React, { FC, useEffect, useRef, useState } from 'react';
import { Input, Button, Space, Toast, Grid, Radio, Divider, Image, Card, Ellipsis, Popup, Result, NavBar } from 'antd-mobile';
import { useGetLocale } from '../../../public/LangProvier';

import api from '../../../config/api';
import SiteLoading from '../../../public/SiteLoading';
import "./index.scss";
import { getClientType } from '../../../utils';
import { CloseOutline } from 'antd-mobile-icons';


type PayPagePopupProps = {
    payData?: any,
    onClose?: any,
    onPaySuccess?: any
}



export const PayPagePopup: FC<PayPagePopupProps> = ({ payData, onClose, onPaySuccess }) => {

    const [loading, setLoading] = useState(true);

    const [orderStatus, setOrderStatus] = useState(0);

    const locale = useGetLocale();
    const timer = useRef<any>(null);

    const clientType = getClientType();


    const checkOrderStatus = (orderNo: any) => {


        api("payment/order/status").get({ orderNo: orderNo }).then(ret => {

            if (ret.code == 200) {
                setOrderStatus(ret.data?.status);
                if (ret.data?.status == 1) {
                    if (timer.current) {
                        clearTimeout(timer.current);
                    }
                    return;
                }
                timer.current = setTimeout(() => checkOrderStatus(payData?.orderNo), 3000);

            }

        }).catch(() => {
            timer.current = setTimeout(() => checkOrderStatus(payData?.orderNo), 3000);
        });
    }


    useEffect(() => {

        setLoading(true);
        setOrderStatus(0);
        if (payData) {
            checkOrderStatus(payData?.orderNo);
        }

        return () => {

            if (timer.current) {
                clearTimeout(timer.current);
            }

        }


    }, [payData]);


    const isPc = clientType == "PC";


    return (
        <Popup


            className={`pay-popup pay-popup-${clientType}`}
            visible={payData ? true : false}
            destroyOnClose={true}
            showCloseButton={false}
            onClose={() => { 
                onClose?.();

            }}
        >
            {payData ? (
                <>
                    <NavBar
                        backIcon={null}
                        right={
                            <div onClick={() => {
                                onClose?.();
                            }}>
                                <CloseOutline fontSize={24} />
                            </div>
                        }
                        back={payData?.way?.name}
                    >

                    </NavBar>
                    <div className='pay-page'>

                        {orderStatus == 1 ? (
                            <div>
                                <Card>
                                    <div>
                                        <Result
                                            status='success'
                                            title={locale("pay_success")}
                                            description={locale("pay_success_info")}
                                        />
                                    </div>
                                    <div>
                                        <Button onClick={() => {
                                            onPaySuccess?.();
                                        }} block color="primary" size="large" >{locale("text.ok")}</Button>
                                    </div>
                                </Card>
                            </div>
                        ) : (
                            <>
                                {loading ? (
                                    <SiteLoading />
                                ) : null}

                                <iframe src={payData?.payUrl} className={`pay-page-iframe pay-page-iframe-${clientType} `} style={{ display: loading ? "none" : "block" }} onLoad={(e) => {
                                    setLoading(false);
                                    try {
                                        console.log("iframe=>", e.currentTarget?.contentWindow?.location.href);

                                    } catch (error) {
                                        console.log("error=>", error);
                                    }
                                }} />
                            </>
                        )}


                    </div>
                </>
            ) : null}

        </Popup>
    )
}