import { useEffect, useState } from "react"
import api from "../../../config/api";
import { List } from "antd-mobile";
import { useGetLogin } from "../../../public/LoginProvier";
import { isPcClient } from "../../../utils";
import { useNavigate } from "react-router";
import { useGetLocale } from "../../../public/LangProvier";

export default ({ onNavigateConfig }: any) => {

    const cache = localStorage.getItem("customer-service-cache");

    const [services, setServices] = useState(cache ? JSON.parse(cache) : []);

    const locale = useGetLocale();

    const { login, user } = useGetLogin();

    const navigate = useNavigate();

    useEffect(() => {

        onNavigateConfig?.({
            title: locale("services")
        });


        api("site/customer-service").cache(true).get({}).then(ret => {
            if (ret.code == 200) {
                setServices(ret.data);
                localStorage.setItem("customer-service-cache", JSON.stringify(ret.data));
            }
        });

    }, []);

    const isPC = isPcClient();


    return (

        <div style={{ padding: 12 }}>

            <List>
                {services.map((it: any) => {
                    return (
                        <List.Item key={it.id}
                            prefix={
                                <img src={it.image} style={{ width: 40 }} />
                            }
                            description={it.workTime}
                            onClick={() => {

                                if (it.type == "chaport") {
                                    const username = login ? `${user?.username} (ID:${user?.id})` : ""
                                    const url = `/chaport?appId=${it.appId}&username=${username}`;

                                    if (isPC) {
                                    
                                        const width = 420;
                                        const height = 600;
                                        const screenWidth = window.screen.width;
                                        const screenHeight = window.screen.height;
                                        // 计算窗口左上角的 x 和 y 坐标
                                        const left = (screenWidth - width) / 2;
                                        const top = (screenHeight - height) / 2;
                                        window.open(url, 'popup', `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=yes,resizable=yes`);
         
                                        navigate("/",{replace:true});
                                        return;

                                    }
                                     navigate(url);
                                    return;
                                }

                                if(it.type == "other"){
                                    if(isPC){
                                      window.open(it.linkUrl,"_blank");
                                      return;
                                    }
                                    window.location.href = it.linkUrl;
                                }

                              


                            }}
                        >
                            {it.name}
                        </List.Item>
                    )
                })}
            </List>
        </div>
    )

}