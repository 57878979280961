import { FC, IframeHTMLAttributes, useEffect, useRef, useState } from "react"
import { useBlocker, useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../config/api";
import Loading from "../../../public/Loading";
import { Button, ErrorBlock, Popover, Popup } from "antd-mobile";
import { useGetLocale } from "../../../public/LangProvier";
import SiteLoading from "../../../public/SiteLoading";
import "./index.scss";
import { useGetSiteConfig } from "../../../public/SiteConfigProvier";
import { CloseOutline, AddCircleOutline, UserSetOutline, MoreOutline, BankcardOutline, UserOutline, UnlockOutline, LeftOutline } from "antd-mobile-icons";
import Modal from "../../../public/Modal";
import Draggable from 'react-draggable';
import { AndroidClient, closeFullscreen, eventLog, getClientType, hideBackBotton, openFullscreen, showBackBotton } from "../../../utils";
import { motion } from "framer-motion";
import { useGetLastPagePath } from "../../../public/AppProvier";
import { addAndroidBackListener, removeAndroidBackListener } from "../../../public/OnAndroidBack";

type PlayGameProps = {
    game?: any,
    onClose?: any
}

type OnBackListenerProps = {
    onBack?: any,
    children?: any,
    reload?: boolean,
    pathname: string
}



const CloseBtn = (props: any) => {

    const [dragging, setDragging] = useState(false);
    const [startPosition, setStartPosition] = useState({ x: 0, y: 0 });

    const clientType = getClientType();

    // 开始拖动时记录起始位置
    const handleStart = (e: any, data: any) => {
        setStartPosition({ x: data.x, y: data.y });
        setDragging(false); // 初始化为 false，表示不是拖动
    };

    // 触发拖动事件时设置 dragging 状态为 true
    const handleDrag = (e: any, data: any) => {
        setDragging(true);
    };

    // 结束时判断是否是点击事件
    const handleStop = (e: any, data: any) => {
        const deltaX = Math.abs(data.x - startPosition.x);
        const deltaY = Math.abs(data.y - startPosition.y);

        // 如果拖动距离非常小，触发点击事件
        if (deltaX < 5 && deltaY < 5) {
            setDragging(false);
            props.onClick?.();
        }
    };

    // const isPC = clientType == "PC";

    return (

        <Draggable onStart={handleStart} onDrag={handleDrag} onStop={handleStop} >


            <div className={`play-close-btn play-close-btn-${clientType}`} >

                <CloseOutline className="icon" />

            </div>

        </Draggable>
    )
}



export const PlayGame: FC<PlayGameProps> = ({ game, onClose }) => {


    const [playData, setPlayData] = useState<any>(null);

    const [loading, setLoading] = useState(false);

    const iframeRef = useRef<any>(null);

    const [playError, setPlayError] = useState<any>(null);

    const locale = useGetLocale();

    const [iframeLoading, setIframeLoading] = useState(false);

    const [reloadOnBack, setReloadOnBack] = useState(false);

    const timer = useRef<any>(null);



    const updateLastOnlineTime = async (playRecordId:any)=>{

       await  api(`game/play/record/${playRecordId}`).put({})
    }




    const reportVisit = async () => {

        return await api(`game/visit/${game?.id}`).post({});

    }


    const onExitGameModal = () => {


        Modal.confirm({
            title: locale("tip"),
            content: locale("close_game"),
            okText: locale("text.ok"),
            cancelText: locale("text.cancel"),
            onOk: async () => {

                AndroidClient?.lockOrientation?.("portrait");
                closeFullscreen();
                gameExit();
                onClose?.();
             

            },
        });

       
    }

    const gameExit = () => {

        if (game && playData) {
            api("game/exit").post({ playRecordId: playData?.playRecordId });
        }

    }





    useEffect(() => {

        console.log("play game =>", game);



        addAndroidBackListener("playGame",()=>{

            onExitGameModal();

        });

        showBackBotton(onExitGameModal);
       



        setLoading(true);

        api("game/play").post({
            id: game?.id,
            lobbyUrl: window.location.href
        }).then(ret => {
            console.log("ret=>", ret);
            if (ret.code == 200) {
                if (AndroidClient) {
                    if (game?.landscape == 1) {
                        //横屏
                        AndroidClient?.lockOrientation?.("landscape");
                    } else {
                        AndroidClient?.openFullscreen?.();
                    }
                }

                setPlayData(ret.data);
                setIframeLoading(true);
                //上报访问信息
                reportVisit();
                //启动最后登陆时间上报
                timer.current = setInterval(()=>updateLastOnlineTime(ret.data?.playRecordId),5000);


            } else {
                setPlayError(ret);
                eventLog("open-game-fail",game?.id);

            }
        }).finally(() => {
            setLoading(false);
        });


        eventLog("open-game",game?.id);
        

        return ()=>{

            removeAndroidBackListener("playGame");
            hideBackBotton(onExitGameModal);
            //关闭
            if(timer){
                clearInterval(timer.current);
            }

        }


    }, [game]);



    return (

        <div className="play-page">


            {loading ? (<SiteLoading />) : (
                <>
                    {playError ? (
                        <div style={{ width: "100vw", height: "100vh" }}>
                            <ErrorBlock status="default" title={locale("open_game_fail")} fullPage description={
                                <div>
                                    {playError?.msg}
                                </div>
                            }>
                                <div style={{ padding: 20 }}>
                                    <Button block color="primary" size="large" onClick={onClose} >{locale("text.ok")}</Button>
                                </div>
                            </ErrorBlock>
                        </div>
                    ) : (
                        <>

                            {
                                iframeLoading ? (
                                    <SiteLoading />
                                ) : null}

                            <iframe
                                className="play-game-iframe"

                                src={playData?.gameUrl}
                                style={{ display: iframeLoading ? "none" : "block" }}

                                ref={iframeRef}
                                // sandbox=""

                                // sandbox="allow-scripts"

                                onLoad={e => {

                                    console.log("onLoad iframe");

                                    setReloadOnBack(!reloadOnBack);

                                    setIframeLoading(false);

                                    try {

                                      

                                       const isClose = e.currentTarget?.contentWindow?.location?.href ? true : false;
                                        if (isClose) {
                                            AndroidClient?.lockOrientation?.("portrait");
                                            //退出游戏
                                            closeFullscreen();

                                            gameExit();
                                            onClose?.();


                                        }

                                    } catch (error) {

                                    }
                                }}

                            />
                        </>
                    )}
                </>
            )}

            <CloseBtn onClick={() => {
                onExitGameModal();
            }} />

        </div>

    )
}



export const PlayGamePopup: FC<PlayGameProps> = ({ game, onClose }) => {

    const locale = useGetLocale();

    const clientType = getClientType();

    useEffect(() => {

    }, [game?.id])


    return (
        <Popup




            visible={game ? true : false}
            destroyOnClose={true}
            // showCloseButton={true}
            className="play-page-popup"
            position={clientType == "PC" ? "bottom" : "right"}
            onClose={() => {

                 //关闭android 全屏
              
                //  closeFullscreen();
                 onClose?.()
                 return;

                // onClose?.();



            }}

        >

            {game ? (
                <PlayGame game={game} onClose={onClose} />
            ) : null}

        </Popup>
    )



}


export const PlayGamePage = () => {


    const [game, setGame] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const getLastPagePath = useGetLastPagePath();


    useEffect(() => {

        setGame(location.state?.game);



    }, []);


    if (game == null) {

        return;
    }

    return (


        <PlayGame game={game} onClose={() => {

            const path = getLastPagePath();

            if (path) {
                navigate(path, { replace: true });
                return;
            }
            navigate("/", { replace: true });


            // navigate(-1);
            // window.history.pushState(null, "", "/");


        }} />
    )



}

export default PlayGamePage;

