import { Button, Grid, Modal } from "antd-mobile"
import { FC, useState } from "react"
import { getLocale } from "./LangProvier"

type ConfirmProps = {
    content?: any,
    onOk?: () => Promise<void>,
    onCancel?: any,
    okText?: any,
    cancelText?: any,
    title?: any
}

const Confirm: FC<ConfirmProps> = (props) => {

    const [loading, setLoading] = useState(false);

    const onOk = async () => {

        await props.onOk?.()

    }

    return (
        <div>
            <div style={{ textAlign: "center", margin: "15px 0px" }}>
                {props.content}
            </div>
            <div style={{ paddingTop: "10px" }}>
                <Grid columns={2} gap={10}>
                    <Grid.Item>
                        <Button onClick={props.onCancel} block>{props.cancelText}</Button>
                    </Grid.Item>
                    <Grid.Item>
                        <Button loading={loading} onClick={() => {
                            setLoading(true);
                            onOk().finally(() => {
                                setLoading(false);
                            })

                        }} block color="primary" >{props.okText}</Button>
                    </Grid.Item>
                </Grid>
            </div>
        </div>
    )

}


const ModalStatus = {
    visable: false
};

export default {


    confirm: (config: ConfirmProps) => {

        if (ModalStatus.visable) {
            return;
        }

        ModalStatus.visable = true;


        const modal = Modal.show({
            title: config.title?config.title:getLocale("tip"),

            content: <Confirm
                okText={getLocale("text.ok")}
                cancelText={getLocale("text.cancel")}
                {...config}
                onCancel={() => {
                    ModalStatus.visable = false;
                    modal.close();
                    config?.onCancel?.();
                }}
                onOk={async () => {

                    await config.onOk?.();

                    modal.close();
                    ModalStatus.visable = false;

                }}

            />,
            showCloseButton: true,
            onClose: () => {
                ModalStatus.visable = false;
                config?.onCancel?.();
            }
        })
    }
    ,

    alert: (message:any,onOk?:any) => {
        Modal.alert({
            title: getLocale("tip"),
            content: message ,
            showCloseButton: true,
            confirmText: getLocale("text.ok"),
            onConfirm:onOk
        
        })
    }

}